
import { getRequest } from 'api/apiClient';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { FormField } from 'types/formFieldTypes';
import FormFieldsTable from './FormFieldsTable';
import FormFieldsDrawer from './FormFieldsDrawer';
import { FORMFIELDS_TOOLTIP } from 'constants/tooltip';

const FormFields: React.FC = () => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    const [isFormFieldsDrawerOpen, setIsFormFieldsDrawerOpen] = useState(false);
    const [formFields, setFormFields] = useState<FormField[]>([]);
    const [selectedFormField, setSelectedFormField] = useState<FormField | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    // Fetch form fields
    useEffect(() => {
        const fetchFormFields = async () => {
            setLoading(true);
            try {
                const formFields = await getRequest(`/orgs/${orgId}/formField`);
                const filteredFormFields = formFields.data.filter((formField: FormField) => formField.status === 1);
                setFormFields(filteredFormFields);
            } catch (error) {
                console.log("Failed to fetch form fields.");
            } finally {
                setLoading(false);
            }
        };
        fetchFormFields();
    }, [orgId]);

    const handleEditClick = (formField: FormField) => {
        setSelectedFormField(formField);
        setIsFormFieldsDrawerOpen(true);
    };

    const handleAddClick = () => {
        setSelectedFormField(null);
        setIsFormFieldsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsFormFieldsDrawerOpen(false);
        setSelectedFormField(null);
    };

    return (
        <OpPage title="Form Fields" tooltip={FORMFIELDS_TOOLTIP}>
            <FormFieldsTable
                formFields={formFields}
                setFormFields={setFormFields}
                handleEditClick={handleEditClick}
                handleAddClick={handleAddClick}
                loading={loading}
            />
            <FormFieldsDrawer
                open={isFormFieldsDrawerOpen}
                onClose={handleDrawerClose}
                selectedFormField={selectedFormField}
                setFormFields={setFormFields}
            />
        </OpPage>
    );
}

export default FormFields;