import { Form, Input, Modal, notification } from 'antd';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import { IOpTableProps, OpTable } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { CONFIRM_DELETE_CONTENT, CONFIRM_DELETE_TITLE } from 'constants/messages';
import { TABLE_HEIGHT } from 'constants/ui';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logUserActivity } from 'store/slices/userActivitySlice';
import { createVisitorWorkflow, deleteVisitorWorkflow, describeVisitorWorkflow, describeVisitorWorkflowConfig, describeVisitorWorkflowFields, describeVisitorWorkflowLocations, fetchVisitorWorkflows } from 'store/slices/visitorWorkflowsSlice';
import { AppDispatch, RootState } from 'store/store';
import { ApiResponse } from 'types/apiTypes';
import { Location } from 'types/locationTypes';
import { CreateVisitorWorkflowPayload, VisitorWorkflow } from 'types/visitorWorkflowTypes';
import { hasPermission } from 'utils/utils';

interface AttendantSignInTableProps {
    setDrawerVisible: (visible: boolean) => void;
}

const AttendantSignIn: React.FC<AttendantSignInTableProps> = ({ setDrawerVisible }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const subscriptions = useSelector((state: RootState) => state.subscriptions.subscriptions);
    const { visitorWorkflows, fetchVisitorWorkflowsLoading } = useSelector((state: RootState) => state.visitorWorkflows);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasSigninconfWrite = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:w');

    const [createForm] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFeatureUnavailableModalOpen, setFeatureUnavailableModalOpen] = useState(false);
    const [signInWorkflows, setSignInWorkflows] = useState<VisitorWorkflow[]>([]);

    useEffect(() => {
        if (orgId) {
            dispatch(fetchVisitorWorkflows({ orgId }));
        }
    }, [dispatch, orgId]);

    // Filter and get list of active locations
    useEffect(() => {
        if (visitorWorkflows && visitorWorkflows.data) {
            const filteredWorkflows = visitorWorkflows.data.filter(visitorWorkflow => visitorWorkflow.workflowTypeId === 1);
            setSignInWorkflows(filteredWorkflows);
        }
    }, [visitorWorkflows]);

    // Handle deleting a Visitor workflow
    const handleDeleteVisitorWorkflow = async (record: VisitorWorkflow) => {
        try {
            const response = await dispatch(describeVisitorWorkflowLocations({ orgId, visitorWorkflowId: record.id }));
            const locations = response.payload as ApiResponse<Location>;
            if (locations.data.length > 0) {
                notification.error({
                    message: 'Error',
                    description: 'This workflow has a location. It cannot be deleted.',
                    placement: 'bottomRight',
                });
            } else {
                await dispatch(deleteVisitorWorkflow({ orgId, visitorWorkflowId: record.id }));
                await dispatch(logUserActivity({ orgId, userActivity: { userId: globalUserId!, activityId: "80", details: record.name || '' } }))
                notification.success({
                    message: 'Success',
                    description: 'Workflow deleted successfully.',
                    placement: 'bottomRight',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to delete workflow.',
                placement: 'bottomRight',
            });
        }
    };

    const handleCreateVisitorWorkflow = async (values: any) => {
        try {
            const payload: CreateVisitorWorkflowPayload = {
                name: values.name,
                workflowTypeId: 1
            };
            await dispatch(createVisitorWorkflow({ orgId, visitorWorkflow: payload }));
            await dispatch(logUserActivity({ orgId, userActivity: { userId: globalUserId!, activityId: "70", details: values.name || '' } }))
            notification.success({
                message: 'Success',
                description: 'Workflow created successfully.',
                placement: 'bottomRight',
            });
            setIsModalOpen(false);
            createForm.resetFields();
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to create workflow.',
                placement: 'bottomRight',
            });
        }
    };

    const handleAddWorkflow = () => {
        const packageCode = subscriptions?.data[0]?.package?.code;
        if (signInWorkflows.length >= 1 && (packageCode === 'package-essential' || packageCode === 'package-pro')) {
            setFeatureUnavailableModalOpen(true);
            return;
        }

        setIsModalOpen(true);
    };

    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'name',
            label: "Name",
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.name || '').localeCompare(b.name || ''), // Alphabetical sorting with null check
        },
    ];

    const opTableProps: IOpTableProps = {
        dataSource: signInWorkflows,
        columns: columns,
        rowActions: {
            onEditClick: (visitorWorkflow: VisitorWorkflow) => {
                dispatch(describeVisitorWorkflow({ orgId, visitorWorkflowId: visitorWorkflow.id }));
                dispatch(describeVisitorWorkflowLocations({ orgId, visitorWorkflowId: visitorWorkflow.id }));
                dispatch(describeVisitorWorkflowConfig({ orgId, visitorWorkflowId: visitorWorkflow.id }));
                dispatch(describeVisitorWorkflowFields({ orgId, visitorWorkflowId: visitorWorkflow.id }));
                setDrawerVisible(true);
            },
            onDeleteClick: hasSigninconfWrite ? handleDeleteVisitorWorkflow : undefined,
            deleteModalTitle: () => CONFIRM_DELETE_TITLE("Visitor Workflow"),
            deleteModalContent: ({ record }) => CONFIRM_DELETE_CONTENT(record.name),
        },
        testId: 'dashboard-table',
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        writeAccess: hasSigninconfWrite,
        allowAddition: hasSigninconfWrite ? {
            itemName: 'Sign In Workflow',
            onClick: handleAddWorkflow,
        } : false,
        loading: fetchVisitorWorkflowsLoading,
        allowExport: true,
        allowShowHideColumns: true,
        gtm: 'dashboard-table-gtm',
    };

    return (
        <>
            <OpTable {...opTableProps} />
            <Modal
                title="New Attendant Sign In Workflow"
                open={isModalOpen}
                onOk={createForm.submit}
                onCancel={() => setIsModalOpen(false)}
                okText="Confirm"
                cancelText="Cancel"
                centered
            >
                <Form form={createForm} onFinish={handleCreateVisitorWorkflow}>
                    <Form.Item
                        label="Workflow Name"
                        name="name"
                        rules={[{ required: true, message: 'Please enter the workflow name' }]}
                    >
                        <Input placeholder="Workflow Name" />
                    </Form.Item>
                </Form>
            </Modal>
            <OpModal
                open={isFeatureUnavailableModalOpen}
                onCancel={() => setFeatureUnavailableModalOpen(false)}
                onOk={() => setFeatureUnavailableModalOpen(false)}
                title="Feature Not Included"
            >
                <p>Your account does not currently include this feature. Please contact your administrator.</p>
            </OpModal>
        </>
    );
}

export default AttendantSignIn;
