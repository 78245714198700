import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import STATUS from 'constants/status';
import dayjs from 'dayjs';
import { formatFullName, hasPermission } from 'utils/utils';
import { Visit } from 'types/visitTypes';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { getRequest } from 'api/apiClient';
import { DATE_TIME_FORMAT } from 'constants/dates';
import { OpSelect } from 'components/customAntd/DLS/OpSelect/OpSelect';
import { setSelectedVisit } from 'store/slices/visitsSlice';

interface VisitorOption {
    label: string;
    value: string;
    visitId: number;
    visitorId: number;
    visit: Visit;
}

interface SignOutModalProps {
    open: boolean;
    onClose: () => void;
    openVisitorsDrawer: () => void;
}

const SignOutModal: React.FC<SignOutModalProps> = ({ open, onClose, openVisitorsDrawer }) => {
    const dispatch: AppDispatch = useDispatch();

    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAllvisitorsWrite = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:w');

    const [visitorOptions, setVisitorOptions] = useState<VisitorOption[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    // Fetch visitor options
    useEffect(() => {
        const fetchVisitorOptions = async () => {
            setLoading(true);
            try {
                const visitors = await getRequest(`/orgs/${orgId}/visitor`, {
                    siteId: globalLocationId,
                    startDate: dayjs().startOf('day').format(DATE_TIME_FORMAT),
                    endDate: dayjs().endOf('day').format(DATE_TIME_FORMAT),
                });

                const filteredSignedInVisitors = visitors.data
                    .filter((visit: Visit) => {
                        return (hasAllvisitorsWrite || (globalUserId === visit.host.userId)) &&
                            visit.visitors.some(visitor => visitor.status === STATUS.SIGNED_IN.id);
                    })
                    .flatMap((visit: Visit) => {
                        return visit.visitors
                            .filter(visitor => visitor.status === STATUS.SIGNED_IN.id)
                            .map(visitor => {
                                return {
                                    label: formatFullName(visitor.firstName, visitor.middleName, visitor.lastName),
                                    value: `${visit.id}-${visitor.id}`,
                                    visitId: visit.id,
                                    visitorId: visitor.id,
                                    visit: visit
                                };
                            });
                    })
                setVisitorOptions(filteredSignedInVisitors);
            } catch (error) {
                console.log("Failed to fetch visitor options.");
            } finally {
                setLoading(false);
            }
        };
        fetchVisitorOptions();
    }, [orgId, globalLocationId, globalUserId, hasAllvisitorsWrite]);

    const handleOptionSelect = (value: string, option: VisitorOption) => {
        dispatch(setSelectedVisit(option.visit));
        openVisitorsDrawer();
        onClose();
    };

    return (
        <Modal
            title="Visitor to Sign Out"
            open={open}
            onCancel={onClose}
            footer={null}
            centered
        >
            <OpSpace direction="vertical" size="large" style={{ display: 'flex', paddingTop: '10px' }}>
                <OpSelect
                    options={visitorOptions}
                    placeholder="Select visitor to sign out"
                    onChange={(value, option) => handleOptionSelect(value as string, option as VisitorOption)}
                    showSearch
                    filterOption={(input, option) =>
                        String(option?.label)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    loading={loading}
                    style={{ width: '100%' }}
                />
            </OpSpace>
        </Modal>
    );
};

export default SignOutModal;
