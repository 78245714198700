import React, { useEffect, useState } from 'react';
import { FloatButton } from 'antd';
import { LoginOutlined, LogoutOutlined, MailOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { OpFloatButton } from 'components/customAntd/DLS/OpFloatButton/OpFloatButton';
import SignOutModal from './signOut/SignOutModal';
import ScanIDModal from './signIn/ScanIDModal';
import SignInModal from './signIn/SignInModal';
import { hasPermission } from 'utils/utils';
import SignInManuallyDrawer from './signIn/SignInManuallyDrawer';
import VisitorsDrawer from 'components/pages/visitors/drawer/VisitorsDrawer';
import SignInMethodModal from './signIn/SignInMethodModal';
import InvitationDrawer from './invite/InvitationDrawer';
import ScanQRCodeModal from './signIn/ScanQRCodeModal';
import { getRequest } from 'api/apiClient';
import { RedFlag } from 'types/redFlagTypes';
import { fetchVisitsToday, setSelectedVisit } from 'store/slices/visitsSlice';
import { fetchInvitationConfig } from 'store/slices/visitorInvitationSlice';
import { describeVisitorWorkflowConfig } from 'store/slices/visitorWorkflowsSlice';

type Actions = 'SIGN_IN' | 'SIGN_OUT' | 'NEW_INVITATION';

const ActionFloatButtons: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const collapsed = useSelector((state: RootState) => state.ui.collapsed);
    const globalLocationWorkflow = useSelector((state: RootState) => state.locations.globalLocationWorkflow);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);
    const { globalSignInWorkflowConfig } = useSelector((state: RootState) => state.visitorWorkflows);

    const hasInviteWrite = hasPermission(tokenScopeList, orgId, 'o', 'invite:w');
    const hasSigninWrite = hasPermission(tokenScopeList, orgId, 'o', 'signin:w');
    const hasSignoutWrite = hasPermission(tokenScopeList, orgId, 'o', 'signout:w');

    const [redFlags, setRedFlags] = useState<RedFlag[]>([]);
    const [visitorNameFilter, setVisitorNameFilter] = useState<{ firstName: string; middleName: string; lastName: string } | undefined>(undefined);
    const [newVisitorName, setNewVisitorName] = useState<{ firstName: string; middleName: string; lastName: string } | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    // sign in
    const [isSignInMethodModalOpen, setIsSignInMethodModalOpen] = useState<boolean>(false);
    const [isSignInModalOpen, setIsSignInModalOpen] = useState<boolean>(false);
    const [isSignInManuallyDrawerOpen, setIsSignInManuallyDrawerOpen] = useState<boolean>(false);
    const [isScanIDModalOpen, setIsScanIDModalOpen] = useState<boolean>(false);
    const [isScanQRCodeModalOpen, setIsScanQRCodeModalOpen] = useState<boolean>(false);
    // sign out
    const [isSignOutModalOpen, setIsSignOutModalOpen] = useState<boolean>(false);
    // invite
    const [isNewInvitationDrawerOpen, setIsNewInvitationDrawerOpen] = useState<boolean>(false);
    // visitors drawer
    const [isVisitorsDrawerOpen, setIsVisitorsDrawerOpen] = useState<boolean>(false);
    const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await dispatch(fetchVisitsToday({ orgId }));
                if (invitationConfig === null) {
                    await dispatch(fetchInvitationConfig({ orgId }));
                }
                if (globalSignInWorkflowConfig === null && globalLocationWorkflow) {
                    await dispatch(describeVisitorWorkflowConfig({ orgId, visitorWorkflowId: globalLocationWorkflow.SignInWorkflowId, global: true }));
                }
                const redFlags = await getRequest(`/orgs/${orgId}/redflag`);
                setRedFlags(redFlags.data);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, [orgId, globalLocationWorkflow]);

    const handleFloatButtonClick = (type: Actions) => {
        if (type === 'SIGN_IN') {
            if (((globalSignInWorkflowConfig?.scan === 1) && (globalSignInWorkflowConfig?.scanScanner === 1)) || (invitationConfig?.allowPreRegistration === 1)) {
                setIsSignInMethodModalOpen(true);
            } else {
                setIsSignInModalOpen(true);
            }
        } else if (type === 'SIGN_OUT') {
            setIsSignOutModalOpen(true);
        } else if (type === 'NEW_INVITATION') {
            setIsNewInvitationDrawerOpen(true);
        }
    };

    return (
        <>
            {(!loading && (hasSigninWrite || hasSignoutWrite || hasInviteWrite)) && (
                <FloatButton.Group trigger="click" type="primary" style={collapsed ? { left: 15, bottom: 65 } : { left: 30, bottom: 30 }} icon={<PlusOutlined />}>
                    {hasSigninWrite && (
                        <OpFloatButton
                            tooltip={<div>SIGN IN VISITOR</div>}
                            icon={<LoginOutlined />}
                            style={{ background: "rgba(109,152,138,1.0)" }}
                            onClick={() => handleFloatButtonClick('SIGN_IN')}
                        />
                    )}
                    {hasSignoutWrite && (
                        <OpFloatButton
                            tooltip={<div>SIGN OUT VISITOR</div>}
                            icon={<LogoutOutlined />}
                            style={{ background: "rgba(155,154,154,1.0)" }}
                            onClick={() => handleFloatButtonClick('SIGN_OUT')}
                        />
                    )}
                    {hasInviteWrite && (invitationConfig?.allowInvitations === 1) && (
                        <OpFloatButton
                            tooltip={<div>SCHEDULE VISITOR</div>}
                            icon={<MailOutlined />}
                            style={{ backgroundColor: "rgba(19,151,213,1.0)" }}
                            onClick={() => handleFloatButtonClick('NEW_INVITATION')}
                        />
                    )}
                </FloatButton.Group>
            )}
            {isSignInMethodModalOpen && (
                <SignInMethodModal
                    open={isSignInMethodModalOpen}
                    onClose={() => setIsSignInMethodModalOpen(false)}
                    openSignInModal={() => setIsSignInModalOpen(true)}
                    openScanIDModal={() => setIsScanIDModalOpen(true)}
                    openScanQRCodeModal={() => setIsScanQRCodeModalOpen(true)}
                />
            )}
            {isSignInModalOpen && (
                <SignInModal
                    open={isSignInModalOpen}
                    onClose={() => {
                        setIsSignInModalOpen(false);
                        setVisitorNameFilter(undefined);
                    }}
                    openVisitorsDrawer={() => setIsVisitorsDrawerOpen(true)}
                    openSignInManuallyDrawer={() => setIsSignInManuallyDrawerOpen(true)}
                    visitorNameFilter={visitorNameFilter}
                />
            )}
            {isSignInManuallyDrawerOpen && (
                <SignInManuallyDrawer
                    open={isSignInManuallyDrawerOpen}
                    onClose={() => {
                        setNewVisitorName(undefined);
                        setVisitorNameFilter(undefined);
                        setIsSignInManuallyDrawerOpen(false);
                    }}
                    newVisitorName={newVisitorName}
                    setNewVisitorName={setNewVisitorName}
                />
            )}
            {isScanIDModalOpen && (
                <ScanIDModal
                    open={isScanIDModalOpen}
                    onClose={() => setIsScanIDModalOpen(false)}
                    openVisitorsDrawer={() => setIsVisitorsDrawerOpen(true)}
                    openSelectVisitorsModal={() => setIsSignInModalOpen(true)}
                    openSignInManuallyDrawer={() => setIsSignInManuallyDrawerOpen(true)}
                    setVisitorNameFilter={setVisitorNameFilter}
                    setNewVisitorName={setNewVisitorName}
                />
            )}
            {isScanQRCodeModalOpen && (
                <ScanQRCodeModal
                    open={isScanQRCodeModalOpen}
                    onClose={() => setIsScanQRCodeModalOpen(false)}
                    openVisitorsDrawer={() => setIsVisitorsDrawerOpen(true)}
                />
            )}
            {isSignOutModalOpen && (
                <SignOutModal
                    open={isSignOutModalOpen}
                    onClose={() => setIsSignOutModalOpen(false)}
                    openVisitorsDrawer={() => setIsVisitorsDrawerOpen(true)}
                />
            )}
            {isNewInvitationDrawerOpen && (
                <InvitationDrawer
                    open={isNewInvitationDrawerOpen}
                    onClose={() => { setIsNewInvitationDrawerOpen(false) }}
                />
            )}
            {(isVisitorsDrawerOpen) && (
                <VisitorsDrawer
                    open={isVisitorsDrawerOpen}
                    redFlags={redFlags}
                    isProfileDrawerOpen={isProfileDrawerOpen}
                    onClose={() => {
                        setNewVisitorName(undefined);
                        setVisitorNameFilter(undefined);
                        setIsVisitorsDrawerOpen(false);
                        dispatch(setSelectedVisit(null));
                    }}
                    setIsProfileDrawerOpen={setIsProfileDrawerOpen}
                />
            )}
        </>
    );
};

export default ActionFloatButtons;
