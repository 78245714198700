import { OpCol } from "components/customAntd/DLS/OpCol/OpCol";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { OpRow } from "components/customAntd/DLS/OpRow/OpRow";
import { DATE_FORMAT } from "constants/dates";
import { Role } from "types/roleTypes";
import { User } from "types/userTypes";

interface UserFormProps {
    selectedUser: User | null;
    roles: Role[];
}

const UserForm: React.FC<UserFormProps> = ({ selectedUser, roles }) => {
    return (
        <>
            <OpForm.Input
                label="Email"
                name={['identity', 'email']}
                rules={[{ required: true, message: 'Email is required', type: 'email' }]}
                disabled={selectedUser ? true : false}
            />

            <OpForm.Input
                label="First Name"
                name={['identity', 'firstName']}
                rules={[{ required: true, message: 'First Name is required', type: 'string' }]}
            />

            <OpForm.Input label="Last Name" name={['identity', 'lastName']} />

            <OpForm.Input label="Middle Name" name={['identity', 'middleName']} />

            <OpForm.Input label="Phone" name={['identity', 'mobilePhone']} />

            <OpRow gutter={16}>
                <OpCol span={12}>
                    <OpForm.DatePicker format={DATE_FORMAT} label="Start Date" name="startDate" />
                </OpCol>
                <OpCol span={12}>
                    <OpForm.DatePicker format={DATE_FORMAT} label="End Date" name="endDate" />
                </OpCol>
            </OpRow>

            <OpRow gutter={16}>
                <OpCol span={12}>
                    <OpForm.Input label="Department" name="department" />
                </OpCol>
                <OpCol span={12}>
                    <OpForm.Input label="Title" name="title" />
                </OpCol>
            </OpRow>

            <OpRow gutter={16}>
                <OpCol span={12}>
                    <OpForm.Input label="Person ID" name="personId" />
                </OpCol>
                <OpCol span={12}>
                    <OpForm.Input label="External ID" name="externalId" />
                </OpCol>
            </OpRow>

            <OpForm.Select
                label="Roles" name="roleId"
                rules={[{ required: true, message: 'Role is required' }]}
                mode="multiple"
                placeholder="Select role(s)"
                options={roles.map((role: Role) => ({ label: role.name, value: role.id }))}
            />
        </>
    );
};

export default UserForm;
