import { VisitorWorkflow, VisitorWorkflowConfig, VisitorWorkflowField, VisitorWorkflowFields, VisitorWorkflowType } from 'types/visitorWorkflowTypes';

export const parseVisitorWorkflowTypeResponse = (response: any): VisitorWorkflowType => ({
    id: Number(response.id),
    name: response.name,
    purpose: response.purpose,
    method: response.method,
    languageCode: response.languageCode,
    workflowCount: response.workflowCount !== null ? Number(response.workflowCount) : null,
});

export const parseVisitorWorkflowResponse = (response: any): VisitorWorkflow => ({
    id: Number(response.id),
    name: response.name,
    workflowTypeId: response.workflowTypeId !== null ? Number(response.workflowTypeId) : null,
    status: Number(response.status),
});

export const parseVisitorWorkflowConfigResponse = (response: any): VisitorWorkflowConfig => {
    return {
        id: Number(response.id),
        scan: response.scan,
        scanCamera: response.scanCamera,
        scanScanner: response.scanScanner,
        notifyHost: response.notifyHost,
        additionalVisitors: response.additionalVisitors,
        visitorPhoto: response.visitorPhoto,
        visitorPhotoEach: response.visitorPhotoEach,
        agreementsDisplay: response.agreementsDisplay,
        agreementsAccept: response.agreementsAccept,
        agreementsSignature: response.agreementsSignature,
        agreementsEach: response.agreementsEach,
        printLabel: response.printLabel,
        confirmReturnItems: response.confirmReturnItems,
    };
};

export const parseVisitorWorkflowFieldsResponse = (response: any): VisitorWorkflowFields => ({
    middleName: parseVisitorWorkflowFieldResponse(response.middleName),
    email: parseVisitorWorkflowFieldResponse(response.email),
    visitPhone: parseVisitorWorkflowFieldResponse(response.visitPhone),
    visitCompany: parseVisitorWorkflowFieldResponse(response.visitCompany),
    host: parseVisitorWorkflowFieldResponse(response.host),
    visitPurpose: parseVisitorWorkflowFieldResponse(response.visitPurpose),
    visitNotes: response.visitNotes ? parseVisitorWorkflowFieldResponse(response.visitNotes) : undefined,
});

export const parseVisitorWorkflowFieldResponse = (response: any): VisitorWorkflowField => {
    return ({
        included: response.included,
        required: response.required,
        configurable: response.configurable,
    })
};
