import React, { useCallback, useState, useEffect, useRef } from 'react';
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { notification, Card, Row, Col } from 'antd';
import { setDarkMode, setMatchSystemMode } from 'store/slices/themeSlice'; // Import setDarkMode action

import lightModeImg from 'images/mode_light.png';
import darkModeImg from 'images/mode_dark.png';
import matchSystemImg from 'images/mode_match.png';

const { Meta } = Card;

interface SettingsContentProps {
    form: any;
}

const SettingsContent: React.FC<SettingsContentProps> = ({ form }) => {
    const dispatch: AppDispatch = useDispatch();
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode); // Access dark mode state
    const isMatchSystemMode = useSelector((state: RootState) => state.theme.isMatchSystemMode); // Access match system mode state
    const [selectedTheme, setSelectedTheme] = useState<string>(isMatchSystemMode ? 'match' : (isDarkMode ? 'dark' : 'light'));
    const prevThemeRef = useRef(selectedTheme); // Reference to keep track of the previous theme

    const windowQuery = window.matchMedia("(prefers-color-scheme:dark)");

    const handleThemeSelect = (theme: string) => {
        setSelectedTheme(theme);
        form.setFieldsValue({ theme });
        form.submit(); // Submit the form immediately after setting the theme
    };

    const darkModeChange = useCallback((event: MediaQueryListEvent) => {
        dispatch(setDarkMode(event.matches ? true : false));
    }, [dispatch]);

    useEffect(() => {
        windowQuery.addEventListener("change", darkModeChange);
        return () => {
            windowQuery.removeEventListener("change", darkModeChange);
        };
    }, [windowQuery, darkModeChange]);

    const handleSubmit = useCallback(
        async ({ values }: IOnSubmitArgs<any>) => {
            let isDarkMode = values.theme === 'dark';
            if (values.theme === 'match') {
                isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
                dispatch(setMatchSystemMode(true));
            } else {
                dispatch(setMatchSystemMode(false));
            }
            dispatch(setDarkMode(isDarkMode));

            if (prevThemeRef.current !== values.theme) {
                notification.success({
                    message: 'Success',
                    description: 'Your theme settings have been updated successfully.',
                    placement: 'bottomRight'
                });
            }
            prevThemeRef.current = values.theme;
        }, [dispatch]);

    return (
        <OpForm
            form={form}
            onSubmit={handleSubmit}
            hasError={false}
            initialValues={{ theme: selectedTheme }}
            defaultButtons={false}
        >
            <OpForm.Item name="theme" noStyle>
                <div style={{ border: '1px solid #d9d9d9', borderRadius: '8px', padding: '16px' }}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Card
                                hoverable={true}
                                onClick={() => handleThemeSelect('light')}
                                style={{
                                    borderRadius: '8px',
                                    padding: '30px',
                                    backgroundColor: selectedTheme === 'light' ? 'rgba(0, 110, 215, 0.2)' : undefined,
                                    cursor: 'pointer',
                                    opacity: 1,
                                }}
                                cover={<img alt="Light mode" src={lightModeImg} />}
                            >
                                <Meta title="Light Mode" style={{ textAlign: 'center' }} />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card
                                hoverable={true}
                                onClick={() => handleThemeSelect('dark')}
                                style={{
                                    borderRadius: '8px',
                                    padding: '30px',
                                    backgroundColor: selectedTheme === 'dark' ? 'rgba(0, 110, 215, 0.2)' : undefined,
                                    cursor: 'pointer',
                                    opacity: 1,
                                }}
                                cover={<img alt="Dark mode" src={darkModeImg} />}
                            >
                                <Meta title="Dark Mode" style={{ textAlign: 'center' }} />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card
                                hoverable={true}
                                onClick={() => handleThemeSelect('match')}
                                style={{
                                    borderRadius: '8px',
                                    padding: '30px',
                                    backgroundColor: selectedTheme === 'match' ? 'rgba(0, 110, 215, 0.2)' : undefined,
                                    cursor: 'pointer',
                                    opacity: 1,
                                }}
                                cover={<img alt="Match system" src={matchSystemImg} />}
                            >
                                <Meta title="Match System" style={{ textAlign: 'center' }} />
                            </Card>
                        </Col>
                    </Row>
                </div>
            </OpForm.Item>
        </OpForm>
    );
};

export default SettingsContent;
