import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import LocationsDrawer from './LocationsDrawer';
import LocationsTable from './LocationsTable';
import { LOCATION_TOOLTIP } from 'constants/tooltip';
import { getRequest } from 'api/apiClient';
import { RootState } from 'store/store';
import { Location } from 'types/locationTypes';


const Locations: React.FC = () => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    const [drawerVisible, setDrawerVisible] = useState(false);
    const [locations, setLocations] = useState<Location[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    // Fetch locations
    useEffect(() => {
        const fetchLocations = async () => {
            setLoading(true);
            try {
                const locations = await getRequest(`/orgs/${orgId}/sites`, { status: 1 });
                setLocations(locations.data);
            } catch (error) {
                console.log("Failed to fetch locations.");
            } finally {
                setLoading(false);
            }
        };
        fetchLocations();
    }, [orgId]);

    const handleEditClick = (location: Location) => {
        setSelectedLocation(location);
        setDrawerVisible(true);
    };

    const handleAddClick = () => {
        setSelectedLocation(null);
        setDrawerVisible(true);
    };

    const handleDrawerClose = () => {
        setDrawerVisible(false);
        setSelectedLocation(null);
    };

    return (
        <OpPage title="Locations" tooltip={LOCATION_TOOLTIP}>
            <LocationsTable
                locations={locations}
                setLocations={setLocations}
                handleEditClick={handleEditClick}
                handleAddClick={handleAddClick}
                loading={loading}
            />
            <LocationsDrawer
                open={drawerVisible}
                onClose={handleDrawerClose}
                selectedLocation={selectedLocation}
                setLocations={setLocations}
            />
        </OpPage>
    );
}

export default Locations;
