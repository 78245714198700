import React, { useCallback, useEffect, useState } from 'react';
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { updateVisitorWorkflowFields } from 'store/slices/visitorWorkflowsSlice';
import { OpTable } from 'components/customAntd/DLS/OpTable/OpTable';
import { UpdateVisitorWorkflowField, UpdateVisitorWorkflowFields, VisitorWorkflowFields } from 'types/visitorWorkflowTypes';
import { hasPermission } from 'utils/utils';
import { FormField, FormFieldWorkflow } from 'types/formFieldTypes';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'api/apiClient';

interface FieldsContentProps {
    form: any;
}

interface InitialValues {
    [key: string]: {
        included: boolean;
        required: boolean;
    };
}

interface FormValues {
    [key: string]: {
        included: boolean;
        required: boolean;
    };
}

const FieldsContent: React.FC<FieldsContentProps> = ({ form }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { selectedVisitorWorkflow, selectedSignInWorkflowFields: visitorWorkflowFields, updateVisitorWorkflowFieldsLoading } = useSelector((state: RootState) => state.visitorWorkflows);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const [customFields, setCustomFields] = useState<FormField[]>([]);
    const [customFieldsWorkflow, setCustomFieldsWorkflow] = useState<FormFieldWorkflow[]>([]);

    const [loading, setLoading] = useState<boolean>(false);

    const hasSigninconfRead = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:r');
    const hasSigninconfWrite = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:w');

    const fields = [
        { label: 'First Name', field: 'firstName' },
        { label: 'Last Name', field: 'lastName' },
        { label: 'Middle Name', field: 'middleName' },
        { label: 'Email', field: 'email' },
        { label: 'Visitor Company', field: 'visitCompany' },
        { label: 'Visit Phone', field: 'visitPhone' },
        { label: 'Host', field: 'host' },
        { label: 'Visit Purpose', field: 'visitPurpose' }
    ];

    const extendedFields = [...fields, ...customFields.map((customField) => ({
        label: customField.name,
        field: `${customField.id}`
    }))];

    // Fetch locations
    useEffect(() => {
        const fetchFormFields = async () => {
            setLoading(true);
            try {
                const customeFields = await getRequest(`/orgs/${orgId}/formField`);
                const filteredCustomeFields = customeFields.data.filter((formField: FormField) => formField.status === 1);
                setCustomFields(filteredCustomeFields);

                const customFieldsWorkflow = await getRequest(`/orgs/${orgId}/visitorWorkflow/${selectedVisitorWorkflow?.id}/fieldCustom`);
                setCustomFieldsWorkflow(customFieldsWorkflow.data);
            } catch (error) {
                console.log("Failed to fetch form fields.");
            } finally {
                setLoading(false);
            }
        };
        fetchFormFields();
    }, [orgId, selectedVisitorWorkflow?.id]);

    const initialValues: InitialValues = fields.reduce((acc, field) => {
        const fieldData = visitorWorkflowFields?.[field.field as keyof VisitorWorkflowFields];
        acc[field.field] = {
            included: field.field === 'firstName' || field.field === 'lastName' ? true : fieldData?.included === 1,
            required: field.field === 'firstName' || field.field === 'lastName' ? true : fieldData?.required === 1
        };
        return acc;
    }, {} as InitialValues);

    // Now merge customFieldsWorkflow data into initialValues
    customFieldsWorkflow.forEach((field) => {
        initialValues[field.id] = {
            included: field.included === 1,
            required: field.required === 1,
        };
    });

    const handleValuesChange = (changedValues: Partial<FormValues>, allValues: FormValues) => {
        fields.forEach(field => {
            if (changedValues[field.field]?.included !== undefined) {
                const included = allValues[field.field].included;
                form.setFieldsValue({
                    [field.field]: {
                        required: included ? form.getFieldValue([field.field, 'required']) : false
                    }
                });
            }
        });
    };

    const handleSubmit = useCallback(async ({ values, touchedValues, initialValues }: IOnSubmitArgs<FormValues>) => {
        const transformedValues: UpdateVisitorWorkflowFields = {};

        for (const key in touchedValues) {
            const field = values[key];
            const initialField = (initialValues) ? initialValues[key] : {};

            const included = field?.included !== undefined ? field.included : false;
            const required = field?.required !== undefined ? field.required : false;
            const initialIncluded = initialField?.included !== undefined ? initialField.included : false;
            const initialRequired = initialField?.required !== undefined ? initialField.required : false;

            // Check if the field is a custom field (custom fields have numeric keys)
            const isCustomField = !isNaN(Number(key));

            if (isCustomField) {
                // Add field if enabled
                if (included && !initialIncluded) {
                    try {
                        await postRequest(`/orgs/${orgId}/visitorWorkflow/${selectedVisitorWorkflow?.id}/fieldCustom/${key}`, {
                            required: required ? 1 : 0,
                        });
                        console.log(`Added custom field ${key} to the workflow.`);
                    } catch (error) {
                        console.log(`Failed to add custom field ${key}:`, error);
                    }
                }

                // Remove field if disabled
                if (!included && initialIncluded) {
                    try {
                        await deleteRequest(`/orgs/${orgId}/visitorWorkflow/${selectedVisitorWorkflow?.id}/fieldCustom/${key}`);
                        console.log(`Removed custom field ${key} from the workflow.`);
                    } catch (error) {
                        console.log(`Failed to remove custom field ${key}:`, error);
                    }
                }

                // Update required if included is on and required status changed
                if (included && initialIncluded && required !== initialRequired) {
                    try {
                        await patchRequest(`/orgs/${orgId}/visitorWorkflow/${selectedVisitorWorkflow?.id}/fieldCustom/${key}`, {
                            required: required ? 1 : 0,
                        });
                        console.log(`Updated custom field ${key} in the workflow.`);
                    } catch (error) {
                        console.log(`Failed to update custom field ${key}:`, error);
                    }
                }
            } else {
                // Non-custom fields (built-in fields)
                const fieldUpdate: Partial<UpdateVisitorWorkflowField> = {};

                fieldUpdate.included = included ? 1 : 0;
                fieldUpdate.required = required ? 1 : 0;

                if (Object.keys(fieldUpdate).length > 0) {
                    transformedValues[key as keyof UpdateVisitorWorkflowFields] = fieldUpdate as UpdateVisitorWorkflowField;
                }
            }
        }
        // Dispatch only for non-custom fields
        if (Object.keys(transformedValues).length > 0 && selectedVisitorWorkflow) {
            dispatch(updateVisitorWorkflowFields({
                orgId,
                visitorWorkflowId: selectedVisitorWorkflow.id,
                visitorWorkflowFields: transformedValues,
            }));
        }
    }, [dispatch, orgId, selectedVisitorWorkflow]);


    return (
        <OpForm
            form={form}
            initialValues={initialValues}
            onValuesChange={handleValuesChange}
            onSubmit={handleSubmit}
            hasError={false}
            defaultButtons={false}
            isReadOnly={!hasSigninconfWrite && hasSigninconfRead}
        >
            <OpTable
                label={<div>INVITATION FIELDS</div>}
                dataSource={extendedFields}
                pagination={false}
                allowGlobalSearch={false}
                allowExport={false}
                allowShowHideColumns={false}
                loading={loading}
                columns={[
                    {
                        label: 'Field',
                        dataIndex: 'label',
                    },
                    {
                        label: 'ENABLED',
                        dataIndex: 'enabledSwitch',
                        render: (text, record) => (
                            <OpForm.Switch name={[record.field, 'included']} style={{ marginBottom: 0 }}
                                loading={updateVisitorWorkflowFieldsLoading}
                                disabled={record.field === 'firstName' || record.field === 'lastName' || (!hasSigninconfWrite && hasSigninconfRead)}
                                onChange={(checked) => {
                                    form.setFieldsValue({
                                        [record.field]: {
                                            included: checked,
                                            required: checked ? form.getFieldValue([record.field, 'required']) : false
                                        }
                                    });
                                }}
                            />
                        )
                    },
                    {
                        label: 'REQUIRED',
                        dataIndex: 'requiredSwitch',
                        render: (text, record) => (
                            <OpForm.Item
                                shouldUpdate={(prevValues: any, currentValues: any) => (prevValues as FormValues)[record.field]?.included !== (currentValues as FormValues)[record.field]?.included}
                                noStyle
                            >
                                {({ getFieldValue }) => (
                                    <OpForm.Switch
                                        name={[record.field, 'required']}
                                        style={{ marginBottom: 0 }}
                                        loading={updateVisitorWorkflowFieldsLoading}
                                        disabled={!getFieldValue([record.field, 'included']) || record.field === 'firstName' || record.field === 'lastName' || (!hasSigninconfWrite && hasSigninconfRead)}
                                    />
                                )}
                            </OpForm.Item>
                        )
                    }
                ]}
            />
        </OpForm>
    );
};

export default FieldsContent;
