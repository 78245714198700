import React, { useState } from 'react';
import { Modal, Button, Input, notification, Spin } from 'antd';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { LoadingOutlined } from '@ant-design/icons';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

interface FeedbackModalProps {
    open: boolean;
    onClose: () => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ open, onClose }) => {
    const [feedback, setFeedback] = useState('');
    const [loading, setLoading] = useState(false);
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const userId = useSelector((state: RootState) => state.users.globalUser?.id);

    const handleFeedbackChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFeedback(e.target.value);
    };

    const handleSendFeedback = async () => {
        setLoading(true);
        try {
            const deviceInfo = `Invisit 1.0 (${navigator.userAgent})`;
            const authToken = localStorage.getItem('authToken');
            const response = await axios.post(
                `${apiUrl}/orgs/${orgId}/contact`,
                {
                    userId: userId,
                    device: deviceInfo,
                    message: feedback,
                },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }
            );

            if (response.status === 200 || response.status === 201) {
                notification.success({
                    message: 'Feedback Sent',
                    description: 'Thank you for your feedback!',
                    placement: 'bottomRight'
                });
                onClose();
                setFeedback('');
            } else {
                throw new Error('Failed to send feedback');
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'There was an error sending your feedback. Please try again later.',
                placement: 'bottomRight'
            });
            onClose();
            setFeedback('');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            open={open}
            title="Send Feedback"
            onCancel={onClose}
            centered
            footer={[
                <Button key="cancel" onClick={onClose} disabled={loading}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleSendFeedback} disabled={loading}>
                    Send
                </Button>,
            ]}
        >
            <Spin indicator={<LoadingOutlined spin />} spinning={loading}>
                <Input.TextArea
                    rows={4}
                    value={feedback}
                    onChange={handleFeedbackChange}
                    placeholder="We value your feedback. Please share your thoughts with us."
                    disabled={loading}
                />
            </Spin>
        </Modal>
    );
};

export default FeedbackModal;
