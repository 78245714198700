import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, DatePicker, TimePicker, notification, Spin } from 'antd';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { formatFullName } from 'utils/utils';
import dayjs from 'dayjs';
import { User } from 'types/userTypes';
import { getRequest, patchRequest, postRequest } from 'api/apiClient';
import { VisitorType } from 'types/VisitorTypeTypes';
import { DATE_TIME_FORMAT } from 'constants/dates';
import STATUS from 'constants/status';
import { fetchVisits } from 'store/slices/visitsSlice';
import { FormFieldWorkflow, VisitData } from 'types/formFieldTypes';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { LoadingOutlined } from '@ant-design/icons';
import { describeVisitorWorkflowFields } from 'store/slices/visitorWorkflowsSlice';
import { fetchInvitationConfig } from 'store/slices/visitorInvitationSlice';

interface ModalFormProps {
    open: boolean;
    onClose: () => void;
    setLoading: (loading: boolean) => void;
}

const VisitorsEditModal: React.FC<ModalFormProps> = ({ open, onClose, setLoading }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalLocationWorkflow = useSelector((state: RootState) => state.locations.globalLocationWorkflow);
    const { selectedVisit } = useSelector((state: RootState) => state.visits);
    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);
    const { globalSignInWorkflowFields } = useSelector((state: RootState) => state.visitorWorkflows);

    const [users, setUsers] = useState<User[]>([]);
    const [visitorTypes, setVisitorTypes] = useState<VisitorType[]>([]);
    const [customFieldsWorkflow, setCustomFieldsWorkflow] = useState<FormFieldWorkflow[]>([]);
    const [visitData, setVisitData] = useState<VisitData[]>([]);
    const [activeKey, setActiveKey] = useState<string>('details');
    const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

    useEffect(() => {
        if (open) {
            setActiveKey('details');
        }
    }, [open]);

    const [form] = OpForm.useForm();

    useEffect(() => {
        const fetchData = async () => {
            setIsModalLoading(true);
            try {
                const users = await getRequest(`/orgs/${orgId}/users`);
                const filteredUsers = users.data.filter((user: User) => user.status === 1);
                setUsers(filteredUsers);

                const visitorTypes = await getRequest(`/orgs/${orgId}/visitorTypes`);
                setVisitorTypes(visitorTypes.data);

                const customFieldsWorkflow = await getRequest(`/orgs/${orgId}/visitorWorkflow/${globalLocationWorkflow?.SignInWorkflowId}/fieldCustom`);
                setCustomFieldsWorkflow(customFieldsWorkflow.data);

                const visitData = await getRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitorData`);
                // const filteredVisitData = visitData.data.filter((visitData: VisitData) => visitData.formField.status === 1);
                setVisitData(visitData.data);

                if (invitationConfig === null) {
                    await dispatch(fetchInvitationConfig({ orgId }));
                }
                if (globalSignInWorkflowFields === null && globalLocationWorkflow) {
                    await dispatch(describeVisitorWorkflowFields({ orgId, visitorWorkflowId: globalLocationWorkflow.SignInWorkflowId, global: true }));
                }

            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setIsModalLoading(false);
            }
        };
        fetchData();
    }, [orgId, selectedVisit?.id, globalLocationWorkflow?.SignInWorkflowId, dispatch, globalLocationWorkflow, globalSignInWorkflowFields, invitationConfig]);

    const initialValues: { [key: string]: any } = {
        company: selectedVisit?.company,
        siteId: selectedVisit?.site?.id,
        visitorType: selectedVisit?.visitorType?.id,
        hostId: selectedVisit?.host?.userId,
        purpose: selectedVisit?.purpose,
        signIn: dayjs(selectedVisit?.signIn),
        signInDate: dayjs(selectedVisit?.signIn), // just to display date
        signOut: dayjs(selectedVisit?.signOut),
        signOutDate: dayjs(selectedVisit?.signOut),// just to display date
        date: dayjs(selectedVisit?.scheduleStart).startOf('day'),
        startTime: dayjs(selectedVisit?.scheduleStart),
        endTime: dayjs(selectedVisit?.scheduleEnd),
    };

    // Add visitData fields to initialValues
    visitData.forEach((data) => {
        const { formField, value } = data;
        initialValues[formField.id] = value;
    });

    const handleFieldsChange = (changedFields: any) => {
        const changedField = changedFields[0];
        if (changedField) {
            const { name, value } = changedField;
            const [field] = name;

            if (field === 'startTime') {
                const startTime = value;
                if (startTime) {
                    const startMoment = dayjs(startTime);
                    const newEndTime = startMoment.add(1, 'hour');
                    form.setFieldsValue({ endTime: newEndTime });
                }
            } else if (field === 'endTime') {
                const endTime = value;
                const startTime = form.getFieldValue('startTime');

                if (startTime && endTime) {
                    const startMoment = dayjs(startTime);
                    const endMoment = dayjs(endTime);

                    if (startMoment.isSameOrAfter(endMoment)) {
                        form.setFieldsValue({ startTime: endMoment.subtract(1, 'hour') });
                    }
                }
            }
        }
    };

    const editVisit = async (updatedVisit: any) => {
        setLoading(true);

        let startDate = updatedVisit.date ? dayjs(updatedVisit.date).hour(dayjs(updatedVisit.startTime).hour()).minute(dayjs(updatedVisit.startTime).minute()) : null;
        let endDate = updatedVisit.date ? dayjs(updatedVisit.date).hour(dayjs(updatedVisit.endTime).hour()).minute(dayjs(updatedVisit.endTime).minute()) : null;

        // Adjust endDate to the next day if it's before startDate
        if (endDate && startDate && endDate.isSameOrBefore(startDate)) {
            endDate = endDate.add(1, 'day');
        }

        if (startDate && endDate && endDate.isSameOrBefore(startDate)) {
            notification.error({
                message: 'Error',
                description: 'End time must be after the start time.',
                placement: 'bottomRight',
            });
            return;
        }

        try {
            const filteredUpdatedVisit = Object.fromEntries(
                Object.entries(updatedVisit).filter(([key]) => isNaN(Number(key)))
            );

            await patchRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}`, {
                ...filteredUpdatedVisit,
                signIn: updatedVisit.signIn ? dayjs(updatedVisit.signIn).format(DATE_TIME_FORMAT) : undefined,
                signOut: updatedVisit.signOut ? dayjs(updatedVisit.signOut).format(DATE_TIME_FORMAT) : undefined,
                scheduleIn: startDate?.format(DATE_TIME_FORMAT),
                scheduleOut: endDate?.format(DATE_TIME_FORMAT)
            });

            for (const key in updatedVisit) {
                const field = updatedVisit[key];
                const initialField = initialValues[key];

                // Check if the field is a custom field (custom fields have numeric keys)
                const isCustomField = !isNaN(Number(key));
                if (isCustomField && field !== initialField) {
                    // Add field if enabled
                    if (field && !initialField && !visitData.find(item => item.formField.id === Number(key))) {
                        try {
                            await postRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitorData`, {
                                fieldId: key,
                                value: field,
                            });
                            console.log(`Added custom field ${key}.`);
                        } catch (error) {
                            console.log(`Failed to add custom field ${key}:`, error);
                        }
                    } else {
                        try {
                            const dataId = visitData.find((data) => Number(data.formField.id) === Number(key))?.id;
                            await patchRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitorData/${dataId}`, {
                                fieldId: key,
                                value: field,
                            });
                            console.log(`Updated custom field ${key}.`);
                        } catch (error) {
                            console.log(`Failed to update custom field ${key}:`, error);
                        }
                    }
                }
            }
            await dispatch(fetchVisits({ orgId }));
            notification.success({
                message: 'Success',
                description: 'Visit updated successfully.',
                placement: 'bottomRight',
            });
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to update visit.',
                placement: 'bottomRight',
            });
        } finally {
            setLoading(false);
        }
    };

    const renderFormFields = () => {
        const customFieldIds = customFieldsWorkflow.map(field => field.id);
        return (
            <>
                {customFieldsWorkflow.map((formField, index) => {
                    if (formField.values) {
                        const options = formField.values.map((option) => ({
                            label: option.display || option.name,
                            value: option.value,
                        }));

                        return (
                            <OpForm.Select
                                label={formField.name}
                                name={formField.id}
                                options={options}
                                rules={[{ required: formField.required === 1 }]}
                                placeholder={formField.placeholder ? formField.placeholder : undefined}
                            />
                        );
                    } else {
                        return (
                            <OpForm.Input
                                label={formField.name}
                                name={formField.id}
                                rules={[{ required: formField.required === 1 }]}
                                placeholder={formField.placeholder ? formField.placeholder : undefined}
                            />
                        );
                    }
                })}

                {visitData
                    .filter(visitField => !customFieldIds.includes(visitField.formField.id)) // Fields not in customFieldsWorkflow
                    .map(visitField => {
                        const formField = visitField.formField;

                        if (formField.values) {
                            const options = formField.values.map((option) => ({
                                label: option.display || option.name,
                                value: option.value,
                            }));

                            return (
                                <OpForm.Select
                                    key={formField.id}
                                    label={formField.name}
                                    name={formField.id}
                                    options={options}
                                    placeholder={formField.placeholder ? formField.placeholder : undefined}
                                />
                            );
                        } else {
                            return (
                                <OpForm.Input
                                    key={formField.id}
                                    label={formField.name}
                                    name={formField.id}
                                    placeholder={formField.placeholder ? formField.placeholder : undefined}
                                />
                            );
                        }
                    })}
            </>
        );
        // return customFieldsWorkflow.map((formField, index) => {
        //     if (formField.values) {
        //         const options = formField.values.map((option) => ({
        //             label: option.display || option.name,
        //             value: option.value,
        //         }));

        //         return (
        //             <OpForm.Select
        //                 label={formField.name}
        //                 name={formField.id}
        //                 options={options}
        //                 rules={[{ required: formField.required === 1 }]}
        //                 placeholder={formField.placeholder ? formField.placeholder : undefined}
        //             />
        //         );
        //     } else {
        //         return (
        //             <OpForm.Input
        //                 label={formField.name}
        //                 name={formField.id}
        //                 rules={[{ required: formField.required === 1 }]}
        //                 placeholder={formField.placeholder ? formField.placeholder : undefined}
        //             />
        //         );
        //     }
        // });
    };

    // Define the tabs with the `items` prop
    const tabItems = [
        {
            key: 'details',
            label: 'Details',
            children: (
                <>
                    {selectedVisit?.visitStatus.id === STATUS.PENDING.id && (
                        <>
                            <OpForm.Select
                                label="Visitor Type" name="visitorType" rules={[{ required: true, message: 'Please enter Visitor Type' }]}
                                placeholder="Select Visitor Type"
                                options={visitorTypes.map(type => ({
                                    label: type.name,
                                    value: type.id
                                }))}
                            />
                            <Row gutter={8}>
                                <Col span={8}>
                                    <OpForm.Item name="date" label="Schedule" rules={[{ required: true, message: 'Please select a date' }]}>
                                        <DatePicker style={{ width: '100%' }} />
                                    </OpForm.Item>
                                </Col>
                                <Col span={8}>
                                    <OpForm.Item name="startTime" label="Start Time" rules={[{ required: true, message: 'Please select a start time' }]}>
                                        <TimePicker
                                            format="h:mm A"
                                            use12Hours
                                            minuteStep={5}
                                            needConfirm={false}
                                            style={{ width: '100%' }}
                                        />
                                    </OpForm.Item>
                                </Col>
                                <Col span={8}>
                                    <OpForm.Item name="endTime" label="End Time" rules={[{ required: true, message: 'Please select an end time' }]}>
                                        <TimePicker
                                            format="h:mm A"
                                            use12Hours
                                            minuteStep={5}
                                            needConfirm={false}
                                            style={{ width: '100%' }}
                                        />
                                    </OpForm.Item>
                                </Col>
                            </Row>
                        </>
                    )}
                    {(selectedVisit?.visitStatus.id === STATUS.SIGNED_IN.id || selectedVisit?.visitStatus.id === STATUS.SIGNED_OUT.id) && (
                        <Row gutter={8}>
                            <Col span={12}>
                                <OpForm.Item name="signInDate" label="Sign In Date">
                                    <DatePicker style={{ width: '100%' }} disabled={true} />
                                </OpForm.Item>
                            </Col>
                            <Col span={12}>
                                <OpForm.Item
                                    name="signIn"
                                    label="Start Time"
                                    rules={[{ required: true, message: 'Please select a start time' }]}
                                >
                                    <TimePicker
                                        format="h:mm A"
                                        use12Hours
                                        minuteStep={5}
                                        style={{ width: '100%' }}
                                    />
                                </OpForm.Item>
                            </Col>
                        </Row>
                    )}
                    {selectedVisit?.visitStatus.id === STATUS.SIGNED_OUT.id && (
                        <Row gutter={8}>
                            <Col span={12}>
                                <OpForm.Item name="signOutDate" label="Sign Out Date">
                                    <DatePicker style={{ width: '100%' }} disabled={true} />
                                </OpForm.Item>
                            </Col>
                            <Col span={12}>
                                <OpForm.Item
                                    name="signOut"
                                    label="End Time"
                                    rules={[{ required: true, message: 'Please select a end time' }]}
                                >
                                    <TimePicker
                                        format="h:mm A"
                                        use12Hours
                                        minuteStep={5}
                                        style={{ width: '100%' }}
                                    />
                                </OpForm.Item>
                            </Col>
                        </Row>
                    )}
                    <OpForm.Input label="Company" name="company"
                        rules={[
                            {
                                required:
                                    (selectedVisit?.visitStatus.id === STATUS.PENDING.id && invitationConfig?.visitCompany.required === 1) ||
                                    ((selectedVisit?.visitStatus.id === STATUS.SIGNED_IN.id ||
                                        selectedVisit?.visitStatus.id === STATUS.SIGNED_OUT.id ||
                                        selectedVisit?.visitStatus.id === STATUS.DENIED_ENTRY.id
                                    ) && globalSignInWorkflowFields?.visitCompany.required === 1)
                            }
                        ]}
                    />
                    <OpForm.Select
                        label="Host" name="hostId"
                        showSearch
                        placeholder={users.filter(user => user.status === 1 && user.hostExclude === 0).length > 0 ? "Select Host" : "No hosts are available"}
                        disabled={users.filter(user => user.status === 1 && user.hostExclude === 0).length === 0}
                        filterOption={(input, option) => {
                            const label = option?.label;
                            return typeof label === 'string' && label.toLowerCase().includes(input.toLowerCase());
                        }}
                        rules={[
                            {
                                required:
                                    (selectedVisit?.visitStatus.id === STATUS.PENDING.id && invitationConfig?.host.required === 1) ||
                                    ((selectedVisit?.visitStatus.id === STATUS.SIGNED_IN.id ||
                                        selectedVisit?.visitStatus.id === STATUS.SIGNED_OUT.id ||
                                        selectedVisit?.visitStatus.id === STATUS.DENIED_ENTRY.id
                                    ) && globalSignInWorkflowFields?.host.required === 1)
                            }
                        ]}
                        options={users.filter(user => user.status === 1 && user.hostExclude === 0)
                            .map(user => ({
                                label: user.identity.firstName || user.identity.lastName
                                    ? formatFullName(user.identity.firstName, user.identity.middleName, user.identity.lastName)
                                    : user.identity.email,
                                value: user.id
                            }))
                        }
                    />
                    <OpForm.TextAreaInput rows={3} label="Purpose" name="purpose"
                        rules={[
                            {
                                required:
                                    (selectedVisit?.visitStatus.id === STATUS.PENDING.id && invitationConfig?.visitPurpose.required === 1) ||
                                    ((selectedVisit?.visitStatus.id === STATUS.SIGNED_IN.id ||
                                        selectedVisit?.visitStatus.id === STATUS.SIGNED_OUT.id ||
                                        selectedVisit?.visitStatus.id === STATUS.DENIED_ENTRY.id
                                    ) && globalSignInWorkflowFields?.visitPurpose.required === 1)
                            }
                        ]}
                    />
                </>
            ),
        },
        // Conditionally add the "Additional Info" tab only if customFieldsWorkflow has data
        ...(customFieldsWorkflow.length > 0 || visitData.length > 0
            ? [{
                key: 'additionalInfo',
                label: 'Additional Info',
                children: renderFormFields(),
            }]
            : []
        ),
    ];
    return (
        <Modal
            open={open}
            title={'Edit Visit'}
            okText="Save"
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onClose();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.submit();
                        onClose();
                    })
                    .catch((errorInfo) => {
                        notification.error({
                            message: 'Validation Error',
                            description: 'Please fill in all required fields.',
                            placement: 'bottomRight',
                        });
                    });
            }}
            centered
        >
            {isModalLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
            ) : (
                <div style={{ overflowY: 'auto', maxHeight: '63vh', padding: '6px' }}>
                    <OpForm
                        form={form}
                        initialValues={initialValues}
                        onFieldsChange={handleFieldsChange}
                        onSubmit={({ values }) => {
                            editVisit(values);
                            form.resetFields();
                        }}
                        hasError={false}
                        defaultButtons={false}
                    >
                        <OpTabs activeKey={activeKey} onChange={setActiveKey} items={tabItems} />
                    </OpForm>
                </div>
            )}
        </Modal>
    );
};

export default VisitorsEditModal;
