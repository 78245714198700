import React, { useEffect, useState } from "react";
import { List, FormInstance } from "antd";
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { updateAppearanceConfig } from "store/slices/kiosksSlice";
import { KioskAppearance, UpdateAppearanceConfigPayload } from "types/kioskTypes";
import { hasPermission } from "utils/utils";
import { OpModal } from "components/customAntd/DLS/OpModal/OpModal";

interface OptionsContentProps {
    optionsForm: FormInstance;
}

const OptionsContent: React.FC<OptionsContentProps> = ({ optionsForm }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const selectedKiosk = useSelector((state: RootState) => state.kiosks.selectedKiosk);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const subscriptions = useSelector((state: RootState) => state.subscriptions.subscriptions);

    const hasKioskRead = hasPermission(tokenScopeList, orgId, 'o', 'kiosk:r');
    const hasKioskWrite = hasPermission(tokenScopeList, orgId, 'o', 'kiosk:w');

    const [isScanIdCameraDisabled, setScanIdCameraDisabled] = useState(true);
    const [featureUnavailableModalOpen, setFeatureUnavailableModalOpen] = useState(false);

    useEffect(() => {
        const initialScanIdState = selectedKiosk?.appearance[0].scanID;
        setScanIdCameraDisabled(!initialScanIdState);
        if (!initialScanIdState) {
            optionsForm.setFieldsValue({ scanIdCamera: false });
        }
    }, [selectedKiosk, optionsForm]);

    const handleSubmit = async (args: IOnSubmitArgs<KioskAppearance>) => {
        if (!orgId) { return; }

        const { values } = args;
        const payload: Partial<UpdateAppearanceConfigPayload> = {
            allowSignOut: values.allowSignOut ? 1 : 0,
            scanQR: values.scanQR ? 1 : 0,
            scanID: values.scanID ? 1 : 0,
            scanIdCamera: values.scanIdCamera ? 1 : 0,
            badgePrinter: values.badgePrinter ? 1 : 0
        };
        if (selectedKiosk) {
            dispatch(updateAppearanceConfig({ orgId, kioskId: selectedKiosk.id, appearanceConfig: payload }));
        }
    };

    const handleScanIdChange = (checked: boolean) => {
        setScanIdCameraDisabled(!checked);
        if (!checked) {
            optionsForm.setFieldsValue({ scanIdCamera: false });
        }
    };

    const handleBadgePrinterChange = (checked: boolean) => {
        if (subscriptions?.data[0]?.package?.code === "package-essential") {
            // Prevent the change and show the modal
            setFeatureUnavailableModalOpen(true);
            optionsForm.setFieldsValue({ badgePrinter: selectedKiosk?.appearance[0].badgePrinter }); // Reset the value in the form
        }
    };

    const handleScanQRChange = (checked: boolean) => {
        if (subscriptions?.data[0]?.package?.code === "package-essential") {
            setFeatureUnavailableModalOpen(true);
            optionsForm.setFieldsValue({ scanQR: selectedKiosk?.appearance[0].scanQR }); // Reset the value in the form
        }
    };

    const data = [
        { name: 'allowSignOut', label: 'Allow Sign Out', valuePropName: 'checked' },
        { name: 'scanQR', label: 'Pre-Registration QR code', valuePropName: 'checked' },
        { name: 'scanID', label: 'Scan ID To Identify', valuePropName: 'checked' },
        { name: 'scanIdCamera', label: 'Use Camera', valuePropName: 'checked', dependent: true },
        { name: 'badgePrinter', label: 'Badge Printer', valuePropName: 'checked' },
    ];

    return (
        <>
            <OpForm
                form={optionsForm}
                onSubmit={handleSubmit}
                hasError={false}
                defaultButtons={false}
                initialValues={{
                    allowSignOut: selectedKiosk?.appearance[0].allowSignOut,
                    scanQR: selectedKiosk?.appearance[0].scanQR,
                    scanID: selectedKiosk?.appearance[0].scanID,
                    scanIdCamera: selectedKiosk?.appearance[0].scanIdCamera,
                    badgePrinter: selectedKiosk?.appearance[0].badgePrinter
                }}
                isReadOnly={!hasKioskWrite && hasKioskRead}
            >
                <List
                    header={<div>KIOSK OPTIONS</div>}
                    dataSource={data}
                    bordered
                    renderItem={item => (
                        <List.Item>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <OpForm.Switch
                                    name={item.name}
                                    style={{ marginBottom: 0 }}
                                    disabled={item.dependent && (isScanIdCameraDisabled || (!hasKioskWrite && hasKioskRead))}
                                    onChange={
                                        item.name === 'scanID'
                                            ? handleScanIdChange
                                            : item.name === 'badgePrinter'
                                                ? handleBadgePrinterChange
                                                : item.name === 'scanQR'
                                                    ? handleScanQRChange
                                                    : undefined
                                    } />
                                <span style={{ marginLeft: 8 }}>{item.label}</span>
                            </div>
                        </List.Item>
                    )}
                />
            </OpForm>

            <OpModal
                open={featureUnavailableModalOpen}
                onCancel={() => setFeatureUnavailableModalOpen(false)}
                onOk={() => setFeatureUnavailableModalOpen(false)}
                title="Feature Not Included"
            >
                <p>Your account does not currently include this feature. Please contact your administrator.</p>
            </OpModal>
        </>
    );
};

export default OptionsContent;
