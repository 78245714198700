import { ComponentProps, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { OpDrawer } from '../OpDrawer/OpDrawer';
import { FormButtons } from '../OpForm/FormButtons';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { FormInstance } from 'antd';

interface IOpFormDrawerProps extends ComponentProps<typeof OpDrawer> {
    form: FormInstance;
    isFormLoading?: boolean;
    isFormReadOnly?: boolean;
    isSubmitButtonLoading?: boolean;
    formComponent: ReactElement;
    submitButtonLabel?: string;
}

export const OpFormDrawer = ({
    form,
    isFormLoading,
    isFormReadOnly,
    isSubmitButtonLoading,
    onClose,
    formComponent,
    submitButtonLabel,
    ...opDrawerProps
}: IOpFormDrawerProps) => {
    const { t } = useTranslation();
    const confirmModal = useConfirmModal();

    const finalOnClose: IOpFormDrawerProps['onClose'] = (e) => {
        if (form.isFieldsTouched()) {
            confirmModal({
                title: t('Confirm discard?'),
                content: t('You have unsaved changes. Are you sure you wish to discard them?'),
                okText: t('Yes'),
                cancelText: t('No'),
                onOk: () => {
                    form.resetFields();
                    onClose?.(e);
                },
            });
        } else {
            form.resetFields();
            onClose?.(e);
        }
    };

    return (
        <OpDrawer
            data-testid="op-form-drawer"
            footer={!isFormReadOnly && <FormButtons form={form} isFormLoading={isFormLoading} submitButtonLabel={submitButtonLabel} />}
            onClose={finalOnClose}
            {...opDrawerProps}
        >
            {isFormLoading ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}>
                    <Spin indicator={<LoadingOutlined spin />} />
                </div>
            ) : (
                formComponent
            )}
        </OpDrawer>
    );
};
