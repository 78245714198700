import React, { useCallback, useMemo } from 'react';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { capitalizeName, hasPermission } from 'utils/utils';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TIME_AM_PM_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';
import { StopOutlined, EyeOutlined } from "@ant-design/icons";
import { updateRedFlag } from 'store/slices/redFlagSlice';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);


interface CombinedFormContentProps {
    form: any;
    tab: string;
}

const CombinedFormContent: React.FC<CombinedFormContentProps> = ({ form, tab }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { selectedRedFlag } = useSelector((state: RootState) => state.redFlags);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasRedflagRead = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:r');
    const hasRedflagWrite = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:w');

    const initialValues = useMemo(() => {
        if (selectedRedFlag) {
            return {
                level: selectedRedFlag.level,
                levelName: selectedRedFlag.levelName,
                datatype: selectedRedFlag.datatype,
                effectiveDate: dayjs(selectedRedFlag.effectiveDate).isValid() ? dayjs(selectedRedFlag.effectiveDate).utc(true).local().format(DATE_TIME_AM_PM_FORMAT) : null, // Convert from UTC to local
                expireDate: dayjs(selectedRedFlag.expireDate).isValid() ? dayjs(selectedRedFlag.expireDate).utc(true).local().format(DATE_TIME_AM_PM_FORMAT) : null, // Convert from UTC to local
                visitor: {
                    firstName: capitalizeName(selectedRedFlag.firstName),
                    lastName: capitalizeName(selectedRedFlag.lastName),
                    middleName: capitalizeName(selectedRedFlag.middleName),
                    dateOfBirth: dayjs(selectedRedFlag.dateOfBirth).isValid() ? dayjs(selectedRedFlag.dateOfBirth).format(DATE_FORMAT) : null, // Convert from UTC to local
                }
            };
        } else {
            return {
                level: 1,
                effectiveDate: dayjs().utc(true).local().format(DATE_TIME_AM_PM_FORMAT) // Current time in local format
            }
        }
    }, [selectedRedFlag]);

    const handleSubmit = useCallback(({ values, touchedValues, initialValues }: IOnSubmitArgs<any>) => {
        // Convert dates back to UTC before submission
        const submitValues = {
            level: values.level,
            expireDate: values.expireDate ? dayjs(values.expireDate).endOf('day').utc().format(DATE_TIME_FORMAT) : undefined,
            // expireDate: values.expireDate ? dayjs(values.expireDate).utc().format(DATE_TIME_FORMAT) : undefined,
            visitor: {
                ...values.visitor,
                dateOfBirth: values.visitor.dateOfBirth ? dayjs(values.visitor.dateOfBirth).format(DATE_FORMAT) : undefined,
                // dateOfBirth: values.visitor.dateOfBirth ? dayjs(values.visitor.dateOfBirth).utc().format(DATE_FORMAT) : undefined,
            },
        };

        if (selectedRedFlag && orgId) {
            dispatch(updateRedFlag({ orgId, redFlagId: selectedRedFlag.id, redFlag: submitValues }));
        } else {
            console.log("Creating new red flag with touched values", touchedValues);
        }
    }, [dispatch, selectedRedFlag, orgId]);

    return (
        <OpForm
            form={form}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            hasError={false}
            defaultButtons={false}
            isReadOnly={!hasRedflagWrite && hasRedflagRead}
        >
            {tab === 'redFlag' && (
                <>
                    <OpForm.Select
                        label="Red Flag type" name="level" rules={[{ required: true, message: 'Please select the level' }]}
                        placeholder="Select type"
                        options={[
                            { label: <span><StopOutlined style={{ color: 'rgba(242,103,87,1.0)' }} /> Blocklist</span>, value: 1 },
                            { label: <span><EyeOutlined style={{ color: '#FFD54F' }} /> Watchlist</span>, value: 2 }
                        ]}
                    />
                    <OpForm.Input label="Effective Date" name="effectiveDate" disabled={true} />
                    <OpForm.DatePicker format={DATE_FORMAT} label="Expire Date" name="expireDate" />
                </>
            )}
            {tab === 'visitor' && (
                <>
                    <OpForm.Input
                        label="First Name" name={['visitor', 'firstName']} rules={[{ required: true, message: 'Please enter the first name' }]}
                        onChange={(e) => form.setFieldsValue({ firstName: capitalizeName(e.target.value) })}
                    />

                    <OpForm.Input
                        label="Last Name" name={['visitor', 'lastName']} rules={[{ required: true, message: 'Please enter the last name' }]}
                        onChange={(e) => form.setFieldsValue({ lastName: capitalizeName(e.target.value) })}
                    />

                    <OpForm.Input
                        label="Middle Name" name={['visitor', 'middleName']}
                        onChange={(e) => form.setFieldsValue({ middleName: capitalizeName(e.target.value) })}
                    />

                    <OpForm.DatePicker format={DATE_FORMAT} label="Date of Birth" name={['visitor', 'dateOfBirth']} />
                </>
            )}
        </OpForm >
    );
};

export default CombinedFormContent;
