import React, { useState, useEffect, useCallback } from 'react';
import { notification } from 'antd';
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import STATUS from 'constants/status';
import { DATE_TIME_AM_PM_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';
import dayjs from 'dayjs';
import { OpDivider } from 'components/customAntd/DLS/OpDivider/OpDivider';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import { getStatusNameById, getStatusColor, profileIcon } from 'utils/visitorsHelper';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { formatFullName, hasPermission } from 'utils/utils';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { Visitor } from 'types/visitTypes';
import { getRequest, patchRequest, postRequest } from 'api/apiClient';
import { DenyReason } from 'types/denyTypes';
import { SIGNED_IN_VISITOR_ID, SIGNED_OUT_VISITOR_ID } from 'constants/userActivities';
import { fetchVisits, fetchVisitsToday } from 'store/slices/visitsSlice';

interface VisitorsModalProps {
    open: boolean;
    newStatus: number;
    onClose: () => void;
}

const AccessControlModal: React.FC<VisitorsModalProps> = ({ open, newStatus, onClose }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const { selectedVisit } = useSelector((state: RootState) => state.visits);
    const subscriptions = useSelector((state: RootState) => state.subscriptions.subscriptions);

    const hasRedFlagWrite = hasPermission(tokenScopeList, orgId, 'o', 'redflag:w');

    const [denyReasons, setDenyReasons] = useState<DenyReason[]>([]);
    const [addToBlockList, setAddToBlockList] = useState(false);
    const [addToWatchList, setAddToWatchList] = useState(false);
    const [featureUnavailableModalOpen, setFeatureUnavailableModalOpen] = useState(false);

    const [form] = OpForm.useForm();

    const confirmModal = useConfirmModal();

    const initialValues = { reasonForDenial: denyReasons[0]?.id };

    // fetch deny reasons
    useEffect(() => {
        const fetchDenyReasons = async () => {
            try {
                const denyReasons = await getRequest(`/orgs/${orgId}/denyReason`);
                setDenyReasons(denyReasons.data);
            } catch (error) {
                console.log("Failed to fetch deny reasons.");
            }
        };
        fetchDenyReasons();
    }, [orgId]);

    const getFilteredVisitors = () => {
        return selectedVisit?.visitors.filter(visitor => {
            if (newStatus === Number(STATUS.SIGNED_IN.id) || newStatus === Number(STATUS.DENIED_ENTRY.id)) {
                return visitor.status === STATUS.PENDING.id;
            } else if (newStatus === Number(STATUS.SIGNED_OUT.id)) {
                return visitor.status === STATUS.SIGNED_IN.id;
            }
            return true;
        });
    };

    const visitorOptions = getFilteredVisitors()?.map(visitor => ({
        label: formatFullName(visitor.firstName, visitor.middleName, visitor.lastName),
        value: `${visitor.id}`,
        firstName: visitor.firstName,
        lastName: visitor.lastName,
        color: getStatusColor(getStatusNameById(visitor.status, selectedVisit?.scheduleStart!))
    }));

    const updateVisitStatus = useCallback(async () => {
        if (newStatus === Number(STATUS.SIGNED_IN.id)) {
            await patchRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}`, {
                status: newStatus,
                signIn: dayjs().format(DATE_TIME_FORMAT),
            });
            notification.success({
                message: 'Success',
                description: 'Signed in successfully.',
                placement: 'bottomRight',
            });
        } else if (newStatus === Number(STATUS.SIGNED_OUT.id)) {
            await postRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitorExit`, {
                signOut: dayjs().format(DATE_TIME_FORMAT),
            });
            notification.success({
                message: 'Success',
                description: 'Signed out successfully.',
                placement: 'bottomRight',
            });
        } else if (newStatus === Number(STATUS.DENIED_ENTRY.id)) {
            await patchRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}`, {
                status: newStatus,
                signIn: dayjs().format(DATE_TIME_FORMAT),
            });
            notification.success({
                message: 'Success',
                description: 'Denied entry.',
                placement: 'bottomRight',
            });
            setAddToBlockList(false);
            setAddToWatchList(false);
        }
        onClose();
    }, [orgId, selectedVisit, newStatus, onClose]);

    const updateVisitorStatus = useCallback(async (visitorId: number) => {
        try {
            await patchRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitors/${visitorId}`, {
                status: newStatus,
                signIn: newStatus === Number(STATUS.SIGNED_IN.id) || newStatus === Number(STATUS.DENIED_ENTRY.id) ? dayjs().format(DATE_TIME_FORMAT) : undefined,
                signOut: newStatus === Number(STATUS.SIGNED_OUT.id) ? dayjs().format(DATE_TIME_FORMAT) : undefined,
            });
        } catch (error) {
            console.error("Failed to update visitor status.");
        }
    }, [orgId, selectedVisit, newStatus]);

    const handleSubmit = useCallback(async ({ values }: IOnSubmitArgs) => {
        const visitors = selectedVisit?.visitors.filter(visitor => values.visitorsCheckbox.includes(visitor.id.toString()));

        // update status of visit and visitors
        if (visitors) {
            for (const visitor of visitors) {
                if (visitor.id) {
                    await updateVisitorStatus(visitor.id);
                }
            }
        }
        await updateVisitStatus();

        // log activity
        const fullNameList = visitors?.map((visitor: Visitor) => formatFullName(visitor.firstName, visitor.middleName, visitor.lastName,)).join(', ');
        if (newStatus === Number(STATUS.SIGNED_IN.id)) {
            await postRequest(`/orgs/${orgId}/userActivity`, {
                userId: globalUserId,
                activityId: SIGNED_IN_VISITOR_ID,
                details: fullNameList
            });
        } else if (newStatus === Number(STATUS.SIGNED_OUT.id)) {
            await postRequest(`/orgs/${orgId}/userActivity`, {
                userId: globalUserId,
                activityId: SIGNED_OUT_VISITOR_ID,
                details: fullNameList
            });
        }

        // Deny Entry
        if (newStatus === Number(STATUS.DENIED_ENTRY.id)) {
            try {
                for (const visitorId of values.visitorsCheckbox) {
                    await postRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/denyEntry`, {
                        visitorId: visitorId,
                        deniedBy: globalUserId,
                        reasonId: values.reasonForDenial,
                        details: values.detail,
                    });
                }

                // Create Red Flag API Call
                if (values.addToBlockList || values.addToWatchList) {
                    await postRequest(`/orgs/${orgId}/redflag`, {
                        level: values.addToBlockList ? "1" : "2",
                        effectiveDate: dayjs().format(DATE_TIME_AM_PM_FORMAT),
                        visitors: values.visitorsCheckbox.map((visitorId: number) => {
                            const visitor = selectedVisit?.visitors.find(v => Number(v.id) === Number(visitorId));
                            return {
                                firstName: visitor?.firstName,
                                lastName: visitor?.lastName,
                                middleName: visitor?.middleName,
                            };
                        })
                    })
                };

                // Add note
                if (values.detail) {
                    await postRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitorLog`, {
                        logUserId: globalUserId,
                        log: values.detail
                    });
                }
            } catch (error) {
                console.error("Failed in denyEntry or createRedFlag.");
            }
        }
        await dispatch(fetchVisits({ orgId }));
        await dispatch(fetchVisitsToday({ orgId }));

        form.resetFields();
    }, [orgId, globalUserId, form, selectedVisit, newStatus, dispatch, updateVisitStatus, updateVisitorStatus]);

    const onOkHandler = async () => {
        const selectedVisitors = form.getFieldValue('visitorsCheckbox') || [];
        if (selectedVisitors.length === 0) {
            notification.warning({
                message: 'No Visitors Selected',
                description: 'Please select at least one visitor to proceed.',
                placement: 'bottomRight',
            });
            return;
        }
        form.submit();
        onClose();
    };

    const getTitle = () => {
        switch (newStatus) {
            case Number(STATUS.SIGNED_IN.id):
                return 'Select Visitors to Sign In';
            case Number(STATUS.SIGNED_OUT.id):
                return 'Select Visitors to Sign Out';
            case Number(STATUS.DENIED_ENTRY.id):
                return 'Deny Entry';
            default:
                return '';
        }
    };

    const handleBlockListChange = (checked: boolean) => {
        if (subscriptions?.data[0]?.package?.code === "package-essential") {
            // Prevent the change and show the modal
            setFeatureUnavailableModalOpen(true);
            form.setFieldsValue({ addToBlockList: false }); // Reset the value in the form
        } else {
            setAddToBlockList(checked);
            if (checked) {
                setAddToWatchList(false);
                form.setFieldsValue({ addToWatchList: false });
            }
        }
    };

    const handleWatchListChange = (checked: boolean) => {
        if (subscriptions?.data[0]?.package?.code === "package-essential") {
            // Prevent the change and show the modal
            setFeatureUnavailableModalOpen(true);
            form.setFieldsValue({ addToWatchList: false }); // Reset the value in the form
        } else {
            setAddToWatchList(checked);
            if (checked) {
                setAddToBlockList(false);
                form.setFieldsValue({ addToBlockList: false });
            }
        }
    };

    const finalOnCancel = (e: React.MouseEvent | React.KeyboardEvent) => {
        if (form.isFieldsTouched()) {
            confirmModal({
                title: 'Confirm discard?',
                content: 'You have unsaved changes. Are you sure you wish to discard them?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    form.resetFields();
                    setAddToBlockList(false);
                    setAddToWatchList(false);
                    onClose();
                },
            });
        } else {
            setAddToBlockList(false);
            setAddToWatchList(false);
            onClose();
        }
    };

    return (
        <>
            <OpModal
                title={getTitle()}
                open={open}
                onOk={onOkHandler}
                onCancel={finalOnCancel}
                centered
            >
                <OpDivider />

                <OpForm
                    form={form}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    hasError={false}
                    defaultButtons={false}
                >
                    <OpForm.CheckboxGroup name="visitorsCheckbox" >
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            {visitorOptions?.map(option => (
                                <OpForm.Checkbox key={option.value} value={option.value} style={{ marginRight: '8px' }}>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        {profileIcon({ firstName: option.firstName || "", lastName: option.lastName || "", size: 28, color: option.color })}
                                        <span style={{ marginLeft: '8px' }}>{option.label}</span>
                                    </span>
                                </OpForm.Checkbox>
                            ))}
                        </div>
                    </OpForm.CheckboxGroup>

                    {newStatus === Number(STATUS.DENIED_ENTRY.id) && (
                        <>
                            <OpForm.Select
                                label="Reason For Denial" name="reasonForDenial"
                                options={denyReasons.map(reason => ({
                                    label: reason.name,
                                    value: reason.id
                                }))}
                            />

                            <OpForm.TextAreaInput label="Details" name="detail" rows={4} />

                            {hasRedFlagWrite && (
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <OpForm.Switch name="addToBlockList" style={{ marginBottom: 0 }} checked={addToBlockList} onChange={handleBlockListChange} />
                                        <span style={{ marginLeft: 8 }}>Add to Blocklist</span>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <OpForm.Switch name="addToWatchList" style={{ marginBottom: 0 }} checked={addToWatchList} onChange={handleWatchListChange} />
                                        <span style={{ marginLeft: 8 }}>Add to Watchlist</span>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </OpForm>
            </OpModal>
            <OpModal
                open={featureUnavailableModalOpen}
                onCancel={() => setFeatureUnavailableModalOpen(false)}
                onOk={() => setFeatureUnavailableModalOpen(false)}
                title="Feature Not Included"
            >
                <p>Your account does not currently include this feature. Please contact your administrator.</p>
            </OpModal>
        </>
    );
};

export default AccessControlModal;
