import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiResponse, ApiErrorResponse } from 'types/apiTypes';
import { InvitationConfig, VisitorInvitationPayload, InvitationDocument, CreateVisitorInvitationResponse } from 'types/visitorInvitationTypes';
import { fetchVisits } from './visitsSlice';

interface VisitorInvitationState {
    invitationConfig: InvitationConfig | null;
    invitationDocuments: ApiResponse<InvitationDocument>;
    invitationSuccess: boolean;
    loadingInvitationConfig: boolean;
    loadingCreateInvitation: boolean;
    loadingUpdateInvitationConfig: boolean;
    loadingInvitationDocuments: boolean;
    error: string | null;
}

const initialState: VisitorInvitationState = {
    invitationConfig: null,
    invitationDocuments: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    invitationSuccess: false,
    loadingInvitationConfig: false,
    loadingCreateInvitation: false,
    loadingUpdateInvitationConfig: false,
    loadingInvitationDocuments: false,
    error: null,
};

// Retrieve the base URL from environment variables
const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
    console.error('REACT_APP_BACKEND_URL is not set');
}

export const createVisitorInvitation = createAsyncThunk<ApiResponse<CreateVisitorInvitationResponse>, { orgId: number; payload: VisitorInvitationPayload }, { rejectValue: string; dispatch: any }>(
    'visitorInvitation/createVisitorInvitation',
    async ({ orgId, payload }, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.post(`${apiUrl}/orgs/${orgId}/visitorInvitation`, payload, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchVisits({ orgId }));
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to create visitor invitation');
        }
    }
);

export const fetchInvitationConfig = createAsyncThunk<ApiResponse<InvitationConfig>, { orgId: number }, { rejectValue: string }>(
    'visitorInvitation/fetchInvitationConfig',
    async ({ orgId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<InvitationConfig>>(`${apiUrl}/orgs/${orgId}/visitorInvitationConfig`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch invitation config');
        }
    }
);

export const updateInvitationConfig = createAsyncThunk<void, { orgId: number, config: Partial<InvitationConfig> }, { rejectValue: string; dispatch: any }>(
    'visitorInvitation/updateInvitationConfig',
    async ({ orgId, config }, { dispatch, rejectWithValue }) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/visitorInvitationConfig`, config, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchInvitationConfig({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update invitation config');
        }
    }
);

export const fetchInvitationDocuments = createAsyncThunk<ApiResponse<InvitationDocument>, { orgId: number }, { rejectValue: string }>(
    'visitorInvitation/fetchInvitationDocuments',
    async ({ orgId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<InvitationDocument>>(`${apiUrl}/orgs/${orgId}/visitorInvitationDocument`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch invitation documents');
        }
    }
);

export const updateInvitationDocuments = createAsyncThunk<void, { orgId: number, documentIds?: number[] }, { rejectValue: string; dispatch: any }>(
    'visitorInvitation/updateInvitationDocuments',
    async ({ orgId, documentIds }, { dispatch, rejectWithValue }) => {
        try {
            const payload = documentIds?.length ? { id: documentIds } : {};
            await axios.patch(`${apiUrl}/orgs/${orgId}/visitorInvitationDocument`, payload, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchInvitationDocuments({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update invitation documents');
        }
    }
);

const visitorInvitationSlice = createSlice({
    name: 'visitorInvitation',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadingInvitationConfig = false;
            state.loadingCreateInvitation = false;
            state.loadingUpdateInvitationConfig = false;
            state.loadingInvitationDocuments = false;
            state.error = null;
            state.invitationSuccess = false;
        },
        clearVisitorInvitationState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createVisitorInvitation.pending, (state) => {
                state.loadingCreateInvitation = true;
                state.error = null;
                state.invitationSuccess = false;
            })
            .addCase(createVisitorInvitation.fulfilled, (state) => {
                state.loadingCreateInvitation = false;
                state.invitationSuccess = true;
            })
            .addCase(createVisitorInvitation.rejected, (state, action) => {
                state.loadingCreateInvitation = false;
                state.error = action.payload || 'Failed to create visitor invitation';
                state.invitationSuccess = false;
            })
            .addCase(fetchInvitationConfig.pending, (state) => {
                state.loadingInvitationConfig = true;
                state.error = null;
            })
            .addCase(fetchInvitationConfig.fulfilled, (state, action) => {
                state.loadingInvitationConfig = false;
                state.invitationConfig = action.payload.data[0];
            })
            .addCase(fetchInvitationConfig.rejected, (state, action) => {
                state.loadingInvitationConfig = false;
                state.error = action.payload || 'Failed to fetch invitation config';
            })
            .addCase(updateInvitationConfig.pending, (state) => {
                state.loadingUpdateInvitationConfig = true;
                state.error = null;
            })
            .addCase(updateInvitationConfig.fulfilled, (state) => {
                state.loadingUpdateInvitationConfig = false;
            })
            .addCase(updateInvitationConfig.rejected, (state, action) => {
                state.loadingUpdateInvitationConfig = false;
                state.error = action.payload || 'Failed to update invitation config';
            })
            .addCase(fetchInvitationDocuments.pending, (state) => {
                state.loadingInvitationDocuments = true;
                state.error = null;
            })
            .addCase(fetchInvitationDocuments.fulfilled, (state, action) => {
                state.loadingInvitationDocuments = false;
                state.invitationDocuments = action.payload;
            })
            .addCase(fetchInvitationDocuments.rejected, (state, action) => {
                state.loadingInvitationDocuments = false;
                state.error = action.payload || 'Failed to fetch invitation documents';
            })
            .addCase(updateInvitationDocuments.pending, (state) => {
                state.loadingInvitationDocuments = true;
                state.error = null;
            })
            .addCase(updateInvitationDocuments.fulfilled, (state) => {
                state.loadingInvitationDocuments = false;
            })
            .addCase(updateInvitationDocuments.rejected, (state, action) => {
                state.loadingInvitationDocuments = false;
                state.error = action.payload || 'Failed to update invitation documents';
            });
    },
});

export const { resetState, clearVisitorInvitationState } = visitorInvitationSlice.actions;

export default visitorInvitationSlice.reducer;
