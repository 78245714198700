import dayjs from 'dayjs';
import { VisitorInvitation, InvitationConfig, ConfigItem, InvitationDocument, CreateVisitorInvitationResponse } from 'types/visitorInvitationTypes';
import { parseVisitorTypeResponse } from './visitorTypeParser';
import { parseHostResponse, parseScheduleByResponse, parseSiteResponse, parseVisitStatusResponse, parseVisitorResponse } from './visitParser';

export const parseVisitorInvitationResponse = (response: any): VisitorInvitation => ({
    id: Number(response.id),
    visitorType: parseVisitorTypeResponse(response.visitorType),
    company: response.company,
    site: parseSiteResponse(response.site),
    host: parseHostResponse(response.host),
    visitors: Array.isArray(response.visitors) ? response.visitors.map(parseVisitorResponse) : [],
    scheduleBy: parseScheduleByResponse(response.scheduleBy),
    scheduleAllDay: response.scheduleAllDay === '1' || response.scheduleAllDay === 1,
    scheduleStart: response.scheduleStart ? dayjs(response.scheduleStart).toISOString() : null,
    scheduleEnd: response.scheduleEnd ? dayjs(response.scheduleEnd).toISOString() : null,
    signIn: response.signIn ? dayjs(response.signIn).toISOString() : null,
    signOut: response.signOut ? dayjs(response.signOut).toISOString() : null,
    visitStatus: parseVisitStatusResponse(response.visitStatus),
    purpose: response.purpose,
    confirmReturnItems: response.confirmReturnItems === '1' || response.confirmReturnItems === 1,
});

export const parseInvitationConfigResponse = (response: any): InvitationConfig => ({
    firstName: parseConfigItemResponse(response.firstName),
    lastName: parseConfigItemResponse(response.lastName),
    email: parseConfigItemResponse(response.email),
    visitSchedule: parseConfigItemResponse(response.visitSchedule),
    visitCompany: parseConfigItemResponse(response.visitCompany),
    visitPhone: parseConfigItemResponse(response.visitPhone),
    visitPurpose: parseConfigItemResponse(response.visitPurpose),
    visitNotes: parseConfigItemResponse(response.visitNotes),
    host: parseConfigItemResponse(response.host),
    allowInvitations: response.allowInvitations,
    allowPreRegistration: response.allowPreRegistration,
    sendEmail: response.sendEmail,
    requireApproval: response.requireApproval,
    customMessage: response.customMessage,
    messageContent: response.messageContent
});


export const parseConfigItemResponse = (response: any): ConfigItem => ({
    included: response.included,
    required: response.required,
    configurable: response.configurable,
});

export const parseInvitationDocumentResponse = (response: any): InvitationDocument => {
    return ({
        id: Number(response.id),
        name: response.name,
        description: response.description,
        updatedAt: response.updatedAt ? dayjs(response.updatedAt).toISOString() : null,
        selected: response.selected === 1,
    })
};

export const parseCreateVisitorInvitationResponse = (response: any): CreateVisitorInvitationResponse => ({
    id: Number(response.id),
    visitors: Array.isArray(response.visitors) ? response.visitors.map(parseVisitorResponse) : [],
});
