import { List } from "antd";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";

const OptionsForm: React.FC = () => {
    return (
        <List
            header={"OPTIONS"}
            dataSource={[
                { name: 'hostExclude', label: 'Exclude as Host' }
            ]}
            bordered
            renderItem={item => (
                <List.Item>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <OpForm.Switch name={item.name} />
                        <span style={{ marginLeft: 8 }}>{item.label}</span>
                    </div>
                </List.Item>
            )}
        />
    );
};

export default OptionsForm;
