import { OpCol } from "components/customAntd/DLS/OpCol/OpCol";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { OpRow } from "components/customAntd/DLS/OpRow/OpRow";

const DetailsForm: React.FC = () => {
    return (
        <>
            <OpForm.Input label="Address" name="address" />
            <OpForm.Input label="Address 2" name="address2" />
            <OpRow gutter={16}>
                <OpCol span={12}>
                    <OpForm.Input label="City" name="city" />
                </OpCol>
                <OpCol span={12}>
                    <OpForm.Input label="State" name="region" />
                </OpCol>
            </OpRow>
            <OpRow gutter={16}>
                <OpCol span={12}>
                    <OpForm.Input label="Zip" name="postalcode" />
                </OpCol>
                <OpCol span={12}>
                    <OpForm.Input label="Country" name="country" />
                </OpCol>
            </OpRow>
            <OpForm.Input label="Phone" name="phone" />
        </>
    );
};

export default DetailsForm;
