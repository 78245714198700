import React, { useCallback, useMemo, useState } from 'react';
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { List, notification } from 'antd';
import { createAgreement, updateAgreement, updateAgreementContent } from 'store/slices/agreementsSlice';
import { DATE_TIME_AM_PM_FORMAT } from 'constants/dates';
import dayjs from 'dayjs';
import { hasPermission } from 'utils/utils';
import { logUserActivity } from 'store/slices/userActivitySlice';

interface AgreementContentProps {
    form: any;
    tab: string;
}

const availability = [
    { label: 'For Invitations', name: 'forInvitation', valuePropName: 'checked' },
    { label: 'For Sign In', name: 'forSignIn', valuePropName: 'checked' },
];

// AgreementContent component definition
const AgreementContent: React.FC<AgreementContentProps> = ({ form, tab }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const { selectedAgreement, selectedDocumentContent } = useSelector((state: RootState) => state.agreements);
    const [type, setType] = useState<string>(selectedAgreement?.type ? selectedAgreement.type : 'text');

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAgreementsRead = hasPermission(tokenScopeList, orgId, 'o', 'agreements:r');
    const hasAgreementsWrite = hasPermission(tokenScopeList, orgId, 'o', 'agreements:w');

    // Create initial values based on the selectedAgreement and selectedDocumentContent
    const initialValues = useMemo(() => {
        if (selectedAgreement && selectedDocumentContent) {
            return {
                name: selectedAgreement.name,
                description: selectedAgreement.description,
                type: selectedAgreement.type,
                updatedAt: dayjs(selectedAgreement.updatedAt).utc(true).local().format(DATE_TIME_AM_PM_FORMAT),
                forInvitation: selectedAgreement.forInvitation,
                forSignIn: selectedAgreement.forSignIn,
                forSignOut: selectedAgreement.forSignOut,
                forRegistration: selectedAgreement.forRegistration,
                content: selectedDocumentContent.content,
            };
        } else {
            return {
                forInvitation: true,
                forSignIn: true,
                type: type
            }
        }
    }, [selectedAgreement, selectedDocumentContent, type]);

    // Handle form submission
    const handleSubmit = useCallback(
        async ({ values, touchedValues, initialValues }: IOnSubmitArgs<any>) => {
            // Transform null values to empty strings and boolean values to 1/0
            const transformedValues = Object.keys(touchedValues).reduce((acc, key) => {
                let value = touchedValues[key];

                // Convert boolean values to 1/0
                if (typeof value === 'boolean') {
                    value = value ? 1 : 0;
                } else if (value === null) {
                    value = ''; // Handle null values
                }

                acc[key] = value;
                return acc;
            }, {} as Record<string, any>);

            // Exclude 'content' from transformedValues
            delete transformedValues.content;

            if (selectedAgreement) {
                // Update the existing agreement
                if (transformedValues && Object.keys(transformedValues).length > 0 && selectedAgreement.id) {
                    dispatch(updateAgreement({
                        orgId,
                        agreementId: selectedAgreement.id,
                        agreement: transformedValues
                    }));
                }
                await dispatch(logUserActivity({ orgId, userActivity: { userId: globalUserId!, activityId: "115", details: transformedValues.name || '' } }))

                if (values.content !== undefined && values.content !== initialValues?.content && selectedAgreement.id) {
                    dispatch(updateAgreementContent({
                        orgId,
                        agreementId: selectedAgreement.id,
                        content: { content: values.content }
                    }));
                }

                notification.success({
                    message: 'Success',
                    description: 'Agreement updated successfully.',
                    placement: 'bottomRight',
                });
            } else {
                // Set the 'type' property to 'text' (this will be modified later)
                transformedValues.type = 'text';

                // Create a new agreement
                const resultAction = await dispatch(createAgreement({
                    orgId,
                    agreement: transformedValues,
                }));
                await dispatch(logUserActivity({ orgId, userActivity: { userId: globalUserId!, activityId: "110", details: transformedValues.name || '' } }))

                if (createAgreement.fulfilled.match(resultAction)) {
                    const newAgreement = resultAction.payload;
                    if (newAgreement.data[0].id) {
                        await dispatch(updateAgreementContent({
                            orgId,
                            agreementId: newAgreement.data[0].id,
                            content: { content: values.content }
                        }));
                    }

                    notification.success({
                        message: 'Success',
                        description: 'Agreement created successfully.',
                        placement: 'bottomRight',
                    });

                    form.resetFields();
                } else {
                    notification.error({
                        message: 'Error',
                        description: 'Failed to create the agreement.',
                        placement: 'bottomRight',
                    });
                }
            }
        },
        [dispatch, selectedAgreement, orgId, form, globalUserId]
    );

    return (
        <OpForm
            form={form}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            hasError={false}
            defaultButtons={false}
            isReadOnly={!hasAgreementsWrite && hasAgreementsRead}
        >
            {tab === 'agreement' && (
                <>
                    <OpForm.Input label="Name" name="name" rules={[{ required: true, message: 'Please enter the agreement name' }]} />
                    <OpForm.Input label="Description" name="description" />
                    {!selectedAgreement && (
                        <OpForm.Select
                            name="type" label="Type"
                            onChange={(value) => setType(value as string)}
                            options={[
                                { label: "Text", value: "text" },
                                // { label: "URL", value: "url" }
                            ]}
                        />
                    )}
                    {type === 'url' && (
                        <OpForm.Input name="content" label="Document URL" />
                    )}
                    {selectedAgreement && (
                        <OpForm.Input disabled label="Updated At" name="updatedAt" />
                    )}
                    <List
                        header={<div>AVAILABILITY</div>}
                        dataSource={availability}
                        bordered
                        renderItem={item => (
                            <List.Item>
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <OpForm.Switch
                                        name={item.name} style={{ marginBottom: 0 }}
                                        disabled={!hasAgreementsWrite && hasAgreementsRead} />
                                    <span style={{ marginLeft: 8 }}>{item.label}</span>
                                </div>
                            </List.Item>
                        )}
                    />
                </>
            )}
            {tab === 'content' && (
                <>
                    {type === 'text' && (
                        <OpForm.TextAreaInput rows={30} name="content" label="Content" />
                    )}
                </>
            )}
        </OpForm >
    );
};

export default AgreementContent;
