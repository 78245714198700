import { List } from "antd";
import { getRequest } from "api/apiClient";
import { OpButton } from "components/customAntd/DLS/OpButton/OpButton";
import STATUS from "constants/status";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { Agreement } from "types/agreementTypes";
import { DocumentCheck } from "types/visitTypes";
import { CheckOutlined } from "@ant-design/icons";

interface AgreementsTabProps {
    extendedVisitorInfo: DocumentCheck;
    allAgreements: Agreement[];
    signedAgreements: Agreement[];
}

const AgreementsTab: React.FC<AgreementsTabProps> = ({ extendedVisitorInfo, allAgreements, signedAgreements }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { selectedVisit, selectedVisitor } = useSelector((state: RootState) => state.visits);

    const handleViewSignedAgreements = async () => {
        try {
            const response = await getRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitors/${selectedVisitor?.id}/agreement`, {}, {
                responseType: 'blob'
            });
            const blob = new Blob([response], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'agreements.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Failed to fetch visitor agreements.', error);
        }
    };

    return (
        <>
            <List
                itemLayout="horizontal"
                dataSource={selectedVisitor?.status === STATUS.PENDING.id ? allAgreements : signedAgreements}
                bordered
                renderItem={(agreement) => (
                    <List.Item
                        extra={
                            signedAgreements.some(signedAgreement =>
                                signedAgreement.id === agreement.id
                            ) && <CheckOutlined style={{ color: 'green' }} />
                        }
                    >
                        <List.Item.Meta
                            title={
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    {agreement.name}
                                </div>
                            }
                        />
                    </List.Item>
                )}
            />
            {(extendedVisitorInfo?.agreement !== null) && (
                <OpButton onClick={handleViewSignedAgreements} type="primary" ghost style={{ width: '100%', marginTop: '10px' }}>
                    View Signed Agreements
                </OpButton>
            )}
        </>
    );
};

export default AgreementsTab;
