export const LOCATION_CREATED_ID = 10;
export const LOCATION_EDITED_ID = 15;
export const LOCATION_DELETED_ID = 20;

export const USER_CREATED_ID = 30;
export const USER_EDITED_ID = 35;
export const USER_DELETED_ID = 40;

export const KIOSK_CREATED_ID = 50;
export const KIOSK_EDITED_ID = 55;
export const KIOSK_DELETED_ID = 60;

export const WORKFLOW_CREATED_ID = 70;
export const WORKFLOW_EDITED_ID = 75;
export const WORKFLOW_DELETED_ID = 80;

export const ROLE_CREATED_ID = 90;
export const ROLE_EDITED_ID = 95;
export const ROLE_DELETED_ID = 100;

export const AGREEMENT_CREATED_ID = 110;
export const AGREEMENT_EDITED_ID = 115;
export const AGREEMENT_DELETED_ID = 120;

export const FIELD_CREATED_ID = 130;
export const FIELD_EDITED_ID = 135;
export const FIELD_DELETED_ID = 140;

export const VISITOR_INVITATION_CREATED_ID = 200;
export const SIGNED_IN_VISITOR_ID = 300;
export const SIGNED_OUT_VISITOR_ID = 400;