import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';

import './CustomFormButtons.scss';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';

interface CustomFormButtonsProps {
    className?: string;
    form: ComponentProps<typeof OpForm>['form'];
    isFormLoading?: boolean;
    isSubmitButtonLoading?: boolean;
    submitButtonLabel?: string;
    onResetClick: () => void; // Add onResetClick prop
}

export const CustomFormButtons = ({
    className,
    form,
    isFormLoading = false,
    isSubmitButtonLoading = false,
    submitButtonLabel = 'Save',
    onResetClick, // Use onResetClick passed as a prop
}: CustomFormButtonsProps) => {
    const { t } = useTranslation();
    const confirmModal = useConfirmModal();

    const handleResetClick = () => {
        if (form?.isFieldsTouched()) {
            confirmModal({
                title: t('Confirm discard?'),
                content: t('You have unsaved changes. Are you sure you wish to discard them?'),
                okText: t('Yes'),
                cancelText: t('No'),
                onOk: onResetClick, // Use the provided onResetClick function
            });
        } else {
            onResetClick(); // If no changes, directly call the reset logic
        }
    };

    const onSubmitClick = () => {
        form?.submit();
    };

    return (
        <OpSpace className={clsx('form-buttons', className)} size="middle">
            <OpButton
                testId="reset-form-button"
                disabled={isFormLoading}
                onClick={handleResetClick} // Use the new reset handler
            >
                {t('Reset')}
            </OpButton>
            <OpButton
                testId="submit-form-button"
                type="primary"
                onClick={onSubmitClick}
                disabled={isFormLoading}
                loading={isSubmitButtonLoading}
            >
                {submitButtonLabel}
            </OpButton>
        </OpSpace>
    );
};
