import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiResponse, ApiErrorResponse } from 'types/apiTypes';
import { DenyReason, DenyEntryPayload, DenyEntry } from 'types/denyTypes';

interface DenyState {
    denyReasons: ApiResponse<DenyReason>;
    fetchDenyReasonsLoading: boolean;
    denyEntryLoading: boolean;
    describeDenyReasonLoading: boolean;
    describeDeniedEntryLoading: boolean;
    error: string | null;
}

const initialState: DenyState = {
    denyReasons: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    fetchDenyReasonsLoading: false,
    describeDenyReasonLoading: false,
    describeDeniedEntryLoading: false,
    denyEntryLoading: false,
    error: null,
};

// Retrieve the base URL from environment variables
const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
    console.error('REACT_APP_BACKEND_URL is not set');
}

export const fetchDenyReasons = createAsyncThunk<ApiResponse<DenyReason>, { orgId: number }, { rejectValue: string }>(
    'deny/fetchDenyReasons',
    async ({ orgId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<DenyReason>>(`${apiUrl}/orgs/${orgId}/denyReason`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch deny reasons');
        }
    }
);

export const describeDenyReason = createAsyncThunk<DenyReason, { orgId: number, reasonId: number }, { rejectValue: string }>(
    'deny/describeDenyReason',
    async ({ orgId, reasonId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<DenyReason>(`${apiUrl}/orgs/${orgId}/denyReason/${reasonId}`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to describe deny reason');
        }
    }
);

export const describeDeniedEntry = createAsyncThunk<DenyEntry, { orgId: number, visitId: number }, { rejectValue: string }>(
    'deny/describeDeniedEntry',
    async ({ orgId, visitId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<DenyEntry>(`${apiUrl}/orgs/${orgId}/visitor/${visitId}/denyEntry`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to describe denied entry');
        }
    }
);

export const denyEntry = createAsyncThunk<void, { orgId: number, visitId: number, denyEntry: Partial<DenyEntryPayload> }, { rejectValue: string }>(
    'deny/denyEntry',
    async ({ orgId, visitId, denyEntry }, { rejectWithValue }) => {
        try {
            await axios.post(`${apiUrl}/orgs/${orgId}/visitor/${visitId}/denyEntry`, denyEntry, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to deny entry');
        }
    }
);

const denySlice = createSlice({
    name: 'deny',
    initialState,
    reducers: {
        clearDenyReasons(state) {
            state.denyReasons = initialState.denyReasons;
        },
        clearError(state) {
            state.error = null;
        },
        clearDenyState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDenyReasons.pending, (state) => {
                state.fetchDenyReasonsLoading = true;
                state.error = null;
            })
            .addCase(fetchDenyReasons.fulfilled, (state, action) => {
                state.fetchDenyReasonsLoading = false;
                state.denyReasons = action.payload;
            })
            .addCase(fetchDenyReasons.rejected, (state, action) => {
                state.fetchDenyReasonsLoading = false;
                state.error = action.payload || 'Failed to fetch deny reasons';
            })
            .addCase(describeDenyReason.pending, (state) => {
                state.describeDenyReasonLoading = true;
                state.error = null;
            })
            .addCase(describeDenyReason.fulfilled, (state) => {
                state.describeDenyReasonLoading = false;
            })
            .addCase(describeDenyReason.rejected, (state, action) => {
                state.describeDenyReasonLoading = false;
                state.error = action.payload || 'Failed to describe deny reason';
            })
            .addCase(describeDeniedEntry.pending, (state) => {
                state.describeDeniedEntryLoading = true;
                state.error = null;
            })
            .addCase(describeDeniedEntry.fulfilled, (state) => {
                state.describeDeniedEntryLoading = false;
            })
            .addCase(describeDeniedEntry.rejected, (state, action) => {
                state.describeDeniedEntryLoading = false;
                state.error = action.payload || 'Failed to describe denied entry';
            })
            .addCase(denyEntry.pending, (state) => {
                state.denyEntryLoading = true;
                state.error = null;
            })
            .addCase(denyEntry.fulfilled, (state) => {
                state.denyEntryLoading = false;
            })
            .addCase(denyEntry.rejected, (state, action) => {
                state.denyEntryLoading = false;
                state.error = action.payload || 'Failed to deny entry';
            });
    },
});

export const { clearDenyReasons, clearError, clearDenyState } = denySlice.actions;
export default denySlice.reducer;
