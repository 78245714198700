import React, { useState, useEffect } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { OpCard } from 'components/customAntd/DLS/OpCard/OpCard';
import { marked } from 'marked';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";

interface Release {
    id: number;
    name: string;
    body: string;
    tag_name: string;
    published_at: string;
}

// Extract the content under the "### Bug Fixes" heading in a Markdown text
const extractChangelogSection = (body: string): string => {
    const regex = /### Bug Fixes([\s\S]*?)(?=###|\n## |\n$)/;
    const match = body.match(regex);

    if (match) {
        // Remove text within parentheses, including the parentheses themselves
        const cleanedSection = match[0].replace(/\s*\([^()]*\)/g, '');
        return cleanedSection.trim(); // Trim any trailing whitespace
    }

    return 'No bug fixes found';
};
const ReleaseNotes: React.FC = () => {
    const [releases, setReleases] = useState<Release[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchReleases = async () => {
            try {
                const response = await fetch('https://api.github.com/repos/skimcorp/psr_antd/releases', {
                    headers: {
                        Authorization: `Bearer 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`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setReleases(data);
            } catch (error) {
                console.error('Error fetching releases:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchReleases();
    }, []);

    return (
        <OpPage title="Release Notes">
            <OpSpace direction="vertical" size="large" style={{ width: '100%' }}>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    </div>
                ) : (
                    releases.map((release) => (
                        <OpCard key={release.id} title={release.name || release.tag_name}>
                            <p><strong>Published:</strong> {new Date(release.published_at).toLocaleDateString()}</p>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: marked(extractChangelogSection(release.body)) as string,
                                }}
                            />
                        </OpCard>
                    ))
                )}
            </OpSpace>
        </OpPage>
    );
};

export default ReleaseNotes;
