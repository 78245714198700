import React from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { REPEATVISITORS_TOOLTIP } from 'constants/tooltip';
import RepeatVisitorsTable from './RepeatVisitorsTable';

const RepeatVisitors: React.FC = () => {
    return (
        <OpPage title="Repeat Visitors" tooltip={REPEATVISITORS_TOOLTIP} subtitle="">
            <RepeatVisitorsTable />
        </OpPage>
    );
}

export default RepeatVisitors;
