import dayjs from 'dayjs';
import { Agreement, AgreementContent, Content } from 'types/agreementTypes';

export const parseAgreementResponse = (response: any): Agreement => {
    return ({
        id: Number(response.id),
        name: response.name,
        description: response.description,
        type: response.type,
        updatedAt: response.updatedAt ? dayjs(response.updatedAt).toISOString() : null,
        status: Number(response.status),
        forInvitation: response.forInvitation === '1' || response.forInvitation === 1,
        forSignIn: response.forSignIn === '1' || response.forSignIn === 1,
        forSignOut: response.forSignOut === '1' || response.forSignOut === 1,
        forRegistration: response.forRegistration === '1' || response.forRegistration === 1,
    })
};

export const parseAgreementContentResponse = (response: any): AgreementContent => ({
    document: parseContentResponse(response.document),
    updatedAt: response.updatedAt ? dayjs(response.updatedAt).toISOString() : null,
    content: response.content,
});

export const parseContentResponse = (response: any): Content => {
    return ({
        id: Number(response.id),
        name: response.name,
        description: response.description,
        type: response.type,
        updatedAt: response.updatedAt ? dayjs(response.updatedAt).toISOString() : null,
        status: Number(response.status),
        forInvitation: response.forInvitation === '1' || response.forInvitation === 1,
        forSignIn: response.forSignIn === '1' || response.forSignIn === 1,
        forSignOut: response.forSignOut === '1' || response.forSignOut === 1,
        forRegistration: response.forRegistration === '1' || response.forRegistration === 1,
    })
};