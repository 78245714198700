import React from 'react';
import { FormInstance } from 'antd';
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { OpSection } from "components/customAntd/DLS/OpSection/OpSection";

interface CustomizationFormProps {
    form: FormInstance;
    isCustomMessageOn: number;
    setIsCustomMessageOn: (checked: number) => void;
}

const CustomizationForm: React.FC<CustomizationFormProps> = ({ form, isCustomMessageOn, setIsCustomMessageOn }) => {
    const handleSwitchChange = (checked: boolean) => {
        form.setFieldsValue({ customMessage: checked ? 1 : 0 });
        setIsCustomMessageOn(checked ? 1 : 0);
    };

    return (
        <OpSection label="Customization">
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <OpForm.Switch
                    name="customMessage"
                    checked={isCustomMessageOn === 1 ? true : false}
                    onChange={handleSwitchChange}
                />
                <span style={{ marginLeft: 8 }}>Custom Message</span>
            </div>
            {(isCustomMessageOn === 1) && (
                <div style={{ marginTop: '16px' }}>
                    <OpForm.TextAreaInput
                        rows={3}
                        name="messageContent"
                        placeholder="Message Content"
                        maxLength={4000}
                    />
                </div>
            )}
        </OpSection>
    );
};

export default CustomizationForm;
