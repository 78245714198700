import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { hasPermission } from 'utils/utils';
import { TABLE_HEIGHT } from 'constants/ui';
import {
    CONFIRM_DELETE_CONTENT,
    CONFIRM_DELETE_TITLE,
    FIELD_DELETE_ERROR,
    FIELD_DELETE_SUCCESS,
    NOTIFICATION_ERROR,
    NOTIFICATION_SUCCESS,
} from 'constants/messages';
import { FormField } from 'types/formFieldTypes';
import { getRequest, patchRequest, postRequest } from 'api/apiClient';
import { FIELD_DELETED_ID } from 'constants/userActivities';
import { notification } from 'antd';

interface FormFieldsTableProps {
    formFields: FormField[];
    setFormFields: (formFields: FormField[]) => void;
    handleEditClick: (formField: FormField) => void;
    handleAddClick: () => void;
    loading: boolean;
}

const FormFieldsTable: React.FC<FormFieldsTableProps> = ({ formFields, setFormFields, handleEditClick, handleAddClick, loading }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasFormFieldsWrite = hasPermission(tokenScopeList, orgId, 'o', 'fields:w');

    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'name',
            label: 'NAME',
            filter: { type: 'input' },
            sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
        },
        {
            dataIndex: 'placeholder',
            label: 'PLACEHOLDER',
            filter: { type: 'input' },
            sorter: (a, b) => (a.placeholder || '').localeCompare(b.placeholder || ''),
        },
    ];

    const handleDeleteFormField = async (formField: FormField) => {
        try {
            await patchRequest(`/orgs/${orgId}/formField/${formField.id}`, {
                status: 0
            });
            await postRequest(`/orgs/${orgId}/userActivity`, {
                userId: globalUserId,
                activityId: FIELD_DELETED_ID,
                details: formField.name
            });

            // Re-fetch the updated list of fields
            const updatedFields = await getRequest(`/orgs/${orgId}/formField`);
            const filteredUpdatedFormFields = updatedFields.data.filter((field: FormField) => field.status === 1);
            setFormFields(filteredUpdatedFormFields);
            notification.success({
                message: NOTIFICATION_SUCCESS,
                description: FIELD_DELETE_SUCCESS,
                placement: 'bottomRight',
            });
        } catch (error) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: FIELD_DELETE_ERROR,
                placement: 'bottomRight',
            });
        }
    };

    // Table properties
    const opTableProps: IOpTableProps = {
        dataSource: formFields,
        columns: columns,
        rowActions: {
            onEditClick: (formFields: FormField) => handleEditClick(formFields),
            onDeleteClick: hasFormFieldsWrite ? handleDeleteFormField : undefined,
            deleteModalTitle: () => CONFIRM_DELETE_TITLE("Form Field"),
            deleteModalContent: ({ record }) => CONFIRM_DELETE_CONTENT(record.name),
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        writeAccess: hasFormFieldsWrite,
        allowAddition: hasFormFieldsWrite ? {
            itemName: 'Form Field',
            onClick: () => handleAddClick(),
        } : false,
        loading: loading,
        allowExport: true,
        allowShowHideColumns: true,
    };

    return (
        <OpTable {...opTableProps} />
    );
};

export default FormFieldsTable;
