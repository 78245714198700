import React from 'react';
import { Form, Input, Button, Typography } from 'antd';

const { Text } = Typography;

const SignUpForm: React.FC<{ onFinish: (values: any) => void; loading: boolean }> = ({ onFinish, loading }) => {
    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            style={{ maxWidth: '400px', margin: '0 auto' }}
        >
            <Typography.Title level={4} style={{ textAlign: 'center' }}>
                Start using InVisit today
            </Typography.Title>
            <Text style={{ marginBottom: '24px', display: 'block', textAlign: 'center' }}>
                Start your free trial with access to all the features.
            </Text>

            <Form.Item
                name="firstname"
                label="First Name"
                rules={[{ required: true, message: 'First name is required' }]}
                style={{ marginBottom: '24px' }}
            >
                <Input autoComplete="off" />
            </Form.Item>

            <Form.Item
                name="lastname"
                label="Last Name"
                rules={[{ required: true, message: 'Last name is required' }]}
                style={{ marginBottom: '24px' }}
            >
                <Input autoComplete="off" />
            </Form.Item>

            <Form.Item
                name="email"
                label="Email Address"
                rules={[
                    { required: true, message: 'Email is required' },
                    { type: 'email', message: 'Not a valid email' },
                ]}
                style={{ marginBottom: '24px' }}
            >
                <Input autoComplete="off" />
            </Form.Item>

            <Form.Item
                name="name"
                label="Company Name"
                rules={[{ required: true, message: 'Company name is required' }]}
                style={{ marginBottom: '24px' }}
            >
                <Input autoComplete="off" />
            </Form.Item>

            <Form.Item style={{ marginBottom: '24px' }}>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                >
                    Sign Up
                </Button>
            </Form.Item>

            <Text type="secondary" style={{ textAlign: 'center', display: 'block' }}>
                By creating an account, you consent to InVisit's <a href="https://invisit.us/about/tos/" target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a href="https://invisit.us/about/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
            </Text>
        </Form>
    );
};

export default SignUpForm;
