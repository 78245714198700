import React, { useEffect, useState, useCallback } from 'react';
import { Form, Button, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from 'store/store';
import { clearAuthState, login } from 'store/slices/authSlice';
import { setGlobalOrgId } from 'store/slices/globalOrgSlice';
import { describeLocation, describeLocationWorkflow, fetchLocations } from 'store/slices/locationsSlice';
import { describeUser } from 'store/slices/usersSlice';
import { fetchSubscriptions } from 'store/slices/subscriptionsSlice';
import { LoginPayload } from 'types/authTypes';
import { hasPermission } from 'utils/utils';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import './login.scss';

const appVersion = process.env.REACT_APP_VERSION;

const LogIn: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const { auth, error } = useSelector((state: RootState) => state.auth);

    const [autoRedirect, setAutoRedirect] = useState(false);
    const [isForgotPassword, setIsForgotPassword] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            const identityId = localStorage.getItem('identityId');
            const createdAt = localStorage.getItem('createdAt');
            const expiresAt = localStorage.getItem('expiresAt');
            const tokenScopeList = localStorage.getItem('tokenScopeList');

            if (identityId && createdAt && expiresAt && tokenScopeList) {
                const storedAuthData = {
                    token,
                    identityId,
                    createdAt,
                    expiresAt,
                    tokenScopeList: JSON.parse(tokenScopeList),
                };
                dispatch({
                    type: 'auth/setAuthData',
                    payload: {
                        data: [storedAuthData],
                        meta: null,
                        totalCount: 1,
                        filteredCount: 1,
                    },
                });
            }
        }
    }, [dispatch]);

    const handleOrganizationSelection = useCallback(async (orgId: number, userId: number) => {
        dispatch(setGlobalOrgId(orgId));
        dispatch(describeUser({ orgId, userId, global: true }));
        dispatch(fetchSubscriptions({ orgId }));

        const locationsResult = await dispatch(fetchLocations({ orgId, status: 1 }));
        if (fetchLocations.fulfilled.match(locationsResult) && locationsResult.payload.data.length !== 0) {
            const firstLocation = locationsResult.payload.data[0];
            if (firstLocation.id) {
                await dispatch(describeLocation({ orgId, locationId: firstLocation.id, global: true }));
                await dispatch(describeLocationWorkflow({ orgId, locationId: firstLocation.id, global: true }));
            }
        }

        const tokenScopeList = auth.data[0].tokenScopeList || [];
        const permissions = {
            hasDashRead: hasPermission(tokenScopeList, orgId, 'o', 'dash:r'),
            hasVisitorsRead: hasPermission(tokenScopeList, orgId, 'o', 'visitors:r'),
            hasLocationRead: hasPermission(tokenScopeList, orgId, 'o', 'location:r'),
            hasUsersRead: hasPermission(tokenScopeList, orgId, 'o', 'user:r'),
            hasRolesRead: hasPermission(tokenScopeList, orgId, 'o', 'role:r'),
            hasKiosksRead: hasPermission(tokenScopeList, orgId, 'o', 'kiosk:r'),
            hasRedFlagConfRead: hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:r'),
            hasInvitationRead: hasPermission(tokenScopeList, orgId, 'o', 'invitation:r'),
            hasWorkflow: hasPermission(tokenScopeList, orgId, 'o', 'signinconf:r') || hasPermission(tokenScopeList, orgId, 'o', 'signoutconf:r'),
            hasFieldsRead: hasPermission(tokenScopeList, orgId, 'o', 'fields:r'),
            hasAgreementsRead: hasPermission(tokenScopeList, orgId, 'o', 'agreements:r'),
            hasAccountRead: hasPermission(tokenScopeList, orgId, 'o', 'account:r'),
            hasIntegrationRead: hasPermission(tokenScopeList, orgId, 'o', 'integration:r'),
            hasRptVisitorRead: hasPermission(tokenScopeList, orgId, 'o', 'rptvisitor:r'),
            hasRptTrendRead: hasPermission(tokenScopeList, orgId, 'o', 'rpttrend:r'),
            hasRptRedFlagRead: hasPermission(tokenScopeList, orgId, 'o', 'rptredflag:r'),
            hasRptHostRead: hasPermission(tokenScopeList, orgId, 'o', 'rpthost:r'),
            hasRptActivityRead: hasPermission(tokenScopeList, orgId, 'o', 'rptactivity:r'),
            hasRptGuestPassRead: hasPermission(tokenScopeList, orgId, 'o', 'rptguestpass:r'),
        };

        const routes = [
            permissions.hasDashRead && '/dashboard',
            permissions.hasVisitorsRead && '/visitors',
            permissions.hasLocationRead && '/locations',
            permissions.hasUsersRead && '/users/users',
            permissions.hasRolesRead && '/users/roles',
            permissions.hasKiosksRead && '/kiosks',
            permissions.hasRedFlagConfRead && '/redFlag',
            permissions.hasInvitationRead && '/configuration/invitations',
            permissions.hasWorkflow && '/configuration/workflow',
            permissions.hasFieldsRead && '/configuration/formFields',
            permissions.hasAgreementsRead && '/configuration/agreements',
            permissions.hasAccountRead && '/administration/account',
            permissions.hasIntegrationRead && '/administration/integration',
            permissions.hasRptVisitorRead && '/reports/visitorReport',
            permissions.hasRptTrendRead && '/reports/repeatVisitors',
            permissions.hasRptRedFlagRead && '/reports/redFlagReport',
            permissions.hasRptGuestPassRead && '/reports/guestPassReport',
            permissions.hasRptHostRead && '/reports/hostReport',
            permissions.hasRptActivityRead && '/reports/activityLog',
        ].filter(Boolean);

        if (routes.length > 0) {
            navigate(routes[0], { replace: true });
        } else {
            navigate('/support', { replace: true });
        }
    }, [auth, dispatch, navigate]);

    useEffect(() => {
        if (auth.data.length === 1) {
            if (auth.data[0].tokenScopeList.length === 0) {
                notification.error({
                    message: 'Login Error',
                    description: 'Credentials are incorrect.',
                    placement: 'bottomRight',
                });
                dispatch(clearAuthState());
                return;
            }

            if (auth.data[0].tokenScopeList.length === 1) {
                const singleOrg = auth.data[0].tokenScopeList[0].org;
                const singleUser = auth.data[0].tokenScopeList[0].user;
                setAutoRedirect(true);
                if (singleOrg.id != null && singleUser.id != null) {
                    handleOrganizationSelection(singleOrg.id, singleUser.id);
                }
            }
        }
    }, [auth, handleOrganizationSelection, dispatch]);

    const onFinish = async (values: LoginPayload) => {
        await dispatch(login(values));
    };

    const handleBack = () => {
        dispatch(clearAuthState());
    };

    if (autoRedirect) {
        return null;
    }

    return (
        <div className="auth-wrapper">
            <div className="auth-wrapper__left">
                <div className="auth-wrapper__left-content">
                    <div className="auth-wrapper__left-logo">
                        <img src="/images/invisit_full.png" alt="Logo" />
                    </div>
                    <div className="login">
                        {isForgotPassword ? (
                            <ForgotPasswordForm />
                        ) : auth.data.length === 0 ? (
                            <LoginForm onFinish={onFinish} error={error} loading={false} />
                        ) : (
                            <>
                                {auth.data[0].tokenScopeList.map((item: any) => (
                                    <Form.Item key={item.org.id}>
                                        <Button
                                            type="primary"
                                            className="button"
                                            onClick={() => handleOrganizationSelection(item.org.id, item.user.id)}
                                        >
                                            {item.org.name}
                                        </Button>
                                    </Form.Item>
                                ))}
                                <Button onClick={handleBack} className="button">Back</Button>
                            </>
                        )}
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '50px' }}>
                        <Button
                            onClick={() => setIsForgotPassword(!isForgotPassword)}
                            type="link"
                            className="button"
                            style={{ fontWeight: 'bold' }}
                        >
                            {isForgotPassword ? 'Return to sign in' : 'Forgot password?'}
                        </Button>
                    </div>
                    {appVersion && (
                        <div className="version-number" style={{ textAlign: 'center', marginTop: '80px', fontSize: '12px', color: '#888' }}>
                            Build {appVersion}
                        </div>
                    )}
                </div>
            </div>
            <div className="auth-wrapper__right">
                <div className="auth-wrapper__right">
                    {/* Marketing text or other content */}
                </div>
            </div>
        </div>
    );
};

export default LogIn;
