import React, { useState, useEffect } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import AttendantSignIn from './AttendantSignIn/AttendantSignIn';
import AttendantSignOut from './AttendantSignOut/AttendantSignOut';
import KioskSignIn from './KioskSignIn/KioskSignIn';
import KioskSignOut from './KioskSignOut/KioskSignOut';
import { WORKFLOW_TOOLTIP } from 'constants/tooltip';
import { hasPermission } from 'utils/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';

const Workflow: React.FC = () => {
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const subscriptions = useSelector((state: RootState) => state.subscriptions.subscriptions);

    const hasSigninconfRead = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:r');
    const hasSignoutconfRead = hasPermission(tokenScopeList, orgId, 'o', 'signoutconf:r');

    const [activeKey, setActiveKey] = useState<string>(hasSigninconfRead ? 'attendantSignIn' : 'attendantSignOut');
    const [featureUnavailableModalOpen, setFeatureUnavailableModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!hasSigninconfRead) {
            setActiveKey('attendantSignOut');
        }
    }, [hasSigninconfRead]);

    const handleTabChange = (newActiveKey: string) => {
        const isSubscriptionValid = subscriptions?.data[0]?.package?.code !== "package-essential";

        if (
            (newActiveKey === 'kioskSignIn' || newActiveKey === 'kioskSignOut') &&
            !isSubscriptionValid
        ) {
            setFeatureUnavailableModalOpen(true);
        } else {
            setActiveKey(newActiveKey);
        }
    };

    const items = [
        {
            key: 'attendantSignIn',
            label: 'Attendant Sign In',
            children: <AttendantSignIn />,
            show: hasSigninconfRead,
        },
        {
            key: 'attendantSignOut',
            label: 'Attendant Sign Out',
            children: <AttendantSignOut />,
            show: hasSignoutconfRead,
        },
        {
            key: 'kioskSignIn',
            label: 'Kiosk Sign In',
            children: <KioskSignIn />,
            show: hasSigninconfRead,
        },
        {
            key: 'kioskSignOut',
            label: 'Kiosk Sign Out',
            children: <KioskSignOut />,
            show: hasSignoutconfRead,
        },
    ].filter(item => item.show);

    return (
        <>
            <OpPage title="Workflow" tooltip={WORKFLOW_TOOLTIP} subtitle="">
                <OpTabs
                    activeKey={activeKey}
                    onChange={handleTabChange}
                    items={items}
                />
            </OpPage>

            <OpModal
                open={featureUnavailableModalOpen}
                onCancel={() => setFeatureUnavailableModalOpen(false)}
                onOk={() => setFeatureUnavailableModalOpen(false)}
                title="Feature Not Included"
            >
                <p>Your account does not currently include this feature. Please contact your administrator.</p>
            </OpModal>
        </>
    );
}

export default Workflow;
