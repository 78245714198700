import { Divider, List } from "antd";
import { Form } from "antd";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { OpButton } from "components/customAntd/DLS/OpButton/OpButton";
import { hasPermission } from "utils/utils";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

const FieldForm: React.FC = () => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasFormFieldsWrite = hasPermission(tokenScopeList, orgId, 'o', 'fields:w');

    return (
        <>
            <OpForm.Input
                label="Name"
                name="name"
                rules={[
                    { required: true, message: 'Enter the name of the location.' },
                ]}

                maxLength={30}
            />
            <OpForm.Input
                label="Placeholder"
                name="placeholder"
                maxLength={30}
            />
            <Divider />
            <Form.List name="values">
                {(values, { add, remove }) => (
                    <>
                        {values.length > 0 && (
                            <List
                                locale={{ emptyText: ' ' }}
                                header={<div>Values</div>}
                                dataSource={values}
                                bordered
                                renderItem={(value, index) => (
                                    <List.Item
                                        key={value.key}
                                        style={{ width: '100%', display: 'flex', alignItems: 'center' }}
                                    >
                                        <div style={{ flexGrow: 1, marginRight: 8 }}>
                                            <OpForm.Input
                                                label={`Value ${index + 1}`}
                                                name={[value.name, 'value']}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                        <MinusCircleOutlined
                                            onClick={() => {
                                                if (hasFormFieldsWrite) {
                                                    remove(value.name);
                                                }
                                            }} style={{
                                                color: hasFormFieldsWrite ? 'red' : 'gray',
                                                cursor: hasFormFieldsWrite ? 'pointer' : 'not-allowed',
                                                flexShrink: 0,
                                            }}
                                        />
                                    </List.Item>
                                )}
                            />
                        )}
                        <OpButton type="dashed" onClick={() => add()} disabled={!hasFormFieldsWrite} style={{ marginTop: '8px' }} block icon={<PlusOutlined />}>
                            Add Pre-Set Value
                        </OpButton>
                    </>
                )}
            </Form.List>
        </>
    );
};

export default FieldForm;
