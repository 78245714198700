import React, { useEffect, useState } from 'react';
import { Modal, Button, Empty, List } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import STATUS from 'constants/status';
import dayjs from 'dayjs';
import { formatFullName, hasPermission } from 'utils/utils';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { Visit, Visitor } from 'types/visitTypes';
import { getRequest } from 'api/apiClient';
import { DATE_TIME_AM_PM_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';
import { OpSelect } from 'components/customAntd/DLS/OpSelect/OpSelect';
import { getStatusColor, profileIcon } from 'utils/visitorsHelper';
import { setSelectedVisit } from 'store/slices/visitsSlice';

interface VisitorOption {
    label: string;
    value: string;
    visitId: number;
    visitorId: number;
    visit: Visit;
    visitor: Visitor;
}

interface SignInModalProps {
    open: boolean;
    onClose: () => void;
    openVisitorsDrawer: () => void;
    openSignInManuallyDrawer: () => void;
    visitorNameFilter?: {
        firstName: string;
        middleName: string;
        lastName: string;
    };
}

const SignInModal: React.FC<SignInModalProps> = ({ open, onClose, openVisitorsDrawer, openSignInManuallyDrawer, visitorNameFilter }) => {
    const dispatch: AppDispatch = useDispatch();

    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAllvisitorsWrite = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:w');

    const [visitorOptions, setVisitorOptions] = useState<VisitorOption[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    // Fetch visitor options
    useEffect(() => {
        const fetchVisitorOptions = async () => {
            setLoading(true);
            try {
                const visitors = await getRequest(`/orgs/${orgId}/visitor`, {
                    siteId: globalLocationId,
                    startDate: dayjs().startOf('day').format(DATE_TIME_FORMAT),
                    endDate: dayjs().endOf('day').format(DATE_TIME_FORMAT),
                });

                const filteredPendingVisitors = visitors.data
                    .filter((visit: Visit) => {
                        return (hasAllvisitorsWrite || (globalUserId === visit.host.userId)) &&
                            visit.visitors.some(visitor => visitor.status === STATUS.PENDING.id);
                    })
                    .flatMap((visit: Visit) => {
                        return visit.visitors
                            .filter(visitor => visitor.status === STATUS.PENDING.id)
                            .filter(visitor => {
                                // Apply visitor name filter
                                if (!visitorNameFilter) {
                                    return true;
                                }
                                const firstNameMatch = visitorNameFilter.firstName === "NONE" || visitor.firstName?.toLowerCase() === visitorNameFilter.firstName.toLowerCase();
                                const lastNameMatch = visitorNameFilter.lastName === "NONE" || visitor.lastName?.toLowerCase() === visitorNameFilter.lastName.toLowerCase();
                                return firstNameMatch && lastNameMatch;
                            })
                            .map(visitor => {
                                return {
                                    label: formatFullName(visitor.firstName, visitor.middleName, visitor.lastName),
                                    value: `${visit.id}-${visitor.id}`,
                                    visitId: visit.id,
                                    visitorId: visitor.id,
                                    visit: visit,
                                    visitor: visitor
                                };
                            });
                    })

                // If visitorNameFilter is not provided, add the "Add New Visitor" option
                if (!visitorNameFilter && filteredPendingVisitors.length > 0) {
                    filteredPendingVisitors.push({
                        label: ' Add New Visitor',
                        value: 'add-new-visitor',
                        visitId: -1,
                        visitorId: -1,
                        visit: {},
                        style: {
                            color: 'var(--colorPrimary)',
                        },
                    });
                }

                setVisitorOptions(filteredPendingVisitors);
            } catch (error) {
                console.log("Failed to fetch visitor options.");
            } finally {
                setLoading(false);
            }
        };
        fetchVisitorOptions();
    }, [orgId, globalLocationId, globalUserId, hasAllvisitorsWrite, visitorNameFilter]);

    const handleOptionSelect = (value: string, option: VisitorOption) => {
        if (value === 'add-new-visitor') {
            openSignInManuallyDrawer();
        } else {
            dispatch(setSelectedVisit(option.visit));
            openVisitorsDrawer();
        }
        onClose();
    };

    return (
        <Modal
            title="Visitor to Sign In"
            open={open}
            onCancel={onClose}
            footer={null}
            centered
        >
            <OpSpace direction="vertical" size="large" style={{ display: 'flex', paddingTop: '10px' }}>
                {visitorNameFilter && visitorOptions.length > 1 ? (
                    <List
                        itemLayout="horizontal"
                        dataSource={visitorOptions}
                        renderItem={item => {
                            return (
                                <List.Item
                                    onClick={() => handleOptionSelect(item.value, item)}
                                    style={{ cursor: 'pointer', transition: 'background-color 0.3s' }}
                                >
                                    <List.Item.Meta
                                        avatar={profileIcon({ firstName: item.visitor.firstName || '', lastName: item.visitor.lastName || '', size: 40, color: getStatusColor("Pending") })}
                                        title={item.label}
                                        description={dayjs(item.visit.scheduleStart).format(DATE_TIME_AM_PM_FORMAT)}
                                    />
                                </List.Item>
                            );
                        }}
                        style={{ overflowY: 'auto', maxHeight: '50vh' }}
                    />
                ) : (
                    <OpSelect
                        options={visitorOptions}
                        placeholder="Select visitor or type name to sign in"
                        onChange={(value, option) => handleOptionSelect(value as string, option as VisitorOption)}
                        showSearch
                        filterOption={(input, option) =>
                            String(option?.label)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        loading={loading}
                        style={{ width: '100%' }}
                        notFoundContent={
                            <Empty description="No visitors found">
                                <Button
                                    type="primary"
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        openSignInManuallyDrawer();
                                        onClose();
                                    }}
                                >
                                    Add New Visitor
                                </Button>
                            </Empty>
                        }
                    />
                )}
            </OpSpace>
        </Modal>
    );
};

export default SignInModal;
