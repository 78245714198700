import { Role } from 'types/roleTypes';

export const parseRoleResponse = (role: any): Role => ({
    id: Number(role.id),
    name: role.name,
    description: role.description,
    admin: role.admin === '1' || role.admin === 1,
    status: Number(role.status),
    userCount: role.userCount !== null ? Number(role.userCount) : null,
    scopes: Array.isArray(role.scopes) ? role.scopes.map((scope: any) => Number(scope)) : [],
});
