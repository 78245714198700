import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Select, Empty } from "antd";
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { HourglassOutlined, LoadingOutlined, LoginOutlined, LogoutOutlined, StopOutlined } from "@ant-design/icons";
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { OpRow } from 'components/customAntd/DLS/OpRow/OpRow';
import { OpCol } from 'components/customAntd/DLS/OpCol/OpCol';
import { OpCard } from 'components/customAntd/DLS/OpCard/OpCard';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { RootState, AppDispatch } from "store/store";
import { fetchVisitsToday, setSelectedVisit, setSelectedVisitor } from 'store/slices/visitsSlice';
import DashboardCard from './DashboardCard';
import VisitorsTrendChart from './VisitorsTrendChart';
import { describeLocation, describeLocationWorkflow, fetchLocations } from 'store/slices/locationsSlice';
import STATUS from 'constants/status';
import { DASHBOARD_TOOLTIP } from 'constants/tooltip';
import { Visit, Visitor } from 'types/visitTypes';
import { formatFullName, hasPermission } from 'utils/utils';
import CardInfoModal from './CardInfoModal';
import VisitorsDrawer from '../visitors/drawer/VisitorsDrawer';
import { getStatusNameById } from 'utils/visitorsHelper';
import { RedFlag } from 'types/redFlagTypes';
import { getRequest } from 'api/apiClient';
import { DATE_TIME_FORMAT } from 'constants/dates';
dayjs.extend(isBetween);

interface ModalContent {
    name: string;
    firstName?: string;
    lastName?: string;
    dateTime: string;
    statusName: string;
    visitId: number;
    visitorId: number;
}

interface VisitorEntry {
    visitor: Visitor;
    visitId: number;
    scheduleStart?: string | null;
}

const Dashboard: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);

    const { locations, globalLocation, fetchLocationsLoading, describeLocationLoading } = useSelector((state: RootState) => state.locations);
    const { visitsToday, fetchVisitsLoading } = useSelector((state: RootState) => state.visits);

    const [visitsLast7Days, setvisitsLast7Days] = useState<Visit[]>([]);
    const [visitorsToday, setVisitorsToday] = useState<Visitor[]>([]);
    const [chartData, setChartData] = useState<Visitor[]>([]);


    const [redFlags, setRedFlags] = useState<RedFlag[]>([]);
    const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);

    const [visitorsComingToday, setVisitorsComingToday] = useState<VisitorEntry[]>([]);
    const [visitorsSignedInToday, setVisitorsSignedInToday] = useState<VisitorEntry[]>([]);
    const [visitorsSignedOutToday, setVisitorsSignedOutToday] = useState<VisitorEntry[]>([]);
    const [visitorsDeniedEntryToday, setVisitorsDeniedEntryToday] = useState<VisitorEntry[]>([]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState<ModalContent[]>([]);
    const [modalTitle, setModalTitle] = useState('');

    const [isVisitorsDrawerOpen, setIsVisitorsDrawerOpen] = useState<boolean>(false);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasDashRead = hasPermission(tokenScopeList, orgId, 'o', 'dash:r');
    const hasAllvisitorsRead = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:r');

    const handleCardClick = (visitors: VisitorEntry[], dateKey: keyof Visitor | 'scheduleStart', title: string) => {
        if (visitors.length === 0) {
            return; // Do nothing if there are no visitors
        }

        const formattedVisitors = visitors.map(({ visitor, visitId, scheduleStart }) => ({
            name: formatFullName(visitor.firstName, visitor.middleName, visitor.lastName),
            firstName: visitor.firstName || undefined,
            lastName: visitor.lastName || undefined,
            dateTime: dateKey === 'scheduleStart' ?
                (scheduleStart ? dayjs(scheduleStart).format('MMMM D, YYYY h:mm A') : 'N/A') :
                (visitor[dateKey as keyof Visitor] ? dayjs(visitor[dateKey as keyof Visitor] as string).format('MMMM D, YYYY h:mm A') : 'N/A'),
            statusName: getStatusNameById(visitor.status!, scheduleStart),
            visitId: visitId,
            visitorId: visitor.id
        }));

        // Sort formattedVisitors by dateTime in decreasing order
        const sortedVisitors = formattedVisitors.sort((a, b) => dayjs(b.dateTime).valueOf() - dayjs(a.dateTime).valueOf());

        setModalContent(sortedVisitors);
        setModalTitle(title);
        setIsModalVisible(true);
    };

    useEffect(() => {
        if (orgId) {
            dispatch(fetchVisitsToday({ orgId }));
            dispatch(fetchLocations({ orgId, status: 1 }));
        }
    }, [orgId, dispatch]);


    // Fetch workflows
    useEffect(() => {
        const fetchVisitsLast7Days = async () => {
            try {
                const visitsLast7Days = await getRequest(`/orgs/${orgId}/visitor`, {
                    siteId: globalLocationId,
                    startDate: dayjs().subtract(6, 'days').startOf('day').format(DATE_TIME_FORMAT),
                    endDate: dayjs().endOf('day').format(DATE_TIME_FORMAT),
                });
                setvisitsLast7Days(visitsLast7Days.data);
            } catch (error) {
                console.log("Failed to fetch visitor workflows.");
            }
        };
        fetchVisitsLast7Days();
    }, [orgId, globalLocationId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const redFlags = await getRequest(`/orgs/${orgId}/redflag`);
                setRedFlags(redFlags.data);
            } catch (error) {
                console.log("Failed to fetch data.");
            }
        };
        fetchData();
    }, [orgId]);

    const handleLocationChange = async (locationId: number) => {
        await dispatch(describeLocation({ orgId, locationId, global: true }));
        await dispatch(describeLocationWorkflow({ orgId, locationId, global: true }));
    };

    useEffect(() => {
        const todayStart = dayjs().startOf('day');
        const todayEnd = dayjs().endOf('day');

        const visitors: Visitor[] = [];

        const visitorsComing: VisitorEntry[] = [];
        const visitorsSignedIn: VisitorEntry[] = [];
        const visitorsSignedOut: VisitorEntry[] = [];
        const visitorsDeniedEntry: VisitorEntry[] = [];

        const filteredVisits = visitsToday.filter(visit => {
            return visit.site.id === globalLocation?.id && (hasAllvisitorsRead || globalUserId === visit.host.userId);
        });

        filteredVisits.forEach(visit => {
            visit.visitors.forEach(visitor => {
                const conditionMet = (
                    (visitor.status === STATUS.PENDING.id && visit.scheduleStart && dayjs(visit.scheduleStart).isBetween(todayStart, todayEnd, null, '[]')) ||
                    (visitor.status === STATUS.SIGNED_IN.id && visitor.signIn && dayjs(visitor.signIn).isBetween(todayStart, todayEnd, null, '[)')) ||
                    (visitor.status === STATUS.SIGNED_OUT.id && visitor.signOut && dayjs(visitor.signOut).isBetween(todayStart, todayEnd, null, '[)')) ||
                    (visitor.status === STATUS.DENIED_ENTRY.id && visitor.signIn && dayjs(visitor.signIn).isBetween(todayStart, todayEnd, null, '[)'))
                );
                if (conditionMet) {
                    visitors.push(visitor);
                    const entry: VisitorEntry = { visitor, visitId: visit.id, scheduleStart: visit.scheduleStart };
                    switch (visitor.status) {
                        case STATUS.PENDING.id:
                            visitorsComing.push(entry);
                            break;
                        case STATUS.SIGNED_IN.id:
                            visitorsSignedIn.push(entry);
                            break;
                        case STATUS.SIGNED_OUT.id:
                            visitorsSignedOut.push(entry);
                            break;
                        case STATUS.DENIED_ENTRY.id:
                            visitorsDeniedEntry.push(entry);
                            break;
                    }
                }
            });
        });
        setVisitorsToday(visitors);
        setVisitorsComingToday(visitorsComing);
        setVisitorsSignedInToday(visitorsSignedIn);
        setVisitorsSignedOutToday(visitorsSignedOut);
        setVisitorsDeniedEntryToday(visitorsDeniedEntry);
    }, [visitsToday, globalLocation?.id, globalUserId, hasAllvisitorsRead]);

    useEffect(() => {
        if (visitsLast7Days) {
            const filteredVisitors = visitsLast7Days.reduce((acc: Visitor[], visit) => {
                if (visit.site?.id === globalLocation?.id && (hasAllvisitorsRead || globalUserId === visit.host.userId)) {
                    const visitors = visit.visitors.filter(visitor =>
                        (visitor.status === STATUS.SIGNED_IN.id || visitor.status === STATUS.SIGNED_OUT.id) &&
                        dayjs(visitor.signIn).isAfter(dayjs().subtract(7, 'day'))
                    );
                    return [...acc, ...visitors];
                }
                return acc;
            }, []);
            setChartData(filteredVisitors);
        }
    }, [visitsToday, globalLocation?.id, globalUserId, hasAllvisitorsRead, visitsLast7Days]);

    const handleClickedVisitor = (visitId: number, visitorId: number) => {
        const selectedVisit = visitsToday.find(v => Number(v.id) === Number(visitId));
        const selectedVisitor = selectedVisit?.visitors.find(v => Number(v.id) === Number(visitorId));

        dispatch(setSelectedVisit(selectedVisit!));
        dispatch(setSelectedVisitor(selectedVisitor!));
        setIsVisitorsDrawerOpen(true);
        setIsProfileDrawerOpen(true);
        setIsModalVisible(false);
        setModalContent([]);
    }

    const handleDrawerClose = () => {
        setIsVisitorsDrawerOpen(false);
        dispatch(setSelectedVisit(null));
    };

    return (
        <OpPage title="Dashboard" tooltip={DASHBOARD_TOOLTIP} contentRight={
            hasDashRead && (
                <Select
                    placeholder="Select Location"
                    onChange={handleLocationChange}
                    style={{ width: 200 }}
                    loading={fetchLocationsLoading || describeLocationLoading}
                    options={locations.data.map(location => ({
                        label: location.name,
                        value: location.id
                    }))}
                    // Only set defaultValue once locations and globalLocation are loaded
                    defaultValue={
                        !fetchLocationsLoading && !describeLocationLoading && globalLocation
                            ? globalLocation.id
                            : undefined
                    }
                    value={
                        !fetchLocationsLoading && !describeLocationLoading && globalLocation
                            ? globalLocation.id
                            : undefined
                    }
                />
            )
        }>
            {hasDashRead ? (
                <OpSpace
                    direction="vertical"
                    size="middle"
                    style={{ display: 'flex' }}
                >
                    <OpCard type="inner" title="Visitors Today" extra={dayjs().format('dddd, M/D')}>
                        {fetchVisitsLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                            </div>
                        ) : (
                            <OpRow justify="space-evenly">
                                <OpCol span={6} style={{ padding: '30px' }}>
                                    <DashboardCard
                                        icon={
                                            <HourglassOutlined
                                                style={{
                                                    color: "white",
                                                    backgroundColor: "rgba(19,151,213,1.0)",
                                                    borderRadius: 20,
                                                    fontSize: 24,
                                                    padding: 8,
                                                }}
                                            />
                                        }
                                        title="COMING"
                                        value={visitorsToday.filter(visitor => visitor.status === STATUS.PENDING.id).length}
                                        color="rgba(19,151,213,0.1)"
                                        onClick={() => handleCardClick(visitorsComingToday, 'scheduleStart', 'Visitors Coming Today')}
                                    />
                                </OpCol>
                                <OpCol span={6} style={{ padding: '30px' }}>
                                    <DashboardCard
                                        icon={
                                            <LoginOutlined
                                                style={{
                                                    color: "white",
                                                    backgroundColor: "rgba(109,152,138,1.0)",
                                                    borderRadius: 20,
                                                    fontSize: 24,
                                                    padding: 8,
                                                }}
                                            />
                                        }
                                        title="HERE"
                                        value={visitorsToday.filter(visitor => visitor.status === STATUS.SIGNED_IN.id).length}
                                        color="rgba(109,152,138,0.1)"
                                        onClick={() => handleCardClick(visitorsSignedInToday, 'signIn', 'Visitors Here Today')}
                                    />
                                </OpCol>
                                <OpCol span={6} style={{ padding: '30px' }}>
                                    <DashboardCard
                                        icon={
                                            <LogoutOutlined
                                                style={{
                                                    color: "white",
                                                    backgroundColor: "rgba(155,154,154,1.0)",
                                                    borderRadius: 20,
                                                    fontSize: 24,
                                                    padding: 8,
                                                }}
                                            />
                                        }
                                        title="LEFT"
                                        value={visitorsToday.filter(visitor => visitor.status === STATUS.SIGNED_OUT.id).length}
                                        color="rgba(155,154,154,0.1)"
                                        onClick={() => handleCardClick(visitorsSignedOutToday, 'signOut', 'Visitors Left Today')}
                                    />
                                </OpCol>
                                <OpCol span={6} style={{ padding: '30px' }}>
                                    <DashboardCard
                                        icon={
                                            <StopOutlined
                                                style={{
                                                    color: "white",
                                                    backgroundColor: "rgba(242,103,87,1.0)",
                                                    borderRadius: 20,
                                                    fontSize: 24,
                                                    padding: 8,
                                                }}
                                            />
                                        }
                                        title="DENIED"
                                        value={visitorsToday.filter(visitor => visitor.status === STATUS.DENIED_ENTRY.id).length}
                                        color="rgba(242,103,87,0.1)"
                                        onClick={() => handleCardClick(visitorsDeniedEntryToday, 'signIn', 'Visitors Denied Entry Today')}
                                    />
                                </OpCol>
                            </OpRow>
                        )}
                    </OpCard>
                    <OpCard type="inner" title="Visitors Trend" extra={`${dayjs().subtract(6, 'day').format('M/D')} - ${dayjs().format('M/D')}`}>
                        {fetchVisitsLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                            </div>
                        ) : (
                            <VisitorsTrendChart visitors={chartData} />
                        )}
                    </OpCard>
                </OpSpace>
            ) : (
                <OpRow justify="center" align="middle" style={{ height: '100%' }}>
                    <OpCol>
                        <Empty description={
                            <p>Additional permissions required to access this page.</p>
                        } />
                    </OpCol>
                </OpRow>
            )}

            {isModalVisible && (
                <CardInfoModal
                    visible={isModalVisible}
                    title={modalTitle}
                    content={modalContent}
                    onCancel={() => { setIsModalVisible(false) }}
                    onItemClick={handleClickedVisitor}
                />
            )}

            {(isVisitorsDrawerOpen) && (
                <VisitorsDrawer
                    open={isVisitorsDrawerOpen}
                    redFlags={redFlags}
                    isProfileDrawerOpen={isProfileDrawerOpen}
                    onClose={handleDrawerClose}
                    setIsProfileDrawerOpen={setIsProfileDrawerOpen}
                />
            )}
        </OpPage>
    );
}

export default Dashboard;
