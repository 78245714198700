import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { RootState, AppDispatch } from 'store/store';
import { DATE_TIME_AM_PM_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';
import { TABLE_HEIGHT } from 'constants/ui';
import { formatFullName, hasPermission } from 'utils/utils';
import { GuestPass } from 'types/guestPassTypes';
import { getRequest } from 'api/apiClient';
import { getStatusNameById } from 'utils/visitorsHelper';
import { RedFlag } from 'types/redFlagTypes';
import { fetchVisits, setSelectedVisit, setVisitsEndDate, setVisitsStartDate } from 'store/slices/visitsSlice';
import VisitorsDrawer from 'components/pages/visitors/drawer/VisitorsDrawer';
import DateRangeLocationFilter2 from 'components/customAntd/DateRangeLocationFilter2';

dayjs.extend(isBetween);

interface GuestPassReportTableData {
    id: number;
    visitId: number;
    visitor: string;
    status: string;
    validFrom: string;
    validTo: string;
}

const GuestPassReportTable: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const triggerUpdate = useSelector((state: RootState) => state.guestPass.triggerUpdate);

    const { visits } = useSelector((state: RootState) => state.visits);

    const [isVisitorsDrawerOpen, setIsVisitorsDrawerOpen] = useState(false);

    const [guestPasses, setGuestPasses] = useState<GuestPass[]>([]);
    const [selectedLocationId, setSelectedLocationId] = useState<number>(globalLocationId!);
    const [startDate, setStartDate] = useState(dayjs().startOf('week').utc());
    const [endDate, setEndDate] = useState(dayjs().endOf('week').utc());
    const [loading, setLoading] = useState<boolean>(false);

    const [redFlags, setRedFlags] = useState<RedFlag[]>([]);
    const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);

    const hasAllvisitorsRead = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:r');
    const hasGuestpassRead = hasPermission(tokenScopeList, orgId, 'o', 'guestpass:r');

    const fetchGuestPasses = async () => {
        setLoading(true);
        try {
            const guestPasses = await getRequest(`/orgs/${orgId}/msiAltaGuestPassByDate`, {
                startDate: dayjs(startDate).format(DATE_TIME_FORMAT),
                endDate: dayjs(endDate).format(DATE_TIME_FORMAT),
                status: 1
            });
            const filteredGuestPasses = guestPasses.data.filter((guestPass: GuestPass) => {
                const matchesLocation = guestPass.siteId === selectedLocationId;
                return matchesLocation && (hasAllvisitorsRead || globalUserId === guestPass.hostUserId);
            });
            setGuestPasses(filteredGuestPasses);
        } catch (error) {
            console.log("Failed to fetch guest passes.");
        } finally {
            setLoading(false);
        }
    };

    const fetchVisitsData = async () => {
        await dispatch(setVisitsStartDate(undefined));
        await dispatch(setVisitsEndDate(undefined));
        await dispatch(fetchVisits({ orgId }));
    };

    // Fetch guest passes
    useEffect(() => {
        const fetchData = async () => {
            try {
                const redFlags = await getRequest(`/orgs/${orgId}/redflag`);
                setRedFlags(redFlags.data);
            } catch (error) {
                console.log("Failed to fetch data.");
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchGuestPasses();
        fetchVisitsData();
        // eslint-disable-next-line
    }, [startDate, endDate, triggerUpdate]);

    // Table columns definition
    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'visitor',
            label: 'Visitor',
            filter: { type: 'input' },
            sorter: (a, b) => a.visitor.length - b.visitor.length
        },
        {
            dataIndex: 'status',
            label: 'VISIT STATUS',
            filter: {
                type: 'multiSelect',
                options: [
                    { label: 'Pending', value: 'Pending' },
                    { label: 'Signed In', value: 'Signed In' },
                    { label: 'Signed Out', value: 'Signed Out' },
                    { label: 'Denied Entry', value: 'Denied Entry' },
                    { label: 'No Show', value: 'No Show' },
                ],
            },
            defaultFilteredValue: ['Pending', 'Signed In', 'Signed Out', 'Denied Entry', 'No Show'],
            sorter: (a, b) => a.status.localeCompare(b.status),
            onFilter: (value, record) => {
                return record.status === value;
            },
            // render: (value) => {
            //     const color = getStatusColor(value);
            //     return <div style={{ backgroundColor: color, padding: '5px 10px', borderRadius: '4px', color: 'white' }}>{value}</div>;
            // }
        },
        {
            dataIndex: 'passStatus',
            label: "Pass Status",
            filter: {
                type: 'multiSelect',
                options: [
                    { label: 'Active', value: 'Active' },
                    { label: 'Inactive', value: 'Inactive' },
                ],
            },
            defaultFilteredValue: ['Active', 'Inactive'],
            sorter: (a, b) => a.passStatus.localeCompare(b.passStatus),
            onFilter: (value, record) => {
                return record.passStatus === value;
            },
            render: (value) => {
                const color = value === 'Active' ? '#709A7A' : 'grey';
                const textColor = 'white';
                return (
                    <div
                        style={{
                            backgroundColor: color,
                            color: textColor,
                            padding: '5px 10px',
                            borderRadius: '4px',
                            textAlign: 'center',
                        }}
                    >
                        {value}
                    </div>
                );
            },
        },
        {
            dataIndex: 'validFrom',
            label: "Valid From",
            sorter: (a, b) => dayjs(b.dateTime).diff(a.dateTime)
        },
        {
            dataIndex: 'validTo',
            label: "Valid To",
            sorter: (a, b) => dayjs(b.dateTime).diff(a.dateTime)
        }

    ];

    const handleDateRangeLocationFilter = (locationId: number, startDate: string, endDate: string) => {
        setSelectedLocationId(locationId);
        setStartDate(dayjs(startDate).utc());
        setEndDate(dayjs(endDate).utc());
    };

    const getPassStatus = (startUtc: string, endUtc: string): string => {
        const now = dayjs();
        return now.isBetween(dayjs(startUtc).utc(true), dayjs(endUtc).utc(true), null, '[]') ? 'Active' : 'Inactive';
    };

    // Format activities for table display
    const formatGuestPassesReportForTable = (guestPasses: GuestPass[]): GuestPassReportTableData[] => {
        return guestPasses.map((guestPass) => ({
            id: guestPass.id,
            visitId: guestPass.visitId,
            visitor: formatFullName(guestPass.visitor[0]?.firstName, guestPass.visitor[0]?.middleName, guestPass.visitor[0]?.lastName)
                + (guestPass.visitor.length > 1 ? ` (+${guestPass.visitor.length - 1})` : ''),
            status: getStatusNameById(guestPass.visitStatus, undefined),
            validFrom: dayjs(guestPass.startUtc).utc(true).local().format(DATE_TIME_AM_PM_FORMAT),
            validTo: dayjs(guestPass.endUtc).utc(true).local().format(DATE_TIME_AM_PM_FORMAT),
            passStatus: getPassStatus(guestPass.startUtc, guestPass.endUtc),
        }));
    };

    const handleVisitClick = (visit: any) => {
        const selectedVisit = visits.find(v => Number(v.id) === Number(visit.visitId));
        dispatch(setSelectedVisit(selectedVisit!));
        setIsVisitorsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsVisitorsDrawerOpen(false);
        dispatch(setSelectedVisit(null));
    };

    // Table properties
    const opTableProps: IOpTableProps = {
        dataSource: formatGuestPassesReportForTable(guestPasses),
        label: `Active Guest Passes`,
        columns: columns,
        rowActions: {
            onEditClick: handleVisitClick,
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        allowExport: true,
        allowShowHideColumns: true,
        loading: loading,
        gtm: 'activity-log-table-gtm',
        rowKey: 'id',
    };

    return (
        <OpSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
            <DateRangeLocationFilter2
                onDateRangeLocationFilter={handleDateRangeLocationFilter}
                initialStartDate={startDate.utc(true).local().format(DATE_TIME_FORMAT)}
                initialEndDate={endDate.utc(true).local().format(DATE_TIME_FORMAT)}
            />

            <OpTable {...opTableProps} />

            {(isVisitorsDrawerOpen) && (
                <VisitorsDrawer
                    open={isVisitorsDrawerOpen}
                    redFlags={redFlags}
                    isProfileDrawerOpen={isProfileDrawerOpen}
                    onClose={handleDrawerClose}
                    setIsProfileDrawerOpen={setIsProfileDrawerOpen}
                    tab={hasGuestpassRead ? 'guestPass' : 'visitors'}
                />
            )}
        </OpSpace>
    );
}

export default GuestPassReportTable;
