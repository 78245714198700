import React, { useState, useEffect, useRef } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { REDFLAGREPORTS_TOOLTIP } from 'constants/tooltip';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import RedFlagReportSummary from './RedFlagReportSummary';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import DateRangeLocationFilter2 from 'components/customAntd/DateRangeLocationFilter2';
import { fetchRedFlags } from 'store/slices/redFlagSlice';
import { RedFlag } from 'types/redFlagTypes';
import { fetchVisits, setSelectedVisit, setVisitsEndDate, setVisitsStartDate } from 'store/slices/visitsSlice';
import { Visitor } from 'types/visitTypes';
import { getStatusNameById, getVisitorDateTime } from 'utils/visitorsHelper';
import { DATE_TIME_FORMAT } from 'constants/dates';
import { hasPermission } from 'utils/utils';
import STATUS from 'constants/status';
import DeniedVistiorsTable from './DeniedVistiorsTable';
import RedFlagsDrawer from 'components/pages/redFlag/RedFlagsDrawer';
import RedFlagsTable from './RedFlagsTable';
import { notification } from 'antd';
import VisitorsDrawer from 'components/pages/visitors/drawer/VisitorsDrawer';

interface ExtendedVisitor extends Visitor {
    visitId: number;
};

const RedFlagReport: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();

    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);

    const { visits, fetchVisitsLoading } = useSelector((state: RootState) => state.visits);
    const { redFlags, fetchRedFlagsLoading } = useSelector((state: RootState) => state.redFlags);

    const [deniedVisitors, setDeniedVisitors] = useState<ExtendedVisitor[]>([]);
    const [filteredBlocklist, setFilteredBlocklist] = useState<RedFlag[]>([]);
    const [filteredWatchlist, setFilteredWatchlist] = useState<RedFlag[]>([]);

    const selectedLocationIdRef = useRef<number>(globalLocationId!);
    const startDateRef = useRef(dayjs().startOf('week').startOf('day').format(DATE_TIME_FORMAT));
    const endDateRef = useRef(dayjs().endOf('week').endOf('day').format(DATE_TIME_FORMAT));

    const [isVisitorsDrawerOpen, setIsVisitorsDrawerOpen] = useState(false);
    const [redFlagsDrawerOpen, setRedFlagsDrawerOpen] = useState(false);
    const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);

    const [activeTable, setActiveTable] = useState<'denied' | 'blockList' | 'watchList'>('denied');
    const [isTableLoading, setIsTableLoading] = useState(false);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasAllvisitorsRead = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:r');

    const fetchVisitsData = async () => {
        await dispatch(setVisitsStartDate(startDateRef.current));
        await dispatch(setVisitsEndDate(endDateRef.current));
        await dispatch(fetchVisits({ orgId }));
    };

    useEffect(() => {
        if (orgId) {
            fetchVisitsData();
            dispatch(fetchRedFlags({ orgId }));
        }
        // eslint-disable-next-line
    }, [dispatch, orgId]);

    useEffect(() => {
        if (!fetchVisitsLoading && !fetchRedFlagsLoading) {
            if (visits) {
                const filteredVisitors = visits.reduce<ExtendedVisitor[]>((acc, visit) => {
                    const extendedVisitor = visit.visitors
                        .filter(visitor => visitor.status === STATUS.DENIED_ENTRY.id && visitor.signIn)
                        .map(visitor => {
                            const statusName = getStatusNameById(visitor.status!, visit.scheduleStart);
                            const dateKey = getVisitorDateTime(visitor, statusName, visit?.scheduleStart, DATE_TIME_FORMAT);
                            return {
                                ...visitor,
                                visitId: visit.id,
                                dateKey
                            };
                        }).filter(visitor => visitor && dayjs(visitor.dateKey).isBetween(dayjs(startDateRef.current).startOf('day'), dayjs(endDateRef.current).endOf('day'), null, '[]'));
                    return [...acc, ...extendedVisitor];
                }, []);
                setDeniedVisitors(filteredVisitors);
            }

            if (redFlags && redFlags.data) {
                const filteredRedFlags = redFlags.data.filter(flag =>
                    dayjs(flag.effectiveDate).utc(true).local().isBetween(dayjs(startDateRef.current), dayjs(endDateRef.current), null, '[]')
                );

                const blockList = filteredRedFlags.filter(flag => flag.level === 1);
                const watchList = filteredRedFlags.filter(flag => flag.level === 2);

                setFilteredBlocklist(blockList);
                setFilteredWatchlist(watchList);
            }

            setIsTableLoading(false);
        }
    }, [visits, redFlags, fetchVisitsLoading, fetchRedFlagsLoading, globalUserId, hasAllvisitorsRead]);

    const handleDateRangeLocationFilter = (locationId: number, startDate: string, endDate: string) => {
        selectedLocationIdRef.current = locationId;
        startDateRef.current = startDate;
        endDateRef.current = endDate;
        fetchVisitsData();
    };

    const showNotification = (message: string) => {
        notification.info({
            message,
            placement: 'bottomRight',
        });
    };

    const handleDeniedClick = () => {
        showNotification('Displaying Denied Visitors.');
        setActiveTable('denied');
    };
    const handleBlockListClick = () => {
        showNotification('Displaying Block List.');
        setActiveTable('blockList');
    };
    const handleWatchListClick = () => {
        showNotification('Displaying Watch List.');
        setActiveTable('watchList');
    };

    const handleDrawerClose = () => {
        setIsVisitorsDrawerOpen(false);
        dispatch(setSelectedVisit(null));
    };

    return (
        <OpPage title="Red Flag Report" tooltip={REDFLAGREPORTS_TOOLTIP} subtitle="">
            <OpSpace
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <DateRangeLocationFilter2
                    onDateRangeLocationFilter={handleDateRangeLocationFilter}
                    initialStartDate={startDateRef.current}
                    initialEndDate={endDateRef.current}
                />
                <RedFlagReportSummary
                    deniedVisitors={deniedVisitors}
                    blockList={filteredBlocklist}
                    watchList={filteredWatchlist}
                    onDeniedClick={handleDeniedClick}
                    onBlockListClick={handleBlockListClick}
                    onWatchListClick={handleWatchListClick}
                />
                {activeTable === 'denied' && !isTableLoading && <DeniedVistiorsTable deniedVisitors={deniedVisitors} setDrawerVisible={setIsVisitorsDrawerOpen} />}
                {activeTable === 'blockList' && !isTableLoading && <RedFlagsTable redFlags={filteredBlocklist} setDrawerVisible={setRedFlagsDrawerOpen} isBlockList={true} />}
                {activeTable === 'watchList' && !isTableLoading && <RedFlagsTable redFlags={filteredWatchlist} setDrawerVisible={setRedFlagsDrawerOpen} isBlockList={false} />}
            </OpSpace>

            {(isVisitorsDrawerOpen) && (
                <VisitorsDrawer
                    open={isVisitorsDrawerOpen}
                    redFlags={redFlags.data}
                    isProfileDrawerOpen={isProfileDrawerOpen}
                    onClose={handleDrawerClose}
                    setIsProfileDrawerOpen={setIsProfileDrawerOpen}
                />
            )}
            <RedFlagsDrawer
                open={redFlagsDrawerOpen}
                onClose={() => {
                    setRedFlagsDrawerOpen(false)
                }}
            />
        </OpPage>
    );
}

export default RedFlagReport;
