import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

// Import custom components
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
// Import actions and types from Redux slices
import { AppDispatch, RootState } from 'store/store';

// Import constants
import { DATE_FORMAT } from 'constants/dates';
import { TABLE_HEIGHT } from 'constants/ui';
import { formatFullName } from 'utils/utils';
import { Visitor } from 'types/visitTypes';
import { setSelectedVisit } from 'store/slices/visitsSlice';

interface DeniedVistiorsTableData {
    visitId: number;
    visitorId: number;
    fullName: string;
    date: string;
}

interface ExtendedVisitor extends Visitor {
    visitId: number;
};

interface DeniedVistiorsTableProps {
    deniedVisitors: ExtendedVisitor[];
    setDrawerVisible: (visible: boolean) => void;
}

const DeniedVistiorsTable: React.FC<DeniedVistiorsTableProps> = ({ deniedVisitors, setDrawerVisible }) => {
    const dispatch: AppDispatch = useDispatch();

    const { visits } = useSelector((state: RootState) => state.visits);

    // Format visitors for table display
    const formatVisitsForTable = (deniedVisitors: ExtendedVisitor[]): DeniedVistiorsTableData[] => {
        return deniedVisitors
            .map((deniedVisitors) => {
                const date = dayjs(deniedVisitors.signIn).format(DATE_FORMAT);
                return {
                    visitId: deniedVisitors.visitId!,
                    visitorId: deniedVisitors.id!,
                    fullName: formatFullName(deniedVisitors.firstName, deniedVisitors.middleName, deniedVisitors.lastName),
                    date: date
                };
            });
    };

    // Table columns definition
    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'fullName',
            label: 'Full Name',
            filter: { type: 'input' },
            sorter: (a, b) => a.fullName.localeCompare(b.fullName), // Alphabetical sorting
        },
        {
            dataIndex: 'date',
            label: "Date & Time",
            sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
        }
    ];

    // Table properties
    const opTableProps: IOpTableProps = {
        dataSource: formatVisitsForTable(deniedVisitors),
        label: "Denied",
        columns: columns,
        rowActions: {
            onEditClick: (visitor: DeniedVistiorsTableData) => {
                const selectedVisit = visits.find(v => Number(v.id) === Number(visitor.visitId));
                dispatch(setSelectedVisit(selectedVisit!));
                setDrawerVisible(true);
            },
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        allowExport: true,
        allowShowHideColumns: true,
        gtm: 'dashboard-table-gtm',
        rowKey: (record) => `${record.visitId}-${record.visitorId}`,
    };

    return <OpTable {...opTableProps} />;
}

export default DeniedVistiorsTable;
