import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";

const LocationForm: React.FC = () => {
    return (
        <>
            <OpForm.Input
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Enter the name of the location.' }]}
            />
            <OpForm.Input
                label="Description"
                name="description"
            />
        </>
    );
};

export default LocationForm;
