import React from 'react';
import { RedFlag } from 'types/redFlagTypes';
import VisitorList from './formComponents/VisitorList';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import ProfileDrawer from '../profile/ProfileDrawer';
import { setSelectedVisitor } from 'store/slices/visitsSlice';

interface VisitorsTabProps {
    redFlags: RedFlag[];
    isProfileDrawerOpen: boolean;
    setIsProfileDrawerOpen: (open: boolean) => void;
}

const VisitorsTab: React.FC<VisitorsTabProps> = ({ redFlags, isProfileDrawerOpen, setIsProfileDrawerOpen }) => {
    const dispatch: AppDispatch = useDispatch();

    const handleDrawerClose = () => {
        setIsProfileDrawerOpen(false);
        dispatch(setSelectedVisitor(null));
    };

    return (
        <>
            <VisitorList
                redFlags={redFlags}
                openProfileDrawer={() => setIsProfileDrawerOpen(true)}
                closeProfileDrawer={() => setIsProfileDrawerOpen(false)}
            />
            {(isProfileDrawerOpen) && (
                <ProfileDrawer
                    open={isProfileDrawerOpen}
                    redFlags={redFlags}
                    onClose={handleDrawerClose}
                />
            )}
        </>
    )
};

export default VisitorsTab;
