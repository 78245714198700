import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Layout } from "antd";
import {
    PushpinOutlined,
    LineChartOutlined,
    UserOutlined,
    WarningOutlined,
    TeamOutlined,
    PieChartOutlined,
    BlockOutlined,
    DesktopOutlined,
    QuestionCircleOutlined,
    ControlOutlined,
    MobileOutlined
} from "@ant-design/icons";
import { THEME } from '../../../op-themes';
import { OpMenu } from '../../../components/customAntd/DLS/OpMenu/OpMenu';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { hasPermission } from 'utils/utils';
import FeedbackModal from './FeedbackModal';
import MobileAppModal from './MobileAppModal';
import { getRequest } from 'api/apiClient';
import dayjs from 'dayjs';
import axios from 'axios';
import { fetchSubscriptions } from 'store/slices/subscriptionsSlice';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';

const { Sider } = Layout;

type MenuItem = {
    key: React.Key;
    icon?: React.ReactNode;
    children?: MenuItem[];
    label: React.ReactNode;
};

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    };
}

const SideMenu: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const location = useLocation();
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const currentTheme = isDarkMode ? THEME.dark : THEME.light;
    const collapsed = useSelector((state: RootState) => state.ui.collapsed);
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
    const [mobileAppModalOpen, setMobileAppModalOpen] = useState(false);
    const [isGuestPassIntegrated, setIsGuestPassIntegrated] = useState<boolean>(false);
    const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
    const [featureUnavailableModalOpen, setFeatureUnavailableModalOpen] = useState(false);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const subscriptions = useSelector((state: RootState) => state.subscriptions.subscriptions);

    const hasAdminRead = hasPermission(tokenScopeList, orgId, 'o', 'admin:r');
    const hasVisitorsRead = hasPermission(tokenScopeList, orgId, 'o', 'visitors:r');
    const hasDashRead = hasPermission(tokenScopeList, orgId, 'o', 'dash:r');
    const hasLocationRead = hasPermission(tokenScopeList, orgId, 'o', 'location:r');
    const hasUsersRead = hasPermission(tokenScopeList, orgId, 'o', 'user:r');
    const hasRolesRead = hasPermission(tokenScopeList, orgId, 'o', 'role:r');
    const hasKiosksRead = hasPermission(tokenScopeList, orgId, 'o', 'kiosk:r');
    const hasRedFlagConfRead = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:r');
    const hasInvitationRead = hasPermission(tokenScopeList, orgId, 'o', 'invitation:r');
    const hasSigninconfRead = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:r');
    const hasSignoutconfRead = hasPermission(tokenScopeList, orgId, 'o', 'signoutconf:r');
    const hasFieldsRead = hasPermission(tokenScopeList, orgId, 'o', 'fields:r');
    const hasAgreementsRead = hasPermission(tokenScopeList, orgId, 'o', 'agreements:r');
    const hasAccountRead = hasPermission(tokenScopeList, orgId, 'o', 'account:r');
    const hasIntegrationRead = hasPermission(tokenScopeList, orgId, 'o', 'integration:r');
    const hasRptVisitorRead = hasPermission(tokenScopeList, orgId, 'o', 'rptvisitor:r');
    const hasRptTrendRead = hasPermission(tokenScopeList, orgId, 'o', 'rpttrend:r');
    const hasRptRedFlagRead = hasPermission(tokenScopeList, orgId, 'o', 'rptredflag:r');
    const hasRptHostRead = hasPermission(tokenScopeList, orgId, 'o', 'rpthost:r');
    const hasRptActivityRead = hasPermission(tokenScopeList, orgId, 'o', 'rptactivity:r');
    const hasRptGuestPassRead = hasPermission(tokenScopeList, orgId, 'o', 'rptguestpass:r');

    const showLocations = hasLocationRead;
    const showUsers = hasUsersRead;
    const showRoles = hasRolesRead;
    const showKiosks = hasKiosksRead;
    const showRedFlag = hasRedFlagConfRead;
    const showInvitations = hasInvitationRead;
    const showWorkflow = hasSigninconfRead || hasSignoutconfRead;
    const showFields = hasFieldsRead;
    const showAgreements = hasAgreementsRead;
    const showAccount = hasAccountRead;
    const showIntegration = hasIntegrationRead;
    const showRptVisitor = hasRptVisitorRead;
    const showRptTrend = hasRptTrendRead;
    const showRptRedFlag = hasRptRedFlagRead;
    const showRptHost = hasRptHostRead;
    const showRptActivity = hasRptActivityRead;
    const showRptGuestPass = hasRptGuestPassRead && isGuestPassIntegrated && isTokenValid;

    useEffect(() => {
        const pathSnippets = location.pathname.split('/').filter(i => i);
        const openKey = `/${pathSnippets[0]}`;
        setOpenKeys([openKey]);
    }, [location.pathname]);

    useEffect(() => {
        const checkIntegration = async () => {
            try {
                const integrationData = await getRequest(`/orgs/${orgId}/integrationMsiAlta`);
                const initialData = integrationData.data[0];

                const { msiAltaOrgId, msiAltaToken, msiAltaTokenExpUtc, guestPassEnabled } = initialData;

                const hasOrgId = !!msiAltaOrgId;
                const hasToken = !!msiAltaToken;
                const tokenIsValid = !msiAltaTokenExpUtc || dayjs(msiAltaTokenExpUtc).isAfter(dayjs());
                const guestPassEnabledCondition = guestPassEnabled === 1;
                // Set isIntegrated to true if all conditions are met
                if (hasOrgId && hasToken && tokenIsValid && guestPassEnabledCondition) {
                    setIsGuestPassIntegrated(true);
                    const response = await axios.post(`https://api.openpath.com/auth/accessTokens/${msiAltaToken}/validate`);
                    setIsTokenValid(response.data.data.isValid);
                } else {
                    setIsGuestPassIntegrated(false);
                }
            } catch (error) {
                console.log("Failed to check integration.");
            }
        }
        checkIntegration();

        if (subscriptions === undefined || subscriptions.data.length === 0) {
            dispatch(fetchSubscriptions({ orgId }));
        }
    }, [dispatch, orgId, subscriptions]);

    const handleOpenChange = (keys: string[]) => {
        setOpenKeys(keys);
    };

    const items: MenuItem[] = [
        ...(hasDashRead ? [
            getItem("Dashboard", "/dashboard", <PieChartOutlined style={{ fontSize: '125%' }} />),
        ] : []),
        ...(hasVisitorsRead ? [
            getItem("Visitors", "/visitors", <TeamOutlined style={{ fontSize: '125%' }} />),
        ] : []),
        ...(showLocations ? [getItem("Locations", "/locations", <PushpinOutlined style={{ fontSize: '125%' }} />)] : []),
        ...(showUsers || showRoles ? [
            getItem("Users", "/users", <UserOutlined style={{ fontSize: '125%' }} />, [
                ...(showUsers ? [getItem("Users", "/users/users")] : []),
                ...(showRoles ? [getItem("Roles", "/users/roles")] : []),
            ])
        ] : []),
        ...(showKiosks ? [getItem("Kiosks", "/kiosks", <DesktopOutlined style={{ fontSize: '125%' }} />)] : []),
        ...(showRedFlag ? [getItem("Red Flag", "/redFlag", <WarningOutlined style={{ fontSize: '125%' }} />)] : []),
        ...(showInvitations || showWorkflow || showFields || showAgreements ? [
            getItem("Configuration", "/configuration", <BlockOutlined style={{ fontSize: '125%' }} />, [
                ...(showInvitations ? [getItem("Invitations", "/configuration/invitations")] : []),
                ...(showWorkflow ? [getItem("Workflow", "/configuration/workflow")] : []),
                ...(showFields ? [getItem("Form Fields", "/configuration/formFields")] : []),
                ...(showAgreements ? [getItem("Agreements", "/configuration/agreements")] : []),
            ])
        ] : []),
        ...(hasAdminRead && (showAccount || showIntegration) ? [
            getItem("Administration", "/administration", <ControlOutlined style={{ fontSize: '125%' }} />, [
                ...(showAccount ? [getItem("Account", "/administration/account")] : []),
                ...(showIntegration ? [getItem("App Integration", "/administration/integration")] : []),
            ])
        ] : []),
        ...(showRptVisitor || showRptTrend || showRptRedFlag || showRptHost || showRptActivity || showRptGuestPass ? [
            getItem("Reports", "/reports", <LineChartOutlined style={{ fontSize: '125%' }} />, [
                ...(showRptVisitor ? [getItem("Visitor Report", "/reports/visitorReport")] : []),
                ...(showRptTrend ? [getItem("Repeat Visitors", "/reports/repeatVisitors")] : []),
                ...(showRptRedFlag ? [getItem("Red Flag Report", "/reports/redFlagReport")] : []),
                ...(showRptGuestPass ? [getItem("Guest Pass Report", "/reports/guestPassReport")] : []),
                ...(showRptHost ? [getItem("Host Report", "/reports/hostReport")] : []),
                ...(showRptActivity ? [getItem("Activity Log", "/reports/activityLog")] : []),
            ])
        ] : []),
        // getItem("Release Notes", "/releaseNotes", <FileTextOutlined style={{ fontSize: '125%' }} />),
        getItem("Support", "/support", <QuestionCircleOutlined style={{ fontSize: '125%' }} />, [
            getItem(
                <span onClick={(e) => e.stopPropagation()}>
                    <a href="https://commaowl.com/?83FJ6X" target="_blank" rel="noopener noreferrer">User Manual</a>
                </span>,
                "usermanual"
            ),
            getItem(
                <span onClick={() => setFeedbackModalOpen(true)}>
                    Send Feedback
                </span>,
                "sendFeedback"
            ),
            getItem(
                <span onClick={(e) => e.stopPropagation()}>
                    <a href="https://commaowl.com/?MQHYZQ" target="_blank" rel="noopener noreferrer">Release Notes</a>
                </span>,
                "releaseNotes"
            ),
        ]),
        getItem(
            <span onClick={() => setMobileAppModalOpen(true)}>
                Mobile App
            </span>,
            "mobileApp",
            <MobileOutlined style={{ fontSize: '125%' }} />
        ),].flat();

    return (
        <Sider
            collapsible
            collapsed={collapsed}
            trigger={null}
            width={280}
            style={{
                background: currentTheme.token.colorBgLayout,
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <div style={{ height: '100%', overflowY: 'auto', paddingBottom: '40px' }}>
                {!collapsed && (
                    <style>
                        {`
                            .ant-menu-sub .ant-menu-item {
                                padding-left: 59px !important;
                            }
                        `}
                    </style>
                )}

                <OpMenu
                    mode="inline"
                    defaultSelectedKeys={['dashboard']}
                    onClick={e => {
                        if (e.key === 'sendFeedback') {
                            setFeedbackModalOpen(true);
                        } else if (e.key === 'mobileApp') {
                            setMobileAppModalOpen(true);
                        } else if (
                            (
                                (e.key === '/redFlag' || e.key === '/reports/redFlagReport') &&
                                (
                                    subscriptions?.data[0]?.package?.code === "package-essential"
                                )
                            ) ||
                            (
                                (e.key === '/kiosks' || e.key === '/reports/activityLog') &&
                                (
                                    subscriptions?.data[0]?.package?.code === "package-essential"
                                )
                            )
                        ) {
                            setFeatureUnavailableModalOpen(true);
                        } else {
                            navigate(e.key);
                        }
                    }}
                    style={{ borderRight: 0, background: currentTheme.token.colorBgLayout }}
                    openKeys={openKeys}
                    onOpenChange={handleOpenChange}
                    selectedKeys={[location.pathname]}
                    items={items}
                />

                <FeedbackModal
                    open={feedbackModalOpen}
                    onClose={() => setFeedbackModalOpen(false)}
                />
                <MobileAppModal
                    open={mobileAppModalOpen}
                    onClose={() => setMobileAppModalOpen(false)}
                />

                <OpModal
                    open={featureUnavailableModalOpen}
                    onCancel={() => setFeatureUnavailableModalOpen(false)}
                    onOk={() => setFeatureUnavailableModalOpen(false)}
                    title="Feature Not Included"
                >
                    <p>Your account does not currently include this feature. Please contact your administrator.</p>
                </OpModal>

            </div>

            <div style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                padding: '10px',
                textAlign: 'center',
                fontSize: '12px',
                color: 'var(--colorTextTertiary)',
                background: currentTheme.token.colorBgLayout
            }}>
                <span>Invisit &copy; 2024</span>
            </div>
        </Sider>
    );
};

export default SideMenu;
