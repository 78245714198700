import React from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';

import Dashboard from "../../pages/dashboard/Dashboard";
import Visitors from "../../pages/visitors/Visitors";
import Locations from "../../pages/locations/Locations";
import Users from "../../pages/users/users/Users";
import Roles from "../../pages/users/roles/Roles";
import Kiosks from "../../pages/kiosks/Kiosks";
import RedFlag from "../../pages/redFlag/RedFlag";
import Invitations from "../../pages/configuration/Invitations/Invitations";
import Workflow from "../../pages/configuration/Workflow/Workflow";
import Agreements from "../../pages/agreements/Agreements";
import VisitorReport from "../../pages/reports/visitorReport/VisitorReport";
import ActivityLog from '../../pages/reports/activityLog/ActivityLog';
import HostReport from '../../pages/reports/hostReport/HostReport';
import RedFlagReport from '../../pages/reports/redFlagReport/RedFlagReport';
import RepeatVisitors from '../../pages/reports/repeatVisitors/RepeatVisitors';
import Account from 'components/pages/administration/account/Account';
import Integration from 'components/pages/administration/integration/Integration';
import VisitorSearch from 'components/pages/search/VisitorSearch';
import ReleaseNotes from 'components/pages/releaseNotes/ReleaseNotes';
import NotFound from '../../pages/notFound/NotFound';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { hasPermission } from 'utils/utils';
import FormFields from 'components/pages/configuration/formFields/FormFields';
import GuestPassReprot from 'components/pages/reports/guestPassReport/GuestPassReport';
import Support from 'components/pages/support/Support';

const Layout: React.FC = () => {
    return (
        <div style={{ height: '100%' }}>
            <Outlet />
        </div>
    );
};

const AppRoutes: React.FC = () => {
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);

    const hasLocationRead = hasPermission(tokenScopeList, orgId, 'o', 'location:r');
    const hasUsersRead = hasPermission(tokenScopeList, orgId, 'o', 'user:r');
    const hasRolesRead = hasPermission(tokenScopeList, orgId, 'o', 'role:r');
    const hasKiosksRead = hasPermission(tokenScopeList, orgId, 'o', 'kiosk:r');
    const hasRedFlagConfRead = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:r');
    const hasInvitationRead = hasPermission(tokenScopeList, orgId, 'o', 'invitation:r');
    const hasSigninconfRead = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:r');
    const hasSignoutconfRead = hasPermission(tokenScopeList, orgId, 'o', 'signoutconf:r');
    const hasFieldsRead = hasPermission(tokenScopeList, orgId, 'o', 'fields:r');
    const hasAgreementsRead = hasPermission(tokenScopeList, orgId, 'o', 'agreements:r');
    const hasAccountRead = hasPermission(tokenScopeList, orgId, 'o', 'account:r');
    const hasRptVisitorRead = hasPermission(tokenScopeList, orgId, 'o', 'rptvisitor:r');
    const hasRptTrendRead = hasPermission(tokenScopeList, orgId, 'o', 'rpttrend:r');
    const hasRptRedFlagRead = hasPermission(tokenScopeList, orgId, 'o', 'rptredflag:r');
    const hasRptHostRead = hasPermission(tokenScopeList, orgId, 'o', 'rpthost:r');
    const hasRptActivityRead = hasPermission(tokenScopeList, orgId, 'o', 'rptactivity:r');
    const hasRptGuestPassRead = hasPermission(tokenScopeList, orgId, 'o', 'rptguestpass:r');

    const showLocations = hasLocationRead;
    const showUsers = hasUsersRead;
    const showRoles = hasRolesRead;
    const showKiosks = hasKiosksRead;
    const showRedFlag = hasRedFlagConfRead;
    const showInvitations = hasInvitationRead;
    const showWorkflow = hasSigninconfRead || hasSignoutconfRead;
    const showFields = hasFieldsRead;
    const showAgreements = hasAgreementsRead;
    const showAccount = hasAccountRead;
    const showRptVisitor = hasRptVisitorRead;
    const showRptTrend = hasRptTrendRead;
    const showRptRedFlag = hasRptRedFlagRead;
    const showRptHost = hasRptHostRead;
    const showRptActivity = hasRptActivityRead;
    const showRptGuestPass = hasRptGuestPassRead;

    return (
        <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/visitors" element={<Visitors />} />
            <Route path="/visitorSearchResults" element={<VisitorSearch />} />
            {showLocations && <Route path="/locations" element={<Locations />} />}
            {(showUsers || showRoles) && (
                <Route path="/users" element={<Layout />}>
                    {showUsers && <Route path="users" element={<Users />} />}
                    {showRoles && <Route path="roles" element={<Roles />} />}
                </Route>
            )}
            {showKiosks && <Route path="/kiosks" element={<Kiosks />} />}
            {showRedFlag && <Route path="/redFlag" element={<RedFlag />} />}
            {(showInvitations || showWorkflow || showFields || showAgreements) && (
                <Route path="/configuration" element={<Layout />}>
                    {showInvitations && <Route path="invitations" element={<Invitations />} />}
                    {showWorkflow && <Route path="workflow" element={<Workflow />} />}
                    {showFields && <Route path="formFields" element={<FormFields />} />}
                    {showAgreements && <Route path="agreements" element={<Agreements />} />}
                </Route>
            )}
            <Route path="/administration" element={<Layout />}>
                {showAccount && <Route path="account" element={<Account />} />}
                <Route path="integration" element={<Integration />} />
            </Route>
            {(showRptVisitor || showRptTrend || showRptRedFlag || showRptHost || showRptActivity || showRptGuestPass) && (
                <Route path="/reports" element={<Layout />}>
                    {showRptVisitor && <Route path="visitorReport" element={<VisitorReport />} />}
                    {showRptTrend && <Route path="repeatVisitors" element={<RepeatVisitors />} />}
                    {showRptRedFlag && <Route path="redFlagReport" element={<RedFlagReport />} />}
                    {showRptHost && <Route path="hostReport" element={<HostReport />} />}
                    {showRptActivity && <Route path="activityLog" element={<ActivityLog />} />}
                    {showRptGuestPass && <Route path="guestPassReport" element={<GuestPassReprot />} />}
                </Route>
            )}
            <Route path="/releaseNotes" element={<ReleaseNotes />} />
            <Route path="/support" element={<Support />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AppRoutes;
