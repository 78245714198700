import React from 'react';
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { VisitorWorkflow } from 'types/visitorWorkflowTypes';

interface WorkflowFormProps {
    visitorWorkflows: VisitorWorkflow[];
}

const WorkflowForm: React.FC<WorkflowFormProps> = ({ visitorWorkflows }) => {

    const getOptions = (workflowTypeId: number) => {
        return visitorWorkflows
            .filter(workflow => workflow.workflowTypeId === workflowTypeId)
            .map(workflow => ({ label: workflow.name, value: workflow.id }));
    };

    return (
        <>
            <OpForm.Select
                label="Attendant Sign In" name="SignInWorkflowId"
                options={getOptions(1)} />

            <OpForm.Select
                label="Attendant Sign Out" name="SignOutWorkflowId"
                options={getOptions(2)} />

            <OpForm.Select
                label="Kiosk Sign In" name="KioskInWorkflowId"
                options={getOptions(3)} />

            <OpForm.Select
                label="Kiosk Sign Out" name="KioskOutWorkflowId"
                options={getOptions(4)} />
        </>
    );
};

export default WorkflowForm;
