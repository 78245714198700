import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { updateVisitorWorkflow, updateVisitorWorkflowConfig } from 'store/slices/visitorWorkflowsSlice';
import { List } from 'antd';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { OpRow } from 'components/customAntd/DLS/OpRow/OpRow';
import { OpCol } from 'components/customAntd/DLS/OpCol/OpCol';
import locationIcon from '../../location.svg';
import { hasPermission } from 'utils/utils';

interface WorkflowContentProps {
    form: any;
}

const idScanData = [
    { name: 'scan', label: 'Scan ID To Identify', valuePropName: 'checked' },
    { name: 'scanScanner', label: 'Use Scanner', valuePropName: 'checked', dependent: true },
];

const notificationData = [
    { name: 'notifyHost', label: 'Notify Host', valuePropName: 'checked' },
];

const WorkflowContent: React.FC<WorkflowContentProps> = ({ form }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { selectedVisitorWorkflow, selectedVisitorWorkflowLocations: visitorWorkflowLocations, selectedSignInWorkflowConfig: visitorWorkflowConfig } = useSelector((state: RootState) => state.visitorWorkflows);
    const [isScanScannerDisabled, setScanScannerDisabled] = useState(true);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode); // Accessing isDarkMode from Redux

    const hasSigninconfRead = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:r');
    const hasSigninconfWrite = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:w');

    useEffect(() => {
        const initialScanIdState = visitorWorkflowConfig?.scan;
        setScanScannerDisabled(!initialScanIdState);
        if (!initialScanIdState) {
            form.setFieldsValue({ scanScanner: false });
        }
    }, [visitorWorkflowConfig, form]);

    const initialValues = useMemo(() => {
        if (selectedVisitorWorkflow) {
            return {
                name: selectedVisitorWorkflow.name,
                workflowType: "Attendant Sign In Workflow",
                scan: visitorWorkflowConfig?.scan,
                scanScanner: visitorWorkflowConfig?.scanScanner,
                notifyHost: visitorWorkflowConfig?.notifyHost,
            };
        }
        return {};
    }, [selectedVisitorWorkflow, visitorWorkflowConfig?.notifyHost, visitorWorkflowConfig?.scan, visitorWorkflowConfig?.scanScanner]);

    const handleSubmit = useCallback(({ values, touchedValues, initialValues }: IOnSubmitArgs<any>) => {
        const visitorWorkflowConfigParams = {
            scan: values.scan ? 1 : 0,
            scanScanner: values.scanScanner ? 1 : 0,
            notifyHost: values.notifyHost ? 1 : 0
        };
        if (selectedVisitorWorkflow) {
            dispatch(updateVisitorWorkflow({ orgId, visitorWorkflowId: selectedVisitorWorkflow.id, visitorWorkflow: { name: touchedValues.name } }));
            dispatch(updateVisitorWorkflowConfig({ orgId, visitorWorkflowId: selectedVisitorWorkflow.id, visitorWorkflowConfigParams }));
        }
    }, [dispatch, orgId, selectedVisitorWorkflow]);


    const handleScanChange = (checked: boolean) => {
        setScanScannerDisabled(!checked);
        if (!checked) {
            form.setFieldsValue({ scanScanner: false });
        }
    };

    const locationIconStyle = {
        marginRight: '8px',
        width: '16px',
        height: '16px',
        filter: isDarkMode ? 'invert(1)' : 'none',
    };

    return (
        <OpForm
            form={form}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            hasError={false}
            defaultButtons={false}
            isReadOnly={!hasSigninconfWrite && hasSigninconfRead}
        >
            <OpSpace
                direction="vertical"
                size="middle"
                style={{
                    display: 'flex',
                }}
            >
                <OpRow gutter={16}>
                    <OpCol span={12}>
                        <OpForm.Input label="Name" name="name" rules={[{ required: true, message: 'Please enter the name' }]} />
                    </OpCol>
                    <OpCol span={12}>
                        <OpForm.Input disabled label="Workflow Type" name="workflowType" />
                    </OpCol>
                </OpRow>

                <List
                    header={<div>ID SCAN</div>}
                    dataSource={idScanData}
                    bordered
                    renderItem={item => (
                        <List.Item>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <OpForm.Switch name={item.name} style={{ marginBottom: 0 }}
                                    disabled={item.dependent && (isScanScannerDisabled || (!hasSigninconfWrite && hasSigninconfRead))}
                                    onChange={item.name === 'scan' ? handleScanChange : undefined} />
                                <span style={{ marginLeft: 8 }}>{item.label}</span>
                            </div>
                        </List.Item>
                    )} />

                <List
                    header={<div>NOTIFICATION</div>}
                    dataSource={notificationData}
                    bordered
                    renderItem={item => (
                        <List.Item>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <OpForm.Switch
                                    name={item.name} style={{ marginBottom: 0 }}
                                    disabled={!hasSigninconfWrite && hasSigninconfRead}
                                />
                                <span style={{ marginLeft: 8 }}>{item.label}</span>
                            </div>
                        </List.Item>
                    )} />

                <List
                    header={<div>LOCATIONS</div>}
                    bordered
                    dataSource={visitorWorkflowLocations.data.filter(location => location.status === 1)}
                    renderItem={location => (
                        <List.Item>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', color: isDarkMode ? '#FFFFFF' : '#000000' }}>
                                <img src={locationIcon} alt="location icon" style={locationIconStyle} />
                                <span>{location.name}</span>
                            </div>
                        </List.Item>
                    )}
                />
            </OpSpace>
        </OpForm>
    );
};

export default WorkflowContent;
