import React, { useCallback } from 'react';
import { OpForm, IOnSubmitArgs } from 'components/customAntd/DLS/OpForm/OpForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { createRole, updateRole } from 'store/slices/rolesSlice';
import { FormInstance, notification } from 'antd';
import { hasPermission } from 'utils/utils';
import { logUserActivity } from 'store/slices/userActivitySlice';

interface RoleContentProps {
    form: FormInstance;
}

const RoleContent: React.FC<RoleContentProps> = ({ form }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const selectedRole = useSelector((state: RootState) => state.roles.selectedRole);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasRoleRead = hasPermission(tokenScopeList, orgId, 'o', 'role:r');
    const hasRoleWrite = hasPermission(tokenScopeList, orgId, 'o', 'role:w');

    const initialValues = selectedRole ? {
        name: selectedRole?.name,
        description: selectedRole?.description
    } : {};

    const handleSubmit = useCallback(({ values, touchedValues, initialValues }: IOnSubmitArgs<any>) => {
        if (touchedValues) {
            if (selectedRole) {
                dispatch(updateRole({ orgId, roleId: selectedRole.id, role: touchedValues }));
                notification.success({
                    message: 'Success',
                    description: 'Role updated successfully.',
                    placement: 'bottomRight',
                });
            } else {
                dispatch(createRole({ orgId, role: touchedValues }));
                dispatch(logUserActivity({ orgId, userActivity: { userId: globalUserId!, activityId: "90", details: touchedValues.name || '' } }))
                notification.success({
                    message: 'Success',
                    description: 'Role created successfully.',
                    placement: 'bottomRight',
                });
            }
        }
    }, [dispatch, selectedRole, orgId, globalUserId]);


    return (
        <OpForm
            form={form}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            hasError={false}
            defaultButtons={false}
            isReadOnly={!hasRoleWrite && hasRoleRead}
        >
            <OpForm.Input
                label='Name'
                name='name'
                rules={[{ required: true, message: 'Please enter the role name' }]}
            />

            <OpForm.Input label='Description' name='description' />
        </OpForm>
    );
};

export default RoleContent;
