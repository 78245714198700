
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiResponse, ApiErrorResponse } from 'types/apiTypes';
import { Subscription } from 'types/subscriptionTypes';

interface SubscriptionsState {
    subscriptions: ApiResponse<Subscription>;
    fetchSubscriptionsLoading: boolean;
    error: string | null;
}

const initialState: SubscriptionsState = {
    subscriptions: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    fetchSubscriptionsLoading: false,
    error: null,
};

// Retrieve the base URL from environment variables
const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
    console.error('REACT_APP_BACKEND_URL is not set');
}

export const fetchSubscriptions = createAsyncThunk<ApiResponse<Subscription>, { orgId: number }, { rejectValue: string }>(
    'subscriptions/fetchSubscriptions',
    async ({ orgId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<Subscription>>(`${apiUrl}/orgs/${orgId}/subscriptions`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch subscriptions');
        }
    }
);

const subscriptionSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {
        clearSubscriptionsState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubscriptions.pending, (state) => {
                state.fetchSubscriptionsLoading = true;
                state.error = null;
            })
            .addCase(fetchSubscriptions.fulfilled, (state, action) => {
                state.fetchSubscriptionsLoading = false;
                state.subscriptions = action.payload;
            })
            .addCase(fetchSubscriptions.rejected, (state, action) => {
                state.fetchSubscriptionsLoading = false;
                state.error = action.payload || 'Failed to fetch subscriptions';
            })
    },
});

export const { clearSubscriptionsState } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
