import React, { useState } from 'react';
import { Form, Input, Button, Alert, Typography, notification } from 'antd';
import axios from 'axios';

const { Text } = Typography;

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const ForgotPasswordForm: React.FC = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values: { email: string }) => {
        setLoading(true);
        try {
            await axios.post(`${apiUrl}/auth/resetPassword`, {
                email: values.email,
            });

            // Show the success notification regardless of the response
            notification.success({
                message: 'Success',
                description: `An email with a password reset link has been sent to ${values.email}`,
                placement: 'bottomRight',
            });
        } catch (error) {
            notification.success({
                message: 'Success',
                description: `An email with a password reset link has been sent to ${values.email}`,
                placement: 'bottomRight',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            style={{ maxWidth: '400px', margin: '0 auto' }}
        >
            <Text style={{ marginBottom: '16px', display: 'block' }}>
                Enter the email address you used to register with InVisit. An email will be sent to reset your password.
            </Text>

            <Alert
                message="If you provide an email that has not been registered, you will NOT receive an email."
                type="info"
                showIcon
                style={{ marginBottom: '24px' }}
            />

            <Form.Item
                name="email"
                label="Email"
                rules={[
                    { required: true, message: 'Email is required' },
                    { type: 'email', message: 'Not a valid email' },
                ]}
                style={{ marginBottom: '24px' }}
            >
                <Input autoComplete="off" />
            </Form.Item>

            <Form.Item style={{ marginBottom: '24px' }}>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ForgotPasswordForm;
