import { FormInstance, List } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { OpModal } from "components/customAntd/DLS/OpModal/OpModal";
import { RootState } from "store/store";
import { InvitationDocument } from "types/visitorInvitationTypes";

interface OptionsFormProps {
    form: FormInstance;
    invitationDocuments: InvitationDocument[];
    isPreRegistrationAllowed: number;
    setIsPreRegistrationAllowed: (checked: number) => void;
}

const OptionsForm: React.FC<OptionsFormProps> = ({ form, invitationDocuments, isPreRegistrationAllowed, setIsPreRegistrationAllowed }) => {
    const [isFeatureUnavailableModalOpen, setIsFeatureUnavailableModalOpen] = useState(false);
    const subscriptions = useSelector((state: RootState) => state.subscriptions.subscriptions);

    const handleAllowInvitationsChange = (checked: boolean) => {
        if (!checked) {
            form.setFieldsValue({ sendEmail: 0, allowPreRegistration: 0 });
            setIsPreRegistrationAllowed(0);
        }
    };

    const handleSendEmailChange = (checked: boolean) => {
        if (checked) {
            form.setFieldsValue({ allowInvitations: 1 });
        } else {
            form.setFieldsValue({ allowPreRegistration: 0 });
            setIsPreRegistrationAllowed(0);
        }
    };

    const handleAllowPreRegistrationChange = (checked: boolean) => {
        // Check subscription package for feature availability
        const isFeatureAvailable = subscriptions?.data[0]?.package?.code !== "package-essential";

        if (!isFeatureAvailable) {
            setIsFeatureUnavailableModalOpen(true);
            form.setFieldsValue({ allowPreRegistration: isPreRegistrationAllowed });
            return;
        }

        if (checked) {
            form.setFieldsValue({ allowInvitations: 1, sendEmail: 1 });
        }
        setIsPreRegistrationAllowed(checked ? 1 : 0);
    };

    return (
        <>
            <List
                header={<div>INVITATION OPTIONS</div>}
                dataSource={[
                    { label: 'Allow Invitations', name: 'allowInvitations' },
                    { label: 'Email Invitations', name: 'sendEmail' },
                    { label: 'Allow Pre-Registration', name: 'allowPreRegistration' },
                    { label: 'Include Agreements', name: 'invitationDocuments' }
                ]}
                bordered
                style={{ marginBottom: '16px' }}
                renderItem={item => (
                    <List.Item style={{ width: '100%' }}>
                        {item.name === 'invitationDocuments' ? (
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <OpForm.Select
                                    name={item.name}
                                    label={item.label}
                                    mode="multiple"
                                    placeholder="Select Invitation Documents"
                                    options={invitationDocuments.map(document => ({
                                        label: document.name,
                                        value: document.id
                                    }))}
                                    disabled={!isPreRegistrationAllowed}
                                />
                            </div>
                        ) : (
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <OpForm.Switch
                                    name={item.name}
                                    onChange={
                                        item.name === 'allowInvitations'
                                            ? handleAllowInvitationsChange
                                            : item.name === 'sendEmail'
                                                ? handleSendEmailChange
                                                : item.name === 'allowPreRegistration'
                                                    ? handleAllowPreRegistrationChange
                                                    : undefined
                                    }
                                />
                                <span style={{ marginLeft: 8 }}>{item.label}</span>
                            </div>
                        )}
                    </List.Item>
                )}
            />

            <OpModal
                open={isFeatureUnavailableModalOpen}
                onCancel={() => setIsFeatureUnavailableModalOpen(false)}
                onOk={() => setIsFeatureUnavailableModalOpen(false)}
                title="Feature Not Included"
            >
                <p>Your account does not currently include this feature. Please contact your administrator.</p>
            </OpModal>
        </>
    );
};

export default OptionsForm;
