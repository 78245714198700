import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import themeReducer from './slices/themeSlice';
import uiReducer from './slices/uiSlice';
import authReducer from './slices/authSlice';
import globalOrgReducer from './slices/globalOrgSlice';
import visitsReducer from './slices/visitsSlice';
import visitorsReducer from './slices/visitorsSlice';
import usersReducer from './slices/usersSlice';
import rolesReducer from './slices/rolesSlice';
import locationsReducer from './slices/locationsSlice';
import redFlagReducer from './slices/redFlagSlice';
import kiosksReducer from './slices/kiosksSlice';
import visitorWorkflowsReducer from './slices/visitorWorkflowsSlice';
import scopeResourcesReducer from './slices/scopeResourcesSlice';
import visitorInvitationReducer from './slices/visitorInvitationSlice';
import visitorTypesReducer from './slices/visitorTypesSlice';
import agreementsReducer from './slices/agreementsSlice';
import denyReducer from './slices/denySlice';
import userActivityReducer from './slices/userActivitySlice';
import visitorSearchReducer from './slices/visitorSearchSlice';
import subscriptionsReducer from './slices/subscriptionsSlice';
import guestPassReducer from './slices/guestPassSlice';

// Configure the persist reducer for the globalUser part of the users slice
const usersPersistConfig = {
  key: 'users',
  storage,
  whitelist: ['globalUser'], // Persist only the globalUser
};

// Configure the persist reducer for the globalLocation and globalLocationWorkflow parts of the locations slice
const locationsPersistConfig = {
  key: 'locations',
  storage,
  whitelist: ['globalLocation', 'globalLocationWorkflow'], // Persist only the globalLocation and globalLocationWorkflow
};

// Combine the persisted reducers with the rest of the reducers
const rootReducer = combineReducers({
  theme: themeReducer,
  ui: uiReducer,
  auth: authReducer,
  globalOrg: globalOrgReducer,
  visits: visitsReducer,
  visitors: visitorsReducer,
  users: persistReducer(usersPersistConfig, usersReducer), // Use persisted reducer for users
  roles: rolesReducer,
  locations: persistReducer(locationsPersistConfig, locationsReducer), // Use persisted reducer for locations
  redFlags: redFlagReducer,
  kiosks: kiosksReducer,
  visitorWorkflows: visitorWorkflowsReducer,
  scopeResources: scopeResourcesReducer,
  visitorInvitation: visitorInvitationReducer,
  visitorTypes: visitorTypesReducer,
  agreements: agreementsReducer,
  deny: denyReducer,
  userActivity: userActivityReducer,
  visitorSearch: visitorSearchReducer,
  subscriptions: subscriptionsReducer,
  guestPass: guestPassReducer
});

export default rootReducer;
