import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { AppDispatch, RootState } from 'store/store';
import { clearRoles, clearSelectedRole, deleteRole, describeRole, fetchRoles, fetchUsersInRole } from 'store/slices/rolesSlice';
import { notification } from 'antd';
import { TABLE_HEIGHT } from 'constants/ui';
import { formatFullName, hasPermission } from 'utils/utils';
import { Role } from 'types/roleTypes';
import { logUserActivity } from 'store/slices/userActivitySlice';
import { CONFIRM_DELETE_CONTENT, CONFIRM_DELETE_TITLE } from 'constants/messages';

interface RolesTableProps {
    setDrawerVisible: (visible: boolean) => void;
}

const RolesTable: React.FC<RolesTableProps> = ({ setDrawerVisible }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const { roles, fetchRolesLoading, fetchUsersInRoleLoading } = useSelector((state: RootState) => state.roles);

    const [activeRoles, setActiveRoles] = useState<Role[]>([]);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasRoleWrite = hasPermission(tokenScopeList, orgId, 'o', 'role:w');

    useEffect(() => {
        if (orgId) {
            dispatch(fetchRoles({ orgId }));
        }
        return () => {
            dispatch(clearRoles());
        };
    }, [dispatch, orgId]);

    useEffect(() => {
        if (roles && roles.data) {
            const filteredRoles = roles.data.filter(role => role.status === 1);
            setActiveRoles(filteredRoles);
        }
    }, [roles]);

    const handleDeleteRole = async (record: Role) => {
        if (record.admin) {
            notification.error({
                message: 'Error',
                description: `The ${record.name} role cannot be deleted.`,
                placement: 'bottomRight',
            });
            return;
        }

        try {
            // Fetch users in the role
            const resultAction = await dispatch(fetchUsersInRole({ orgId, roleId: record.id }));

            if (fetchUsersInRole.fulfilled.match(resultAction)) {
                const users = resultAction.payload.data;
                if (users.length > 0) {
                    const usersWithoutOtherRoles = users.filter(user => Number(user.roleCount) === 1);
                    if (usersWithoutOtherRoles.length > 0) {
                        const userNames = usersWithoutOtherRoles.map(user =>
                            formatFullName(user.identity.firstName, user.identity.middleName, user.identity.lastName)
                        ).join(', ');
                        notification.error({
                            message: 'Error',
                            description: `This is the only role for user(s): ${userNames}. Please ensure all users are assigned to another role before deleting this role.`,
                            placement: 'bottomRight',
                        });
                        return;
                    }
                }

                // Proceed with deletion if no blocking users found
                await dispatch(deleteRole({ orgId, roleId: record.id }));
                await dispatch(logUserActivity({ orgId, userActivity: { userId: globalUserId!, activityId: "100", details: record.name || '' } }))
                notification.success({
                    message: 'Success',
                    description: 'Role deleted successfully.',
                    placement: 'bottomRight',
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Failed to fetch users in role.',
                    placement: 'bottomRight',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to delete role.',
                placement: 'bottomRight',
            });
        }
    };

    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'name',
            label: 'ROLE',
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.name || '').localeCompare(b.name || ''), // Alphabetical sorting with null check
        },
        {
            dataIndex: 'description',
            label: 'DESCRIPTION',
            filter: {
                type: 'input',
            },
            sorter: (a, b) => (a.description || '').localeCompare(b.description || ''), // Alphabetical sorting with null check
        },
        {
            dataIndex: 'userCount',
            label: 'USER COUNT',
            filter: {
                type: 'input',
            },
            sorter: (a, b) => a.userCount - b.userCount
        },
    ];

    const opTableProps: IOpTableProps = {
        dataSource: activeRoles,
        columns: columns,
        rowActions: {
            onEditClick: (role: Role) => {
                dispatch(describeRole({ orgId, roleId: role.id }));
                setDrawerVisible(true);
            },
            onDeleteClick: hasRoleWrite ? handleDeleteRole : undefined,
            deleteModalTitle: () => CONFIRM_DELETE_TITLE("Role"),
            deleteModalContent: ({ record }) => CONFIRM_DELETE_CONTENT(record.name),
        },
        testId: 'dashboard-table',
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        writeAccess: hasRoleWrite,
        allowAddition: hasRoleWrite ? {
            itemName: 'Role',
            onClick: () => {
                dispatch(clearSelectedRole());
                setDrawerVisible(true);
            },
        } : false,
        loading: fetchRolesLoading || fetchUsersInRoleLoading,
        allowExport: true,
        allowShowHideColumns: true,
        gtm: 'dashboard-table-gtm',
    };

    return <OpTable {...opTableProps} />;
};

export default RolesTable;
