import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiResponse, ApiErrorResponse } from 'types/apiTypes';
import { Kiosk, CreateKioskPayload, UpdateKioskPayload, UpdateAppearanceConfigPayload, CreateAppearanceConfigPayload } from 'types/kioskTypes';

interface KiosksState {
    kiosks: ApiResponse<Kiosk>;
    selectedKiosk: Kiosk | null;
    fetchKiosksLoading: boolean;
    describeKioskLoading: boolean;
    createKioskLoading: boolean;
    updateKioskLoading: boolean;
    deleteKioskLoading: boolean;
    createAppearanceConfigLoading: boolean;
    updateAppearanceConfigLoading: boolean;
    error: string | null;
}

const initialState: KiosksState = {
    kiosks: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    selectedKiosk: null,
    fetchKiosksLoading: false,
    describeKioskLoading: false,
    createKioskLoading: false,
    updateKioskLoading: false,
    deleteKioskLoading: false,
    createAppearanceConfigLoading: false,
    updateAppearanceConfigLoading: false,
    error: null,
};

// Retrieve the base URL from environment variables
const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
    console.error('REACT_APP_BACKEND_URL is not set');
}

export const fetchKiosks = createAsyncThunk<ApiResponse<Kiosk>, { orgId: number; siteId?: number; }, { rejectValue: string }>(
    'kiosks/fetchKiosks',
    async ({ orgId, siteId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<Kiosk>>(`${apiUrl}/orgs/${orgId}/kiosk`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
                params: {
                    siteId
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch kiosks');
        }
    }
);


export const describeKiosk = createAsyncThunk<ApiResponse<Kiosk>, { orgId: number; kioskId: number; siteId?: number; }, { rejectValue: string }>(
    'kiosks/describeKiosk',
    async ({ orgId, kioskId, siteId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<Kiosk>>(`${apiUrl}/orgs/${orgId}/kiosk/${kioskId}`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
                params: {
                    siteId
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch kiosk details');
        }
    }
);

export const createKiosk = createAsyncThunk<void, { orgId: number; kiosk: Partial<CreateKioskPayload> }, { rejectValue: string; dispatch: any }>(
    'kiosks/createKiosk',
    async ({ orgId, kiosk }, { dispatch, rejectWithValue }) => {
        try {
            await axios.post(`${apiUrl}/orgs/${orgId}/kiosk`, kiosk, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchKiosks({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to create kiosk');
        }
    }
);

export const updateKiosk = createAsyncThunk<void, { orgId: number; kioskId: number; kiosk: Partial<UpdateKioskPayload> }, { rejectValue: string; dispatch: any }>(
    'kiosks/updateKiosk',
    async ({ orgId, kioskId, kiosk }, { dispatch, rejectWithValue }) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/kiosk/${kioskId}`, kiosk, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchKiosks({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update kiosk');
        }
    }
);

export const deleteKiosk = createAsyncThunk<void, { orgId: number; kioskId: number }, { rejectValue: string; dispatch: any }>(
    'kiosks/deleteKiosk',
    async ({ orgId, kioskId }, { dispatch, rejectWithValue }) => {
        try {
            await axios.delete(`${apiUrl}/orgs/${orgId}/kiosk/${kioskId}`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(fetchKiosks({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to delete kiosk');
        }
    }
);

export const createAppearanceConfig = createAsyncThunk<void, { orgId: number; kioskId: number; appearanceConfig: Partial<CreateAppearanceConfigPayload> }, { rejectValue: string; dispatch: any }>(
    'kiosks/createAppearanceConfig',
    async ({ orgId, kioskId, appearanceConfig }, { dispatch, rejectWithValue }) => {
        try {
            await axios.post(`${apiUrl}/orgs/${orgId}/kiosk/${kioskId}/appearance`, appearanceConfig, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(describeKiosk({ orgId, kioskId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to create appearance config');
        }
    }
);

export const updateAppearanceConfig = createAsyncThunk<void, { orgId: number; kioskId: number; appearanceConfig: Partial<UpdateAppearanceConfigPayload> }, { rejectValue: string; dispatch: any }>(
    'kiosks/updateAppearanceConfig',
    async ({ orgId, kioskId, appearanceConfig }, { dispatch, rejectWithValue }) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/kiosk/${kioskId}/appearance`, appearanceConfig, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(describeKiosk({ orgId, kioskId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update appearance config');
        }
    }
);

const kiosksSlice = createSlice({
    name: 'kiosks',
    initialState,
    reducers: {
        clearKiosks(state) {
            state.kiosks = initialState.kiosks;
        },
        clearSelectedKiosk(state) {
            state.selectedKiosk = initialState.selectedKiosk;
        },
        clearError(state) {
            state.error = null;
        },
        clearKiosksState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchKiosks.pending, (state) => {
                state.fetchKiosksLoading = true;
                state.error = null;
            })
            .addCase(fetchKiosks.fulfilled, (state, action) => {
                state.fetchKiosksLoading = false;
                state.kiosks = action.payload;
            })
            .addCase(fetchKiosks.rejected, (state, action) => {
                state.fetchKiosksLoading = false;
                state.error = action.payload || 'Failed to fetch kiosks';
            })
            .addCase(describeKiosk.pending, (state) => {
                state.describeKioskLoading = true;
                state.error = null;
            })
            .addCase(describeKiosk.fulfilled, (state, action) => {
                state.describeKioskLoading = false;
                state.selectedKiosk = action.payload.data[0];
            })
            .addCase(describeKiosk.rejected, (state, action) => {
                state.describeKioskLoading = false;
                state.error = action.payload || 'Failed to fetch kiosk details';
            })
            .addCase(createKiosk.pending, (state) => {
                state.createKioskLoading = true;
                state.error = null;
            })
            .addCase(createKiosk.fulfilled, (state) => {
                state.createKioskLoading = false;
            })
            .addCase(createKiosk.rejected, (state, action) => {
                state.createKioskLoading = false;
                state.error = action.payload || 'Failed to create kiosk';
            })
            .addCase(updateKiosk.pending, (state) => {
                state.updateKioskLoading = true;
                state.error = null;
            })
            .addCase(updateKiosk.fulfilled, (state) => {
                state.updateKioskLoading = false;
            })
            .addCase(updateKiosk.rejected, (state, action) => {
                state.updateKioskLoading = false;
                state.error = action.payload || 'Failed to update kiosk';
            })
            .addCase(deleteKiosk.pending, (state) => {
                state.deleteKioskLoading = true;
                state.error = null;
            })
            .addCase(deleteKiosk.fulfilled, (state) => {
                state.deleteKioskLoading = false;
            })
            .addCase(deleteKiosk.rejected, (state, action) => {
                state.deleteKioskLoading = false;
                state.error = action.payload || 'Failed to delete kiosk';
            })
            .addCase(createAppearanceConfig.pending, (state) => {
                state.createAppearanceConfigLoading = true;
                state.error = null;
            })
            .addCase(createAppearanceConfig.fulfilled, (state) => {
                state.createAppearanceConfigLoading = false;
            })
            .addCase(createAppearanceConfig.rejected, (state, action) => {
                state.createAppearanceConfigLoading = false;
                state.error = action.payload || 'Failed to create appearance config';
            })
            .addCase(updateAppearanceConfig.pending, (state) => {
                state.updateAppearanceConfigLoading = true;
                state.error = null;
            })
            .addCase(updateAppearanceConfig.fulfilled, (state) => {
                state.updateAppearanceConfigLoading = false;
            })
            .addCase(updateAppearanceConfig.rejected, (state, action) => {
                state.updateAppearanceConfigLoading = false;
                state.error = action.payload || 'Failed to update appearance config';
            });
    },
});

export const { clearKiosks, clearSelectedKiosk, clearError, clearKiosksState } = kiosksSlice.actions;
export default kiosksSlice.reducer;
