import React, { useEffect, useState } from 'react';
import { Layout, Spin } from 'antd';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import './GuestPass.scss';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { formatFullName } from 'utils/utils';

const { Content } = Layout;
const apiUrl = process.env.REACT_APP_BACKEND_URL;

interface Entry {
    id: number;
    name: string;
    isOpened: boolean; // To track if it's opened locally
    isFailed: boolean;
}

const GuestPass: React.FC = () => {
    const [isValid, setIsValid] = useState<boolean>(false);
    const [guestPass, setGuestPass] = useState<any>({});
    const [integration, setIntegration] = useState<any>({});
    const [msiAltaEntries, setMsiAltaEntries] = useState<Entry[]>([]);
    const [visitors, setVisitors] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [buttonLoading, setButtonLoading] = useState<{ [key: number]: boolean }>({}); // Track loading for each button
    const { token } = useParams();

    useEffect(() => {
        setIsLoading(true);
        if (token) {
            axios.get(`${apiUrl}/guestPass/${token}`)
                .then(response => {
                    const guestPassData = response.data.data[0].guestPass[0];
                    const integrationData = response.data.data[0].integration[0];
                    const entries = guestPassData.msiAltaEntryId;
                    setGuestPass(guestPassData);
                    setIntegration(integrationData);
                    setVisitors(guestPassData.visitor);
                    // Map over entries to create the list of buttons
                    const entryList = entries.map((entry: any) => ({
                        id: entry.id,
                        name: entry.name,
                        isOpened: false,
                        isFailed: false,
                    }));

                    setMsiAltaEntries(entryList);

                    // Validate guest pass time
                    if (guestPassData.startUtc && guestPassData.endUtc) {
                        const now = dayjs();
                        setIsValid(now.isAfter(dayjs(guestPassData.startUtc).utc(true).local()) && now.isBefore(dayjs(guestPassData.endUtc).utc(true).local()));
                    } else {
                        setIsValid(false);
                    }
                })
                .catch(error => {
                    console.error('Token validation error:', error);
                    setIsValid(false);
                    setIsLoading(false);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [token]);

    const formatVisitorNames = () => {
        if (visitors.length === 0) return '';
        const firstVisitorName = formatFullName(visitors[0].firstName, visitors[0].middleName, visitors[0].lastName);

        if (visitors.length === 1) {
            return `Visitor: ${firstVisitorName}`;
        } else {
            const additionalVisitorsCount = visitors.length - 1;
            return `Visitor: ${firstVisitorName} (+${additionalVisitorsCount})`;
        }
    };

    // Handler to trigger the open entry and update the status
    const handleOpenEntry = async (entryId: number) => {
        // Set loading for the clicked button
        setButtonLoading((prev) => ({ ...prev, [entryId]: true }));

        let ipAddress = 'UNKNOWN';

        // Attempt to fetch the IP address
        try {
            const ipResponse = await Promise.race([
                axios.get<{ ip: string }>('https://api.ipify.org?format=json'),
                new Promise((_, reject) =>
                    setTimeout(() => reject(new Error('Timeout')), 5000)
                )
            ]) as { data: { ip: string } };

            ipAddress = ipResponse.data.ip;
        } catch (ipError) {
            console.error('Failed to fetch IP address within the timeout or another error occurred:', ipError);
            ipAddress = 'UNKNOWN'; // Default to UNKNOWN if fetch fails or times out
        }

        try {
            const visitorNames = formatVisitorNames();
            const response = await axios.post(
                `https://api.openpath.com/orgs/${integration.msiAltaOrgId}/users/${integration.msiAltaUserId}/credentials/${guestPass.msiAltaCredentialId}/cloudKeyEntryUnlock`,
                {
                    entryId: entryId,
                    description: `${visitorNames}`,
                },
                {
                    headers: {
                        Authorization: `Bearer ${integration.msiAltaToken}`
                    }
                }
            );

            if (response.status === 204) {
                setMsiAltaEntries((prevEntries) =>
                    prevEntries.map((entry) =>
                        entry.id === entryId
                            ? { ...entry, isOpened: true, isFailed: false }
                            : { ...entry, isOpened: false }
                    )
                );

                // Reset the button state after 5 seconds
                setTimeout(() => {
                    setMsiAltaEntries((prevEntries) =>
                        prevEntries.map((entry) =>
                            entry.id === entryId ? { ...entry, isOpened: false } : entry
                        )
                    );
                }, 5000);

                // Log the successful entry unlock
                await axios.post(`${apiUrl}/guestPass/${token}/log`, {
                    entryId: entryId,
                    ipAddress: ipAddress,
                    success: 1
                });

                console.log("Entry successfully opened and logged");
            } else {
                // Log failure if response status is not 204
                await axios.post(`${apiUrl}/guestPass/${token}/log`, {
                    entryId: entryId,
                    ipAddress: ipAddress,
                    success: 0
                });

                setMsiAltaEntries((prevEntries) =>
                    prevEntries.map((entry) =>
                        entry.id === entryId ? { ...entry, isFailed: true } : entry
                    )
                );

                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error('Error opening entry:', error);

            // Log failure in case of an error
            try {
                await axios.post(`${apiUrl}/guestPass/${token}/log`, {
                    entryId: entryId,
                    ipAddress: ipAddress,
                    success: 0
                });
            } catch (logError) {
                console.error('Failed to log unlock attempt:', logError);
            }

            setMsiAltaEntries((prevEntries) =>
                prevEntries.map((entry) =>
                    entry.id === entryId ? { ...entry, isFailed: true } : entry
                )
            );
        } finally {
            // Remove loading state for the button
            setButtonLoading((prev) => ({ ...prev, [entryId]: false }));
        }
    };

    return (

        <Layout className="guest-pass-layout">
            <Content className="guest-pass-content">
                <img src="/images/invisit_main.png" alt="Logo" className="logo" />
                {isLoading ? (
                    <div className="loading-container">
                        <Spin />
                    </div>
                ) : isValid ? (
                    <>
                        <div className="guest-pass-header">
                            <p>Hello! You’ve arrived at this page because someone has shared a Guest Access link with you, allowing you to unlock an entry, controlled by Avigilon Alta, by clicking the button(s) below.</p>

                            <p><strong>Guest Pass Expiration: {dayjs(guestPass.endUtc).utc(true).local().format('YYYY-MM-DD h:mm A')}</strong></p>

                            <p>Please note that all unlock activity will be logged and visible to the administrators of the InVisit account and the Avigilon Alta account that owns the entry.</p>
                        </div>

                        {/* Render the list of buttons */}
                        <div className="entry-buttons">
                            {msiAltaEntries.map((entry) => (
                                <OpButton
                                    key={entry.id}
                                    onClick={() => handleOpenEntry(entry.id)}
                                    loading={buttonLoading[entry.id]}
                                    className={entry.isOpened ? 'opened' : entry.isFailed ? 'failed' : ''}
                                >
                                    {buttonLoading[entry.id]
                                        ? 'Requesting...'
                                        : entry.isOpened
                                            ? `OPENED: ${entry.name}`
                                            : entry.isFailed
                                                ? `FAILED: ${entry.name}`
                                                : `OPEN: ${entry.name}`}
                                </OpButton>
                            ))}
                        </div>
                    </>
                ) : (
                    <div className="message-box">
                        <span>Invalid or expired link.</span>
                    </div>
                )}
            </Content>

            <footer className="guest-pass-footer">
                &copy; 2024 Invisit, LLC
            </footer>
        </Layout>
    );
};

export default GuestPass;
