import React, { useEffect, useState } from 'react';
import { OpForm, IOnSubmitArgs } from 'components/customAntd/DLS/OpForm/OpForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { fetchUsersInRole, bulkUpdateRoleUsers, clearUsersInRole } from 'store/slices/rolesSlice';
import { notification, Form, Transfer, TransferProps, GetProp, TableColumnsType, Table, Space, TableProps, FormInstance, Spin } from 'antd';
import { clearUsers, fetchUsers } from 'store/slices/usersSlice';
import { formatFullName, hasPermission } from 'utils/utils';
import { User } from 'types/userTypes';
import { LoadingOutlined } from '@ant-design/icons';
import { profileIcon } from 'utils/visitorsHelper';
import { fetchTokenIdentity } from 'store/slices/authSlice';

type TransferItem = GetProp<TransferProps, 'dataSource'>[number];
type TableRowSelection<T extends object> = TableProps<T>['rowSelection'];

interface UserContentProps {
    form: FormInstance;
}

interface DataType {
    key: number;
    fullName: string;
    email: string;
}

interface TableTransferProps extends TransferProps<TransferItem> {
    dataSource: DataType[];
    leftColumns: TableColumnsType<DataType>;
    rightColumns: TableColumnsType<DataType>;
}

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }: TableTransferProps) => (
    <Transfer {...restProps} disabled={restProps.disabled}>
        {({
            direction,
            filteredItems,
            onItemSelect,
            onItemSelectAll,
            selectedKeys: listSelectedKeys,
            disabled: listDisabled,
        }) => {
            const columns = direction === 'left' ? leftColumns : rightColumns;
            const rowSelection: TableRowSelection<TransferItem> = {
                getCheckboxProps: () => ({ disabled: listDisabled }),
                onChange(selectedRowKeys) {
                    onItemSelectAll(selectedRowKeys, 'replace');
                },
                selectedRowKeys: listSelectedKeys,
            };

            return (
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={filteredItems}
                    size="small"
                    style={{ pointerEvents: listDisabled ? 'none' : undefined }}
                    onRow={({ key }) => ({
                        onClick: () => {
                            if (listDisabled) {
                                return;
                            }
                            onItemSelect(key, !listSelectedKeys.includes(key));
                        },
                    })}
                />
            );
        }}
    </Transfer>
);

const UserContent: React.FC<UserContentProps> = ({ form }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const token = useSelector((state: RootState) => state.auth.auth.data[0].token);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

    const { users, fetchUsersLoading } = useSelector((state: RootState) => state.users);
    const { selectedRole, usersInRole, fetchUsersInRoleLoading } = useSelector((state: RootState) => state.roles);

    const [targetKeys, setTargetKeys] = useState<React.Key[]>([]);
    const [activeUsers, setActiveUsers] = useState<User[]>([]);
    const [activeUsersInRole, setActiveUsersInRole] = useState<User[]>([]);

    const hasRoleRead = hasPermission(tokenScopeList, orgId, 'o', 'role:r');
    const hasRoleWrite = hasPermission(tokenScopeList, orgId, 'o', 'role:w');

    useEffect(() => {
        if (orgId) {
            dispatch(fetchUsers({ orgId }));
        }
        if (orgId && selectedRole) {
            dispatch(fetchUsersInRole({ orgId, roleId: selectedRole.id }));
        }
        return () => {
            dispatch(clearUsers());
            dispatch(clearUsersInRole());
        };
    }, [dispatch, orgId, selectedRole]);

    // Filter active users from the fetched users
    useEffect(() => {
        if (users && users.data) {
            const filteredUsers = users.data.filter(user => user.status === 1);
            setActiveUsers(filteredUsers);
        }
    }, [users]);

    // Set active users and targetKeys to only active users in role
    useEffect(() => {
        if (usersInRole) {
            const activeUsersInRole = usersInRole.data.filter(user => user.status === 1);
            setActiveUsersInRole(activeUsersInRole);
            setTargetKeys(activeUsersInRole.map(user => user.id));
        }
    }, [usersInRole]);

    // called whenever transfer happens
    const onChange: TransferProps['onChange'] = (nextTargetKeys, direction, moveKeys) => {
        console.log('targetKeys:', nextTargetKeys);
        console.log('direction:', direction);
        console.log('moveKeys:', moveKeys);
        setTargetKeys(nextTargetKeys);
    };

    const columns: TableColumnsType<TransferItem> = [
        {
            dataIndex: 'fullName',
            title: 'Name',
            render: (text, record) => (
                <Space>
                    {profileIcon({
                        fullName: record.fullName,
                        size: 24,
                        color: isDarkMode ? "#D3D3D3" : "#1f93ff"
                    })}
                    {text}
                </Space>
            ),
        },
        {
            dataIndex: 'email',
            title: 'Email',
        },
    ];

    const handleSubmit = async (args: IOnSubmitArgs<any>) => {
        if (!orgId || !selectedRole) {
            return;
        }

        const { values } = args;
        console.log(values);

        const usersToAdd: number[] = targetKeys.filter(key => !activeUsersInRole.some(user => user.id === key)).map(Number);
        const usersToRemove: number[] = activeUsersInRole.filter(user => !targetKeys.includes(user.id)).map(user => Number(user.id));


        // Super Admin role validation
        if (selectedRole.admin && usersToRemove.length > 0) {
            const remainingSuperAdmins = activeUsersInRole.length - usersToRemove.length;
            if (remainingSuperAdmins < 1) {
                notification.error({
                    message: 'Error',
                    description: `There must be at least one user in the ${selectedRole.name} role.`,
                    placement: 'bottomRight',
                });
                return;
            }
        }

        // Check if removing users are having only this role
        const usersWithoutOtherRoles = usersToRemove.filter(userId => {
            const user = activeUsersInRole.find(user => Number(user.id) === userId);
            return user !== undefined && user.roleCount === 1;
        });

        if (usersWithoutOtherRoles.length > 0) {
            const userNames = usersWithoutOtherRoles.map(userId => {
                const user = activeUsersInRole.find(user => Number(user.id) === userId);
                return user ? formatFullName(user.identity.firstName, user.identity.middleName, user.identity.lastName) : 'Unknown';
            }).join(', ');

            notification.error({
                message: 'Error',
                description: `This is the only role for user(s): ${userNames}. Please ensure all users are assigned to another role before deleting this role.`,
                placement: 'bottomRight',
            });
            return;
        }

        try {
            await dispatch(bulkUpdateRoleUsers({ orgId, roleId: selectedRole.id, add: usersToAdd, remove: usersToRemove }));

            // Check for globalUserId in usersToAdd or usersToRemove
            if (usersToAdd.includes(globalUserId!) || usersToRemove.includes(globalUserId!)) {
                await dispatch(fetchTokenIdentity({ token }));
            }
            notification.success({
                message: 'Success',
                description: 'Users updated successfully.',
                placement: 'bottomRight',
            });
        } catch (error) {
            notification.error({ message: 'Failed to update users.' });
        }
    };

    return (
        <OpForm
            form={form}
            onSubmit={handleSubmit}
            hasError={false}
            defaultButtons={false}
            isReadOnly={!hasRoleWrite && hasRoleRead}
        >
            {fetchUsersLoading || fetchUsersInRoleLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
            ) : (
                <Form.Item name="users" initialValue={targetKeys}>
                    <TableTransfer
                        dataSource={activeUsers.map(user => ({
                            key: user.id,
                            fullName: formatFullName(user.identity.firstName, user.identity.middleName, user.identity.lastName),
                            email: user.identity.email || '',
                        }))}
                        titles={['Available Users', 'Users in Role']}
                        targetKeys={targetKeys}
                        showSearch
                        showSelectAll={false}
                        onChange={onChange}
                        filterOption={(inputValue, item) =>
                            item.fullName.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 || item.email.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                        }
                        leftColumns={columns}
                        rightColumns={columns}
                        disabled={!hasRoleWrite}
                    />
                </Form.Item>
            )}
        </OpForm>
    );
};

export default UserContent;
