import React from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { RootState } from 'store/store';
import { deleteRequest, getRequest, postRequest } from 'api/apiClient';
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { User } from 'types/userTypes';
import { Role } from 'types/roleTypes';
import { formatFullName, hasPermission } from 'utils/utils';
import { TABLE_HEIGHT } from 'constants/ui';
import {
    CONFIRM_DELETE_CONTENT,
    CONFIRM_DELETE_TITLE,
    NOTIFICATION_ERROR,
    NOTIFICATION_SUCCESS,
    USER_DELETE_ERROR,
    USER_DELETE_ERROR_SUPER_ADMIN,
    USER_DELETE_ERROR_SUPER_ADMIN_NOT_FOUND,
    USER_DELETE_SUCCESS,
} from 'constants/messages';
import { USER_DELETED_ID } from 'constants/userActivities';


interface UsersTableProps {
    users: User[];
    roles: Role[];
    setUsers: (user: User[]) => void;
    handleEditClick: (user: User) => void;
    handleAddClick: () => void;
    loading: boolean;
}

const UsersTable: React.FC<UsersTableProps> = ({ users, roles, setUsers, handleEditClick, handleAddClick, loading }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasUserWrite = hasPermission(tokenScopeList, orgId, 'o', 'user:w');

    const handleDeleteUser = async (user: User) => {
        const superAdminRole = roles.find(role => role.admin);
        if (!superAdminRole) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: USER_DELETE_ERROR_SUPER_ADMIN_NOT_FOUND,
                placement: 'bottomRight',
            });
            return;
        }

        try {
            // Fetch users in "Super Admin" role
            const superAdminUsersResponse = await getRequest(`/orgs/${orgId}/roles/${superAdminRole.id}/users`);
            const superAdminUsers = superAdminUsersResponse.data.filter((user: User) => user.status === 1);

            // Check if the only remaining "Super Admin" user is being deleted
            if (superAdminUsers.length === 1 && superAdminUsers[0].id === user.id) {
                notification.error({
                    message: NOTIFICATION_ERROR,
                    description: USER_DELETE_ERROR_SUPER_ADMIN(superAdminRole.name || ''),
                    placement: 'bottomRight',
                });
                return;
            }

            await deleteRequest(`/orgs/${orgId}/users/${user.id}`);
            await postRequest(`/orgs/${orgId}/userActivity`, {
                userId: globalUserId!,
                activityId: USER_DELETED_ID,
                details: formatFullName(user.identity.firstName, user.identity.middleName, user.identity.lastName)
            });

            // Re-fetch the updated list of users
            const updatedUsers = await getRequest(`/orgs/${orgId}/users`);
            const filteredUsers = updatedUsers.data.filter((user: User) => user.status === 1);
            setUsers(filteredUsers);

            notification.success({
                message: NOTIFICATION_SUCCESS,
                description: USER_DELETE_SUCCESS,
                placement: 'bottomRight',
            });
        } catch (error) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: USER_DELETE_ERROR,
                placement: 'bottomRight',
            });
        }
    };

    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: ['identity', 'firstName'],
            label: 'FIRST NAME',
            filter: { type: 'input' },
            sorter: (a, b) => (a.identity.firstName || '').localeCompare(b.identity.firstName || ''),
        },
        {
            dataIndex: ['identity', 'lastName'],
            label: 'LAST NAME',
            filter: { type: 'input' },
            sorter: (a, b) => (a.identity.lastName || '').localeCompare(b.identity.lastName || ''),
        },
        {
            dataIndex: ['identity', 'email'],
            label: 'EMAIL',
            filter: { type: 'input' },
            sorter: (a, b) => (a.identity.email || '').localeCompare(b.identity.email || ''),
        }
    ];

    // Table properties
    const opTableProps: IOpTableProps = {
        dataSource: users,
        columns: columns,
        rowActions: {
            onEditClick: (user: User) => handleEditClick(user),
            onDeleteClick: hasUserWrite ? handleDeleteUser : undefined,
            deleteModalTitle: () => CONFIRM_DELETE_TITLE("User"),
            deleteModalContent: ({ record }) => {
                const fullName = formatFullName(record.identity.firstName, record.identity.middleName, record.identity.lastName);
                return CONFIRM_DELETE_CONTENT(fullName);
            },
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        writeAccess: hasUserWrite,
        allowAddition: hasUserWrite ? {
            itemName: 'User',
            onClick: () => handleAddClick(),
        } : false,
        loading: loading,
        allowExport: true,
        allowShowHideColumns: true,
    };

    return (
        <OpTable {...opTableProps} />
    );
};

export default UsersTable;
