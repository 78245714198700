import { useState, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { OpSelect } from 'components/customAntd/DLS/OpSelect/OpSelect';
import { OpDropdown } from 'components/customAntd/DLS/OpDropdown/OpDropdown';
import { CheckboxList } from './CheckboxList';
import { RadioList } from './RadioList';

import './SelectFilter.scss';

type CheckboxFilter = {
  allowMultiple: true;
  options: ComponentProps<typeof CheckboxList>['options'];
};

type RadioFilter = {
  allowMultiple: false;
  options: ComponentProps<typeof RadioList>['options'];
};

type SelectFilterProps = {
  onChange: (value?: string | string[]) => void;
  defaultValue?: string[];
} & (CheckboxFilter | RadioFilter);

export const SelectFilter = ({
  defaultValue,
  options = [], // options list
  onChange,
  allowMultiple, // boolean determine the CheckboxList or RadioList
}: SelectFilterProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<string[]>(defaultValue || []);

  const handleSelectClear = () => {
    // Reset the select value
    setSelectValue([]);

    // Reset the table state filter
    onChange(undefined);
  };

  // Determine the dropdown render to use
  const dropdownRender = () => {
    return allowMultiple ? (
      <CheckboxList
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        onChange={onChange}
        options={options}
      />
    ) : (
      <RadioList
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        onChange={onChange}
        options={options}
      />
    );
  };

  return (
    <OpDropdown
      className="select-filter"
      dropdownRender={dropdownRender}
      trigger={['click']}
      visible={open}
      onVisibleChange={(flag) => setOpen(flag)}
      // open={open}
      // onOpenChange={(flag) => setOpen(flag)}
    >
      <OpSelect
        data-testid={'select-filter'}
        size="small"
        allowClear
        showSearch={false}
        placeholder={t('Filter')}
        onClear={handleSelectClear}
        value={
          allowMultiple
            ? selectValue.length
              ? `${selectValue.length} selected`
              : undefined
            : selectValue
        }
        open={false}
        options={options} // The checkbox/radio options both conform to the select options
        onChange={onChange}
      />
    </OpDropdown>
  );
};
