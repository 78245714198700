import React, { useCallback, useEffect, useState } from 'react';
import { OpFormDrawer } from 'components/customAntd/DLS/OpFormDrawer/OpFormDrawer';
import { DRAWER_WIDTH } from 'constants/ui';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { VisitorType } from 'types/VisitorTypeTypes';
import { getRequest, postRequest } from 'api/apiClient';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { User } from 'types/userTypes';
import { RedFlag } from 'types/redFlagTypes';
import { VisitorInvite } from 'types/visitorInvitationTypes';
import VisitorList from '../formComponents/VisitorList';
import { Location } from 'types/locationTypes';
import { OpRow } from 'components/customAntd/DLS/OpRow/OpRow';
import { OpCol } from 'components/customAntd/DLS/OpCol/OpCol';
import { formatFullName, transformNullToEmptyString } from 'utils/utils';
import { notification } from 'antd';
import STATUS from 'constants/status';
import { VISITOR_INVITATION_CREATED_ID } from 'constants/userActivities';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';
import { fetchVisits, fetchVisitsToday } from 'store/slices/visitsSlice';
import { fetchInvitationConfig } from 'store/slices/visitorInvitationSlice';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const now = dayjs();
const defaultStartTime = now.minute(0).add(1, 'hour');
const defaultEndTime = defaultStartTime.add(1, 'hour');

interface InvitationDrawerProps {
    open: boolean;
    onClose: () => void;
}

const InvitationDrawer: React.FC<InvitationDrawerProps> = ({ open, onClose }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);

    const [locations, setLocations] = useState<Location[]>([]);
    const [hosts, setHosts] = useState<User[]>([]);
    const [redFlags, setRedFlags] = useState<RedFlag[]>([]);
    const [visitorTypes, setVisitorTypes] = useState<VisitorType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [form] = OpForm.useForm();

    const initialValues = {
        siteId: globalLocationId,
        visitorTypeId: visitorTypes[0]?.id,
        date: now,
        startTime: defaultStartTime,
        endTime: defaultEndTime,
        visitors: [],
    };

    // Fetch visits and red flags
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (invitationConfig === null) {
                    await dispatch(fetchInvitationConfig({ orgId }));
                }

                const locations = await getRequest(`/orgs/${orgId}/sites`, { status: 1 });
                setLocations(locations.data);

                const hosts = await getRequest(`/orgs/${orgId}/users`);
                const filteredHosts = hosts.data.filter((user: User) => user.status === 1 && (user.hostExclude === 0 || user.hostExclude === null));
                setHosts(filteredHosts);

                const redFlags = await getRequest(`/orgs/${orgId}/redflag`);
                setRedFlags(redFlags.data);

                const visitorTypes = await getRequest(`/orgs/${orgId}/visitorTypes`);
                setVisitorTypes(visitorTypes.data);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, [orgId]);


    const handleSubmit = useCallback(async ({ values }: IOnSubmitArgs) => {
        setIsSubmitting(true);
        try {
            if (values.visitors.length === 0) {
                notification.error({
                    message: 'Error',
                    description: 'Please add at least one visitor.',
                    placement: 'bottomRight',
                });
                setIsSubmitting(false);
                return;
            }

            const transformedValues = transformNullToEmptyString(values);
            const { date, startTime, endTime, ...payload } = transformedValues;
            let startDate = date ? dayjs(date).hour(dayjs(startTime).hour()).minute(dayjs(startTime).minute()) : null;
            let endDate = date ? dayjs(date).hour(dayjs(endTime).hour()).minute(dayjs(endTime).minute()) : null;
            // Adjust endDate to the next day if it's before startDate
            if (endDate && startDate && endDate.isSameOrBefore(startDate)) {
                endDate = endDate.add(1, 'day');
            }
            await postRequest(`/orgs/${orgId}/visitorInvitation`, {
                ...payload,
                scheduleIn: startDate?.format(DATE_TIME_FORMAT),
                scheduleOut: endDate?.format(DATE_TIME_FORMAT),
                visitors: transformedValues.visitors.map((visitor: VisitorInvite) => ({
                    ...visitor,
                    status: STATUS.PENDING.id,
                })),
            });

            const fullNameList = values.visitors.map((visitor: VisitorInvite) =>
                formatFullName(visitor.firstName, null, visitor.lastName,)).join(', ');
            await postRequest(`/orgs/${orgId}/userActivity`, {
                userId: globalUserId,
                activityId: VISITOR_INVITATION_CREATED_ID,
                details: fullNameList
            });
            notification.success({
                message: 'Success',
                description: 'Visitor invitation created successfully.',
                placement: 'bottomRight',
            });

            await dispatch(fetchVisits({ orgId }));
            await dispatch(fetchVisitsToday({ orgId }));
            form.resetFields();
            onClose();
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred during the submission.',
                placement: 'bottomRight',
            });
            console.error("Form submission failed:", error);
        } finally {
            setIsSubmitting(false);
        }
    }, [orgId, globalUserId, form, dispatch, onClose]);

    const handleFieldsChange = (changedFields: any) => {
        const changedField = changedFields[0];
        if (changedField) {
            const { name, value } = changedField;
            const [field] = name;
            if (field === 'startTime') {
                const startTime = value;
                if (startTime) {
                    const startMoment = dayjs(startTime);
                    const newEndTime = startMoment.add(1, 'hour');
                    form.setFieldsValue({ endTime: newEndTime });
                }
            } else if (field === 'endTime') {
                const endTime = value;
                const startTime = form.getFieldValue('startTime');

                if (startTime && endTime) {
                    const startMoment = dayjs(startTime);
                    const endMoment = dayjs(endTime);

                    if (startMoment.isSameOrAfter(endMoment)) {
                        form.setFieldsValue({ startTime: endMoment.subtract(1, 'hour') });
                    }
                }
            }
        }
    };

    return (
        <OpFormDrawer
            title={'New Invitation'}
            width={DRAWER_WIDTH}
            open={open}
            isFormLoading={loading || isSubmitting}
            onClose={() => {
                form.resetFields();
                onClose();
            }}
            form={form}
            formComponent={
                <OpForm
                    form={form}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    onFieldsChange={handleFieldsChange}
                    hasError={false}
                    defaultButtons={false}
                >
                    <VisitorList form={form} redFlags={redFlags} isInviting={true} />
                    <OpForm.Select
                        label="Visitor Type" name="visitorTypeId" rules={[{ required: true, message: 'Please select a visitor type.' }]}
                        placeholder="Select Visitor Type"
                        options={visitorTypes.map(type => ({
                            label: type.name,
                            value: type.id
                        }))}
                    />
                    <OpForm.Select
                        label="Locations" name="siteId" rules={[{ required: true, message: 'Please select a location.' }]}
                        placeholder="Select Locations"
                        options={locations.map(location => ({
                            label: location.name,
                            value: location.id
                        }))}
                    />
                    <OpRow gutter={8} >
                        <OpCol span={8} >
                            <OpForm.DatePicker
                                name="date" label="Schedule" rules={[{ required: true, message: 'Please select a date' }]}
                                showTime={false}
                                format={DATE_FORMAT}
                                style={{ width: '100%' }}
                            />
                        </OpCol>
                        <OpCol span={8}>
                            <OpForm.TimePicker
                                name="startTime" label="Start Time" rules={[{ required: true, message: 'Please select a start time' }]}
                                format="h:mm A"
                                use12Hours
                                minuteStep={5}
                                needConfirm={false}
                                style={{ width: '100%' }}
                            />
                        </OpCol>
                        <OpCol span={8}>
                            <OpForm.TimePicker
                                name="endTime" label="End Time" rules={[{ required: true, message: 'Please select an end time' }]}
                                format="h:mm A"
                                use12Hours
                                minuteStep={5}
                                needConfirm={false}
                                style={{ width: '100%' }}
                            />
                        </OpCol>
                    </OpRow>
                    {(invitationConfig?.visitCompany.included === 1) ? (
                        <OpForm.Input
                            label="Company" name="company"
                            rules={[{ required: invitationConfig.visitCompany.required === 1, message: 'Please enter company.' }]}
                        />
                    ) : undefined}
                    {(invitationConfig?.host.included === 1) ? (
                        <OpForm.Select
                            label="Host" name="hostId"
                            rules={[{ required: invitationConfig.host.required === 1 && hosts.length > 0, message: 'Please select a host.' }]}
                            showSearch
                            placeholder={hosts.length === 0 ? "No hosts are available" : "Select Host"}
                            disabled={hosts.length === 0}
                            options={hosts.map(host => ({
                                label: host.identity?.firstName || host.identity?.lastName
                                    ? formatFullName(host.identity.firstName, host.identity.middleName, host.identity.lastName)
                                    : host.identity?.email,
                                value: host.id
                            }))}
                        />
                    ) : undefined}
                    {(invitationConfig?.visitPurpose.included === 1) ? (
                        <OpForm.TextAreaInput
                            label="Purpose" name="purpose" rows={4}
                            rules={[{ required: invitationConfig.visitPurpose.required === 1, message: 'Please enter purpose.' }]}
                        />
                    ) : undefined}
                    {(invitationConfig?.visitNotes?.included === 1) ? (
                        <OpForm.TextAreaInput
                            label="Note" name="note" rows={4}
                            rules={[{ required: invitationConfig.visitNotes.required === 1, message: 'Please enter note.' }]}
                        />
                    ) : undefined}
                </OpForm>
            }
        />
    );
};

export default InvitationDrawer;