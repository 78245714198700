import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StopOutlined, EyeOutlined } from "@ant-design/icons";
import { OpRow } from 'components/customAntd/DLS/OpRow/OpRow';
import { OpCol } from 'components/customAntd/DLS/OpCol/OpCol';
import { OpCard } from 'components/customAntd/DLS/OpCard/OpCard';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { RootState, AppDispatch } from "store/store";
import { fetchRedFlagCount } from 'store/slices/redFlagSlice';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import RedFlagCard from './RedFlagCard';

const RedFlagOverView: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { redFlagCount, fetchRedFlagCountLoading } = useSelector((state: RootState) => state.redFlags);
    const [blockCount, setBlockCount] = useState<number>(0);
    const [watchCount, setWatchCount] = useState<number>(0);

    useEffect(() => {
        if (orgId) {
            dispatch(fetchRedFlagCount({ orgId }));
        }
    }, [orgId, dispatch]);

    useEffect(() => {
        if (redFlagCount.data.length > 0) {
            const blockItem = redFlagCount.data.find(item => item.level === 1);
            const watchItem = redFlagCount.data.find(item => item.level === 2);

            setBlockCount(blockItem?.count ? blockItem.count : 0);
            setWatchCount(watchItem?.count ? watchItem.count : 0);
        } else {
            setBlockCount(0);
            setWatchCount(0);
        }
    }, [redFlagCount]);

    return (
        <OpSpace
            direction="vertical"
            size="middle"
            style={{ display: 'flex' }}
        >

            <OpCard type="inner" title="Red Flags Overview">
                {fetchRedFlagCountLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    </div>
                ) : (
                    <OpRow justify="space-evenly">
                        <OpCol span={6} style={{ padding: '30px' }}>
                            <RedFlagCard
                                icon={
                                    <StopOutlined
                                        style={{
                                            color: "white",
                                            backgroundColor: "rgba(242,103,87,1.0)",
                                            borderRadius: 20,
                                            fontSize: 24,
                                            padding: 8,
                                        }}
                                    />
                                }
                                title="BLOCKLIST"
                                value={blockCount}
                                color="rgba(242,103,87,0.1)"
                            />
                        </OpCol>
                        <OpCol span={6} style={{ padding: '30px' }}>
                            <RedFlagCard
                                icon={
                                    <EyeOutlined
                                        style={{
                                            color: "white",
                                            backgroundColor: "#FFD54F",
                                            borderRadius: 20,
                                            fontSize: 24,
                                            padding: 8,
                                        }}
                                    />
                                }
                                title="WATCHLIST"
                                value={watchCount}
                                color="rgba(255, 213, 79, 0.1)"
                            />
                        </OpCol>
                    </OpRow>
                )}
            </OpCard>
        </OpSpace>

    );
}

export default RedFlagOverView;
