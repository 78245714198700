import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { IOpTableProps, OpTable } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import DateRangeLocationFilter2 from 'components/customAntd/DateRangeLocationFilter2';
import { Bar } from 'react-chartjs-2';
import { AppDispatch, RootState } from 'store/store';
import { fetchVisits, setSelectedVisit, setVisitsEndDate, setVisitsStartDate } from 'store/slices/visitsSlice';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Visit } from 'types/visitTypes';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import STATUS from 'constants/status';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';
import { formatFullName } from 'utils/utils';
import { DRAWER_WIDTH, TABLE_HEIGHT } from 'constants/ui';
import { Modal } from 'antd';
import { OpCollapse } from 'components/customAntd/DLS/OpCollapse/OpCollapse';
import VisitorsDrawer from 'components/pages/visitors/drawer/VisitorsDrawer';
import { RedFlag } from 'types/redFlagTypes';
import { getRequest } from 'api/apiClient';

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

interface TableData {
    fullName: string;
    totalCount: number;
    chartData: { date: string, count: number }[];
    tableData: { key: string, date: string, hostName: string, visitId: number }[];
}

interface VisitorsTrendChartProps {
    visitor: TableData | null;
    startDate: Dayjs;
    endDate: Dayjs;
}

function VisitorsTrendChart({ visitor, startDate, endDate }: VisitorsTrendChartProps) {
    const generateDateRange = (start: Dayjs, end: Dayjs) => {
        const dates = [];
        let currentDate = start;
        while (currentDate.isBefore(end) || currentDate.isSame(end, 'day')) {
            dates.push(currentDate.format('M/D'));
            currentDate = currentDate.add(1, 'day');
        }
        return dates;
    };

    const labels = generateDateRange(startDate, endDate);

    const initialData = Array(labels.length).fill(0);

    const chartData = visitor ? visitor.chartData.reduce((acc, visit) => {
        const dateLabel = dayjs(visit.date).format('M/D');
        const index = labels.indexOf(dateLabel);
        if (index !== -1) {
            acc[index] = visit.count;
        }
        return acc;
    }, initialData) : initialData;

    const data = {
        labels,
        datasets: [
            {
                label: 'Visit Count',
                data: chartData,
                backgroundColor: 'rgba(54, 162, 235, 0.6)',  // Light blue
                borderColor: 'rgba(54, 162, 235, 1)',        // Darker blue
                borderWidth: 1,
            },
        ],
    };

    const [labelColor, setLabelColor] = useState(getComputedStyle(document.documentElement).getPropertyValue('--colorTextBase'));
    const [gridColor, setGridColor] = useState(getComputedStyle(document.documentElement).getPropertyValue('--colorBorderSecondary'));

    useEffect(() => {
        const handleThemeChange = () => {
            setLabelColor(getComputedStyle(document.documentElement).getPropertyValue('--colorTextBase'));
            setGridColor(getComputedStyle(document.documentElement).getPropertyValue('--colorBorderSecondary'));
        };

        // Listen for changes to the theme
        window.addEventListener('themechange', handleThemeChange);

        // Initial set
        handleThemeChange();

        return () => {
            window.removeEventListener('themechange', handleThemeChange);
        };
    }, []);

    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1,
                    color: labelColor,
                },
                grid: {
                    color: gridColor,
                },
            },
            x: {
                ticks: {
                    color: labelColor,
                },
                grid: {
                    color: gridColor,
                },
            },
        },
        plugins: {
            legend: {
                display: true,
            },
        },
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '280px' }}>
            <Bar data={data} options={options} />
        </div>
    );
}

const RepeatVisitorsTable: React.FC = () => {
    // Redux hooks
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const { visits, fetchVisitsLoading } = useSelector((state: RootState) => state.visits);
    const [selectedVisitorTableData, setSelectedVisitorTableData] = useState<TableData | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isVisitorsDrawerOpen, setIsVisitorsDrawerOpen] = useState<boolean>(false);

    const selectedLocationIdRef = useRef<number>(globalLocationId!);
    const startDateRef = useRef(dayjs().startOf('month').startOf('day').format(DATE_TIME_FORMAT));
    const endDateRef = useRef(dayjs().endOf('month').endOf('day').format(DATE_TIME_FORMAT));

    const [redFlags, setRedFlags] = useState<RedFlag[]>([]);
    const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);

    const fetchVisitsData = async () => {
        await dispatch(setVisitsStartDate(startDateRef.current));
        await dispatch(setVisitsEndDate(endDateRef.current));
        await dispatch(fetchVisits({ orgId }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const redFlags = await getRequest(`/orgs/${orgId}/redflag`);
                setRedFlags(redFlags.data);
            } catch (error) {
                console.log("Failed to fetch data.");
            }
        };
        fetchVisitsData();
        fetchData();
        // eslint-disable-next-line
    }, [orgId]);

    const handleDateRangeLocationFilter = (locationId: number, startDate: string, endDate: string) => {
        selectedLocationIdRef.current = locationId;
        startDateRef.current = startDate;
        endDateRef.current = endDate;
        fetchVisitsData();
    };

    const handleRowClick = (record: TableData) => {
        setSelectedVisitorTableData(record);
        setIsModalVisible(true);
    };

    const getRepeatVisitorsData = (visits: Visit[]): TableData[] => {
        const visitorMap = new Map<string, TableData>();

        visits.forEach((visit) => {
            visit.visitors.forEach((visitor) => {
                if (visitor.status === STATUS.SIGNED_IN.id || visitor.status === STATUS.SIGNED_OUT.id) {
                    const fullName = formatFullName(visitor.firstName, visitor.middleName, visitor.lastName);
                    let visitDate = "";
                    if (visitor.status === STATUS.SIGNED_IN.id) {
                        visitDate = dayjs(visitor.signIn).format(DATE_FORMAT);
                    } else {
                        visitDate = dayjs(visitor.signOut).format(DATE_FORMAT);
                    }

                    const hostName = formatFullName(visit.host.firstName, null, visit.host.lastName);
                    const key = `${visitor.id}`;

                    if (!visitorMap.has(fullName)) {
                        visitorMap.set(fullName, {
                            fullName,
                            totalCount: 0,
                            chartData: [],
                            tableData: []
                        });
                    }

                    const visitorData = visitorMap.get(fullName);
                    if (visitorData) {
                        visitorData.totalCount += 1;
                        const chartEntry = visitorData.chartData.find(entry => entry.date === visitDate);
                        if (chartEntry) {
                            chartEntry.count += 1;
                        } else {
                            visitorData.chartData.push({ date: visitDate, count: 1 });
                        }
                        if (!visitorData.tableData.some(entry => entry.key === key)) {
                            visitorData.tableData.push({ key, date: visitDate, hostName, visitId: visit.id });
                        }
                    }
                }
            });
        });

        // Filter out visitors with totalCount less than 2 and sort by totalCount in descending order, then by fullName in ascending order
        return Array.from(visitorMap.values())
            .filter(visitorData => visitorData.totalCount > 1)
            .sort((a, b) => b.totalCount - a.totalCount || a.fullName.localeCompare(b.fullName));
    };

    const tableData = getRepeatVisitorsData(visits);

    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'fullName',
            label: 'Full Name',
            filter: { type: 'input' },
            sorter: (a, b) => a.fullName.localeCompare(b.fullName),
        },
        {
            dataIndex: 'totalCount',
            label: 'Visit Count',
            filter: { type: 'input' },
            sorter: (a, b) => a.totalCount - b.totalCount,
        },
    ];

    const visitorModalColumns: OpTableRawColumnType[] = [
        {
            dataIndex: 'date',
            label: 'Date',
        },
        {
            dataIndex: 'hostName',
            label: 'Host Name',
        },
    ];

    const opTableProps: IOpTableProps = {
        dataSource: tableData,
        label: `${dayjs(startDateRef.current).format(DATE_FORMAT)} - ${dayjs(endDateRef.current).format(DATE_FORMAT)}`,
        columns: columns,
        rowActions: {
            onEditClick: handleRowClick,
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        loading: fetchVisitsLoading,
        allowExport: true,
        allowShowHideColumns: true,
        gtm: 'dashboard-table-gtm',
        rowKey: 'fullName',
    };

    const handleVisitClick = (visit: any) => {
        const selectedVisit = visits.find(v => Number(v.id) === Number(visit.visitId));
        dispatch(setSelectedVisit(selectedVisit!));
        setIsVisitorsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsVisitorsDrawerOpen(false);
        dispatch(setSelectedVisit(null));
    };

    return (
        <OpSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
            <DateRangeLocationFilter2
                onDateRangeLocationFilter={handleDateRangeLocationFilter}
                initialStartDate={startDateRef.current}
                initialEndDate={endDateRef.current}
            />
            <OpTable {...opTableProps} />
            <Modal
                title={selectedVisitorTableData ? selectedVisitorTableData.fullName : "No Visitor Selected"}
                open={isModalVisible}
                onCancel={() => {
                    setIsModalVisible(false);
                    setSelectedVisitorTableData(null);
                }}
                footer={null}
                width={DRAWER_WIDTH}
                centered
            >
                <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    <div style={{ position: 'relative' }}>
                        <OpCollapse defaultActiveKey={['1']} items={[
                            {
                                key: '1',
                                label: 'Visitor Trend Chart',
                                children: (
                                    <VisitorsTrendChart visitor={selectedVisitorTableData} startDate={dayjs(startDateRef.current)} endDate={dayjs(endDateRef.current)} />
                                )
                            }
                        ]} />
                        {selectedVisitorTableData && (
                            <OpTable
                                dataSource={selectedVisitorTableData ? selectedVisitorTableData.tableData : []}
                                columns={visitorModalColumns}
                                pagination={false}
                                allowGlobalSearch={false}
                                allowExport={false}
                                allowShowHideColumns={false}
                                style={{ marginTop: '10px' }}
                                rowKey="key"
                                rowActions={{
                                    onEditClick: handleVisitClick,
                                }}
                            />
                        )}
                    </div>
                </div>
            </Modal>

            {(isVisitorsDrawerOpen) && (
                <VisitorsDrawer
                    open={isVisitorsDrawerOpen}
                    redFlags={redFlags}
                    isProfileDrawerOpen={isProfileDrawerOpen}
                    onClose={handleDrawerClose}
                    setIsProfileDrawerOpen={setIsProfileDrawerOpen}
                />
            )}
        </OpSpace>
    );
}

export default RepeatVisitorsTable;
