import React from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import GuestPassReportTable from './GuestPassReportTable';

const GuestPassReprot: React.FC = () => {
    return (
        <OpPage title="Guest Pass Report" tooltip="" subtitle="">
            <GuestPassReportTable />
        </OpPage>
    );
}

export default GuestPassReprot;
