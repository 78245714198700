import React, { useCallback } from 'react';
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { notification } from 'antd';
import { updateUser } from 'store/slices/usersSlice';

interface InfoContentProps {
    form: any;
    onClose: () => void;
}

const InfoContent: React.FC<InfoContentProps> = ({ form, onClose }) => {
    const dispatch: AppDispatch = useDispatch();

    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { globalUser } = useSelector((state: RootState) => state.users);
    const initialValues = {
        identity: {
            email: globalUser?.identity?.email,
            firstName: globalUser?.identity?.firstName,
            middleName: globalUser?.identity?.middleName,
            lastName: globalUser?.identity?.lastName,
        }
    };

    const handleSubmit = useCallback(({ values, touchedValues, initialValues }: IOnSubmitArgs<any>) => {
        if (globalUser?.id) {
            const userPayload = {
                identity: {
                    firstName: values.identity.firstName,
                    middleName: values.identity.middleName,
                    lastName: values.identity.lastName,
                }
            };
            dispatch(updateUser({ orgId, userId: globalUser.id, user: userPayload, global: true }));
            notification.success({
                message: 'Success',
                description: 'User updated successfully.',
                placement: 'bottomRight',
            });
            onClose();
        }
    }, [dispatch, orgId, globalUser?.id, onClose]);

    return (
        <OpForm
            form={form}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            hasError={false}
        >
            <OpForm.Input
                label="Email"
                name={['identity', 'email']}
                rules={[{ required: true, message: 'Email is required', type: 'email' }]}
                disabled
            />
            <OpForm.Input
                label="First Name"
                name={['identity', 'firstName']}
                rules={[{ required: true, message: 'First name is required' }]}
            />
            <OpForm.Input
                label="Middle Name"
                name={['identity', 'middleName']}
            />
            <OpForm.Input
                label="Last Name"
                name={['identity', 'lastName']}
            />
        </OpForm>
    );
};

export default InfoContent;
