import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { bulkUpdateVisitorWorkflowAgreements, fetchVisitorWorkflowAgreements, updateVisitorWorkflowConfig } from 'store/slices/visitorWorkflowsSlice';
import { List } from 'antd';
import { fetchAgreements } from 'store/slices/agreementsSlice';
import { hasPermission } from 'utils/utils';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';

interface AgreementsContentProps {
    form: any;
}

const agreementsData = [
    { name: 'agreementsDisplay', label: 'Display Agreements', valuePropName: 'checked' },
    { name: 'agreementsAccept', label: 'Require Agreements', valuePropName: 'checked' },
    { name: 'agreementsSignature', label: 'Require Signature', valuePropName: 'checked' },
    { name: 'agreementsEach', label: 'Agreement Per Visitor', valuePropName: 'checked' },
];

const AgreementsContent: React.FC<AgreementsContentProps> = ({ form }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { selectedVisitorWorkflow, selectedSignInWorkflowConfig: visitorWorkflowConfig, visitorAgreements } = useSelector((state: RootState) => state.visitorWorkflows);
    const { agreements, fetchAgreementsLoading } = useSelector((state: RootState) => state.agreements);

    const [isAgreementsDisplayEnabled, setIsAgreementsDisplayEnabled] = useState(false);
    const [isFeatureUnavailableModalOpen, setFeatureUnavailableModalOpen] = useState(false);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasSigninconfRead = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:r');
    const hasSigninconfWrite = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:w');
    const subscriptions = useSelector((state: RootState) => state.subscriptions.subscriptions);

    useEffect(() => {
        if (orgId) {
            dispatch(fetchAgreements({ orgId }));
        }
    }, [orgId, dispatch]);

    useEffect(() => {
        if (orgId && selectedVisitorWorkflow) {
            dispatch(fetchVisitorWorkflowAgreements({ orgId, visitorWorkflowId: selectedVisitorWorkflow.id }));
        }
    }, [orgId, selectedVisitorWorkflow, dispatch]);

    useEffect(() => {
        setIsAgreementsDisplayEnabled(visitorWorkflowConfig?.agreementsDisplay === 1);
        if (!visitorWorkflowConfig?.agreementsDisplay) {
            form.setFieldsValue({ agreementsAccept: false, agreementsSignature: false });
        }
    }, [visitorWorkflowConfig, form]);

    const initialValues = useMemo(() => {
        if (selectedVisitorWorkflow) {
            return {
                agreementsDisplay: visitorWorkflowConfig?.agreementsDisplay,
                agreementsAccept: visitorWorkflowConfig?.agreementsAccept,
                agreementsSignature: visitorWorkflowConfig?.agreementsSignature,
                agreementsEach: visitorWorkflowConfig?.agreementsEach,
                agreements: visitorAgreements.data
                    .filter(agreement => agreement.status === 1 && agreement.forSignIn)
                    .map(agreement => agreement.id)
            };
        }
        return {};
    }, [selectedVisitorWorkflow, visitorWorkflowConfig, visitorAgreements]);

    const handleSubmit = useCallback(({ values, touchedValues, initialValues }: IOnSubmitArgs<any>) => {
        const visitorWorkflowConfigParams = {
            agreementsDisplay: values.agreementsDisplay ? 1 : 0,
            agreementsAccept: values.agreementsAccept ? 1 : 0,
            agreementsSignature: values.agreementsSignature ? 1 : 0,
            agreementsEach: values.agreementsEach ? 1 : 0
        };

        if (selectedVisitorWorkflow) {
            dispatch(updateVisitorWorkflowConfig({ orgId, visitorWorkflowId: selectedVisitorWorkflow.id, visitorWorkflowConfigParams }));

            const agreementsToAdd = values.agreements.filter((id: number) => !initialValues?.agreements.includes(id));
            const agreementsToRemove = initialValues?.agreements.filter((id: number) => !values.agreements.includes(id));
            dispatch(bulkUpdateVisitorWorkflowAgreements({ orgId, visitorWorkflowId: selectedVisitorWorkflow.id, add: agreementsToAdd, remove: agreementsToRemove }));
        }
    }, [dispatch, orgId, selectedVisitorWorkflow]);

    const handleAgreementsDisplayChange = (checked: boolean) => {
        setIsAgreementsDisplayEnabled(checked);
        if (!checked) {
            form.setFieldsValue({
                agreementsAccept: false,
                agreementsSignature: false,
                agreementsEach: false
            });
        }
    };

    const handleRequireSignatureChange = (checked: boolean) => {
        if (subscriptions?.data[0]?.package?.code === "package-essential" ||
            subscriptions?.data[0]?.package?.code === "package-pro") {
            setFeatureUnavailableModalOpen(true);
            form.setFieldsValue({ agreementsSignature: visitorWorkflowConfig?.agreementsSignature });
        }
    };

    return (
        <>
            <OpForm
                form={form}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                hasError={false}
                defaultButtons={false}
                isReadOnly={!hasSigninconfWrite && hasSigninconfRead}
            >
                <List
                    header={<div>Agreements</div>}
                    bordered
                    dataSource={agreementsData}
                    renderItem={item => (
                        <List.Item>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <OpForm.Switch
                                    name={item.name}
                                    style={{ marginBottom: 0 }}
                                    onChange={
                                        item.name === 'agreementsDisplay'
                                            ? handleAgreementsDisplayChange
                                            : item.name === 'agreementsSignature'
                                                ? handleRequireSignatureChange
                                                : undefined
                                    }
                                    disabled={
                                        !isAgreementsDisplayEnabled &&
                                        (['agreementsAccept', 'agreementsSignature', 'agreementsEach'].includes(item.name) ||
                                            (!hasSigninconfWrite && hasSigninconfRead))
                                    }
                                />
                                <span style={{ marginLeft: 8 }}>{item.label}</span>
                            </div>
                        </List.Item>
                    )}
                >
                    <List.Item>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <OpForm.Item name="agreements" style={{ flex: 1, marginBottom: 0 }}>
                                <OpForm.Select
                                    loading={fetchAgreementsLoading}
                                    mode="multiple"
                                    placeholder="Select agreements"
                                    options={agreements.data.filter(agreement => agreement.forSignIn).map(agreement => ({
                                        label: agreement.name,
                                        value: agreement.id
                                    }))}
                                    disabled={!isAgreementsDisplayEnabled}
                                />
                            </OpForm.Item>
                        </div>
                    </List.Item>
                </List>
            </OpForm>

            <OpModal
                open={isFeatureUnavailableModalOpen}
                onCancel={() => setFeatureUnavailableModalOpen(false)}
                onOk={() => setFeatureUnavailableModalOpen(false)}
                title="Feature Not Included"
            >
                <p>Your account does not currently include this feature. Please contact your administrator.</p>
            </OpModal>
        </>
    );
};

export default AgreementsContent;
