import React, { useEffect, useState } from 'react';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpRow } from 'components/customAntd/DLS/OpRow/OpRow';
import { OpCol } from 'components/customAntd/DLS/OpCol/OpCol';
import dayjs from 'dayjs';
import { getStatusNameById, getVisitDateTime, getStatusColor } from 'utils/visitorsHelper';
import { DATE_TIME_AM_PM_FORMAT } from 'constants/dates';
import { formatFullName } from 'utils/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { getRequest } from 'api/apiClient';
import { FormFieldWorkflow, VisitData } from 'types/formFieldTypes';
import { OpDivider } from 'components/customAntd/DLS/OpDivider/OpDivider';

const DetailsTab: React.FC = () => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalLocationWorkflow = useSelector((state: RootState) => state.locations.globalLocationWorkflow);

    const { selectedVisit } = useSelector((state: RootState) => state.visits);

    const [statusColor, setStatusColor] = useState('transparent');
    const [customFieldsWorkflow, setCustomFieldsWorkflow] = useState<FormFieldWorkflow[]>([]);
    const [visitData, setVisitData] = useState<VisitData[]>([]);
    const status = getStatusNameById(selectedVisit?.visitStatus.id!, selectedVisit?.scheduleStart);

    useEffect(() => {
        setStatusColor(getStatusColor(status));
        // eslint-disable-next-line
    }, [selectedVisit]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const customFieldsWorkflow = await getRequest(`/orgs/${orgId}/visitorWorkflow/${globalLocationWorkflow?.SignInWorkflowId}/fieldCustom`);
                setCustomFieldsWorkflow(customFieldsWorkflow.data);

                const visitData = await getRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitorData`);
                // const filteredVisitData = visitData.data.filter((visitData: VisitData) => visitData.formField.status === 1);
                setVisitData(visitData.data);
            } catch (error) {
                console.log("Failed to fetch data.");
            }
        };
        fetchData();
    }, [orgId, selectedVisit?.id, globalLocationWorkflow?.SignInWorkflowId]);

    const initialValues: { [key: string]: any } = {
        company: selectedVisit?.company,
        visitorType: selectedVisit?.visitorType.name,
        visitStatus: getStatusNameById(selectedVisit?.visitStatus.id!, selectedVisit?.scheduleStart!),
        dateTime: (status === 'Pending' || status === 'No Show') ? '' : getVisitDateTime(selectedVisit, status, DATE_TIME_AM_PM_FORMAT),
        scheduleStart: selectedVisit?.scheduleStart ? dayjs(selectedVisit.scheduleStart).format(DATE_TIME_AM_PM_FORMAT) : undefined,
        scheduleEnd: selectedVisit?.scheduleEnd ? dayjs(selectedVisit.scheduleEnd).format(DATE_TIME_AM_PM_FORMAT) : undefined,
        site: selectedVisit?.site?.name,
        host: formatFullName(selectedVisit?.host.firstName || '', null, selectedVisit?.host.lastName || ''),
        purpose: selectedVisit?.purpose,
    };

    // Add visitData fields to initialValues
    visitData.forEach((data) => {
        const { formField, value } = data;
        initialValues[formField.id] = value;
    });

    const renderFormFields = () => {
        const customFieldIds = customFieldsWorkflow.map(field => field.id);

        return (
            <>
                {/* Render customFieldsWorkflow */}
                {customFieldsWorkflow.map((formField, index) => {
                    if (formField.values) {
                        const options = formField.values.map((option) => ({
                            label: option.display || option.name,
                            value: option.value,
                        }));

                        return (
                            <OpForm.Select
                                key={formField.id}
                                label={formField.name}
                                name={formField.id}
                                options={options}
                            />
                        );
                    } else {
                        return (
                            <OpForm.Input
                                key={formField.id}
                                label={formField.name}
                                name={formField.id}
                            />
                        );
                    }
                })}

                {/* Render visitData fields that are not present in customFieldsWorkflow */}
                {visitData
                    .filter(visitField => !customFieldIds.includes(visitField.formField.id)) // Fields not in customFieldsWorkflow
                    .map(visitField => {
                        const formField = visitField.formField;

                        if (formField.values) {
                            const options = formField.values.map((option) => ({
                                label: option.display || option.name,
                                value: option.value,
                            }));

                            return (
                                <OpForm.Select
                                    key={formField.id}
                                    label={formField.name}
                                    name={formField.id}
                                    options={options}
                                />
                            );
                        } else {
                            return (
                                <OpForm.Input
                                    key={formField.id}
                                    label={formField.name}
                                    name={formField.id}
                                />
                            );
                        }
                    })}
            </>
        );
    };

    return (
        <OpForm
            onSubmit={() => { }}
            hasError={false}
            defaultButtons={false}
            initialValues={initialValues}
            isReadOnly={true}
        >
            <OpRow gutter={16}>
                <OpCol span={12}>
                    <OpForm.Input label="Company" name="company" readOnly />
                </OpCol>
                <OpCol span={12}>
                    <OpForm.Input label="Visitor Type" name="visitorType" readOnly />
                </OpCol>
            </OpRow>

            <OpRow gutter={16}>
                <OpCol span={12}>
                    <OpForm.Input label="Status" name="visitStatus" readOnly
                        style={{
                            backgroundColor: statusColor,
                            color: 'white',
                            WebkitTextFillColor: 'white',
                            opacity: 1,
                        }} />
                </OpCol>
                <OpCol span={12}>
                    <OpForm.Input label="Date / Time" name="dateTime" readOnly />
                </OpCol>
            </OpRow>

            <OpRow gutter={16}>
                <OpCol span={12}>
                    <OpForm.Input label="Schedule Start Date / Time" name="scheduleStart" readOnly />
                </OpCol>
                <OpCol span={12}>
                    <OpForm.Input label="Schedule End Date / Time" name="scheduleEnd" readOnly />
                </OpCol>
            </OpRow>

            <OpRow gutter={16}>
                <OpCol span={12}>
                    <OpForm.Input label="Location" name="site" readOnly />
                </OpCol>
                <OpCol span={12}>
                    <OpForm.Input label="Host" name="host" readOnly />
                </OpCol>
            </OpRow>

            <OpForm.TextAreaInput label="Purpose" name="purpose" readOnly rows={4} />

            <OpDivider />
            {renderFormFields()}
            {/* {selectedVisit?.visitStatus.id === STATUS.PENDING.id ? undefined : (renderFormFields())} */}
        </OpForm>
    );
};

export default DetailsTab;
