import { FormInstance } from "antd";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { OpTable } from "components/customAntd/DLS/OpTable/OpTable";
import { OpTableRawColumnType } from "components/customAntd/DLS/OpTableCore/OpTableCore";
import { ConfigItem, InvitationConfig } from "types/visitorInvitationTypes";

interface FieldsFormProps {
    form: FormInstance;
    invitationConfig: InvitationConfig;
}

const FieldsForm: React.FC<FieldsFormProps> = ({ form, invitationConfig }) => {

    // Extract only the objects (ConfigItem) and turn them into an array of objects
    const configItemsArray = Object.keys(invitationConfig)
        .filter(key => typeof invitationConfig[key as keyof InvitationConfig] === 'object')
        .map(key => ({
            field: key,
            ...invitationConfig[key as keyof InvitationConfig] as ConfigItem
        }));

    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'field',
            label: 'Field',
            render: (text) => {
                const fieldName = text.replace(/([A-Z])/g, ' $1').replace(/^./, (str: string) => str.toUpperCase());
                return <span>{fieldName}</span>;
            }
        },
        {
            dataIndex: 'included',
            label: 'Enabled',
            render: (included: boolean, record) => {
                const handleEnabledChange = (checked: boolean) => {
                    form.setFieldsValue({
                        [record.field]: {
                            ...record,
                            included: checked,
                            required: checked ? form.getFieldValue([record.field, 'required']) : false
                        }
                    });
                };
                return (
                    <OpForm.Switch
                        name={[record.field, 'included']}
                        onChange={handleEnabledChange}
                        disabled={!record.configurable}
                    />
                );
            }
        },
        {
            dataIndex: 'required',
            label: 'Required',
            render: (included: boolean, record) => {
                const handleRequiredChange = (checked: boolean) => {
                    form.setFieldsValue({
                        [record.field]: {
                            ...record,
                            included: checked ? true : form.getFieldValue([record.field, 'included']),
                            required: checked
                        }
                    });
                };
                return (
                    <OpForm.Switch
                        name={[record.field, 'required']}
                        onChange={handleRequiredChange}
                        disabled={!record.configurable}
                    />
                );
            }
        }
    ];

    return (
        <OpTable
            label="INVITATION FIELDS"
            dataSource={configItemsArray}
            pagination={false}
            allowGlobalSearch={false}
            allowExport={false}
            allowShowHideColumns={false}
            columns={columns}
            style={{ marginBottom: '16px' }}
            rowKey="field"
        />
    );
};

export default FieldsForm;
