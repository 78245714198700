import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiResponse, ApiErrorResponse } from 'types/apiTypes';
import { ScopeResource } from 'types/scopeResourcesTypes';

interface ScopeResourcesState {
    scopeResources: ApiResponse<ScopeResource>;
    fetchScopeResourcesLoading: boolean;
    error: string | null;
}

const initialState: ScopeResourcesState = {
    scopeResources: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    fetchScopeResourcesLoading: false,
    error: null,
};

// Retrieve the base URL from environment variables
const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
    console.error('REACT_APP_BACKEND_URL is not set');
}

export const fetchScopeResources = createAsyncThunk<ApiResponse<ScopeResource>, { orgId: number }, { rejectValue: string }>(
    'scopeResources/fetchScopeResources',
    async ({ orgId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<ScopeResource>>(`${apiUrl}/orgs/${orgId}/scopeResources`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch scope resources');
        }
    }
);

const scopeResourcesSlice = createSlice({
    name: 'scopeResources',
    initialState,
    reducers: {
        clearScopeResources(state) {
            state.scopeResources = initialState.scopeResources;
        },
        clearScopeResourcesState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchScopeResources.pending, (state) => {
                state.fetchScopeResourcesLoading = true;
                state.error = null;
            })
            .addCase(fetchScopeResources.fulfilled, (state, action) => {
                state.fetchScopeResourcesLoading = false;
                state.scopeResources = action.payload;
            })
            .addCase(fetchScopeResources.rejected, (state, action) => {
                state.fetchScopeResourcesLoading = false;
                state.error = action.payload || 'Failed to fetch scope resources';
            });
    },
});

export const { clearScopeResources, clearScopeResourcesState } = scopeResourcesSlice.actions;
export default scopeResourcesSlice.reducer;
