import { VisitorType } from 'types/VisitorTypeTypes';

export const parseVisitorTypeResponse = (response: any): VisitorType => {
    return ({
        id: Number(response.id),
        name: response.name,
        languageCode: response.languageCode,
        global: response.global === '1' || response.global === 1
    })
};
