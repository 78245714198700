import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { hasPermission } from 'utils/utils';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { notification } from 'antd';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { deleteRequest } from 'api/apiClient';
import ProfileEditModal from './ProfileEditModal';
import { fetchVisits, fetchVisitsToday } from 'store/slices/visitsSlice';

interface ProfileDrawerHeaderProps {
    onClose: () => void;
}

const ProfileDrawerHeader: React.FC<ProfileDrawerHeaderProps> = ({ onClose }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const { selectedVisit, selectedVisitor } = useSelector((state: RootState) => state.visits);

    const hasAllvisitorsWrite = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:w');
    const hasInviteWrite = hasPermission(tokenScopeList, orgId, 'o', 'invite:w');
    const hasSigninWrite = hasPermission(tokenScopeList, orgId, 'o', 'signin:w');
    const hasSignoutWrite = hasPermission(tokenScopeList, orgId, 'o', 'signout:w');

    const [isProfileEditModalOpen, setIsProfileEditModalOpen] = useState(false);

    const confirmModal = useConfirmModal();

    const onDeleteClick = () => {
        confirmModal({
            title: 'Delete Visitor',
            content: 'Are you sure you want to delete this visitor? This can not be undone.',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                try {
                    if (selectedVisit && selectedVisit.visitors.length > 1) {
                        await deleteRequest(`/orgs/${orgId}/visitor/${selectedVisit.id}/visitors/${selectedVisitor?.id}`);
                        notification.success({
                            message: 'Success',
                            description: 'Visitor deleted successfully.',
                            placement: 'bottomRight',
                        });
                        dispatch(fetchVisits({ orgId }));
                        dispatch(fetchVisitsToday({ orgId }));
                        onClose();
                    } else {
                        notification.error({
                            message: 'Error',
                            description: 'There must be at least one visitor in a visit.',
                            placement: 'bottomRight',
                        });
                    }
                } catch (error) {
                    console.log("Failed to delete visitor.");
                }
            },
        });
    };

    return (
        <>
            <OpSpace>
                {((selectedVisitor?.status === 1 && hasInviteWrite) ||
                    (selectedVisitor?.status === 2 && hasSigninWrite) ||
                    (selectedVisitor?.status === 3 && hasSignoutWrite)) &&
                    (hasAllvisitorsWrite || (globalUserId === selectedVisit?.host.userId)) && (
                        <>
                            {selectedVisitor.status === 1 && (
                                <OpButton type="primary" danger ghost onClick={onDeleteClick}>
                                    Delete
                                </OpButton>
                            )}
                            <OpButton type="primary" ghost onClick={() => setIsProfileEditModalOpen(true)}>
                                Edit
                            </OpButton>
                        </>
                    )}
            </OpSpace>
            {(isProfileEditModalOpen) && (
                <ProfileEditModal
                    open={isProfileEditModalOpen}
                    onClose={() => setIsProfileEditModalOpen(false)}
                />
            )}
        </>
    );
}

export default ProfileDrawerHeader;