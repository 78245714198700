import apiClient from "./apiService";

export const getRequest = async (url: string, params = {}, config = {}) => {
    try {
        const response = await apiClient.get(url, {
            params,
            ...config,
        });
        return response.data;
    } catch (error) {
        console.error('GET request error:', error);
        throw error;
    }
};

export const postRequest = async (url: string, data: object) => {
    try {
        const response = await apiClient.post(url, data);
        return response.data;
    } catch (error) {
        console.error('POST request error:', error);
        throw error;
    }
};

export const patchRequest = async (url: string, data: object) => {
    try {
        const response = await apiClient.patch(url, data);
        return response.data;
    } catch (error) {
        console.error('PATCH request error:', error);
        throw error;
    }
};

export const deleteRequest = async (url: string) => {
    try {
        const response = await apiClient.delete(url);
        return response.data;
    } catch (error) {
        console.error('DELETE request error:', error);
        throw error;
    }
};
