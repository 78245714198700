import { createSlice } from '@reduxjs/toolkit';

interface GuestPassState {
    triggerUpdate: boolean;
}

const initialState: GuestPassState = {
    triggerUpdate: false,
};

const guestPassSlice = createSlice({
    name: 'guestPass',
    initialState,
    reducers: {
        triggerGuestPassUpdate(state) {
            state.triggerUpdate = !state.triggerUpdate; // Toggles the value to notify listeners
        },
        resetGuestPassUpdate(state) {
            state.triggerUpdate = false; // Reset to default state if needed
        },
    },
});

export const { triggerGuestPassUpdate, resetGuestPassUpdate } = guestPassSlice.actions;
export default guestPassSlice.reducer;
