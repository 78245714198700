import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ApiResponse, ApiErrorResponse } from 'types/apiTypes';
import { Agreement } from 'types/agreementTypes';
import { Location } from 'types/locationTypes';
import {
    // VisitorWorkflowType,
    VisitorWorkflow,
    VisitorWorkflowConfig,
    VisitorWorkflowFields,
    CreateVisitorWorkflowPayload,
    UpdateVisitorWorkflowPayload,
    UpdateVisitorWorkflowConfig,
    UpdateVisitorWorkflowFields,
} from 'types/visitorWorkflowTypes';

interface VisitorWorkflowsState {
    visitorWorkflows: ApiResponse<VisitorWorkflow>;
    selectedVisitorWorkflow: VisitorWorkflow | null;
    selectedVisitorWorkflowLocations: ApiResponse<Location>;
    selectedSignInWorkflowConfig: VisitorWorkflowConfig | null;
    selectedSignInWorkflowFields: VisitorWorkflowFields | null;
    globalSignInWorkflowConfig: VisitorWorkflowConfig | null;
    globalSignInWorkflowFields: VisitorWorkflowFields | null;
    visitorAgreements: ApiResponse<Agreement>;
    fetchVisitorWorkflowsLoading: boolean;
    describeVisitorWorkflowLoading: boolean;
    createVisitorWorkflowLoading: boolean;
    updateVisitorWorkflowLoading: boolean;
    deleteVisitorWorkflowLoading: boolean;
    describeVisitorWorkflowLocationsLoading: boolean;
    describeVisitorWorkflowConfigLoading: boolean;
    updateVisitorWorkflowConfigLoading: boolean;
    describeVisitorWorkflowFieldsLoading: boolean;
    updateVisitorWorkflowFieldsLoading: boolean;
    fetchVisitorAgreementsLoading: boolean;
    bulkUpdateVisitorAgreementsLoading: boolean;
    error: string | null;
}

const initialState: VisitorWorkflowsState = {
    visitorWorkflows: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    selectedVisitorWorkflow: null,
    selectedVisitorWorkflowLocations: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    selectedSignInWorkflowConfig: null,
    selectedSignInWorkflowFields: null,
    globalSignInWorkflowConfig: null,
    globalSignInWorkflowFields: null,
    visitorAgreements: {
        data: [],
        meta: null,
        totalCount: 0,
        filteredCount: 0,
    },
    fetchVisitorWorkflowsLoading: false,
    describeVisitorWorkflowLoading: false,
    createVisitorWorkflowLoading: false,
    updateVisitorWorkflowLoading: false,
    deleteVisitorWorkflowLoading: false,
    describeVisitorWorkflowLocationsLoading: false,
    describeVisitorWorkflowConfigLoading: false,
    updateVisitorWorkflowConfigLoading: false,
    describeVisitorWorkflowFieldsLoading: false,
    updateVisitorWorkflowFieldsLoading: false,
    fetchVisitorAgreementsLoading: false,
    bulkUpdateVisitorAgreementsLoading: false,
    error: null,
};

// Retrieve the base URL from environment variables
const apiUrl = process.env.REACT_APP_BACKEND_URL;

if (!apiUrl) {
    console.error('REACT_APP_BACKEND_URL is not set');
}

export const fetchVisitorWorkflows = createAsyncThunk<ApiResponse<VisitorWorkflow>, { orgId: number, workflowTypeId?: number }, { rejectValue: string }>(
    'visitorWorkflows/fetchVisitorWorkflows',
    async ({ orgId, workflowTypeId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<VisitorWorkflow>>(`${apiUrl}/orgs/${orgId}/visitorWorkflow`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
                params: {
                    workflowTypeId
                }
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch visitor workflows');
        }
    }
);

export const describeVisitorWorkflow = createAsyncThunk<ApiResponse<VisitorWorkflow>, { orgId: number, visitorWorkflowId: number, workflowTypeId?: number }, { rejectValue: string }>(
    'visitorWorkflows/describeVisitorWorkflow',
    async ({ orgId, visitorWorkflowId, workflowTypeId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<VisitorWorkflow>>(`${apiUrl}/orgs/${orgId}/visitorWorkflow/${visitorWorkflowId}`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
                params: {
                    workflowTypeId
                }
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to describe visitor workflow');
        }
    }
);

export const createVisitorWorkflow = createAsyncThunk<void, { orgId: number, visitorWorkflow: CreateVisitorWorkflowPayload }, { dispatch: any; rejectValue: string }>(
    'visitorWorkflows/createVisitorWorkflow',
    async ({ orgId, visitorWorkflow }, { dispatch, rejectWithValue }) => {
        try {
            await axios.post(`${apiUrl}/orgs/${orgId}/visitorWorkflow`, visitorWorkflow, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                }
            });
            dispatch(fetchVisitorWorkflows({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to create visitor workflow');
        }
    }
);

export const updateVisitorWorkflow = createAsyncThunk<void, { orgId: number, visitorWorkflowId: number, visitorWorkflow: UpdateVisitorWorkflowPayload }, { dispatch: any; rejectValue: string }>(
    'visitorWorkflows/updateVisitorWorkflow',
    async ({ orgId, visitorWorkflowId, visitorWorkflow }, { dispatch, rejectWithValue }) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/visitorWorkflow/${visitorWorkflowId}`, visitorWorkflow, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                }
            });
            dispatch(fetchVisitorWorkflows({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update visitor workflow');
        }
    }
);

export const deleteVisitorWorkflow = createAsyncThunk<void, { orgId: number, visitorWorkflowId: number }, { dispatch: any; rejectValue: string }>(
    'visitorWorkflows/deleteVisitorWorkflow',
    async ({ orgId, visitorWorkflowId }, { dispatch, rejectWithValue }) => {
        try {
            await axios.delete(`${apiUrl}/orgs/${orgId}/visitorWorkflow/${visitorWorkflowId}`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                }
            });
            dispatch(fetchVisitorWorkflows({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to delete visitor workflow');
        }
    }
);

export const describeVisitorWorkflowLocations = createAsyncThunk<ApiResponse<Location>, { orgId: number, visitorWorkflowId: number }, { rejectValue: string }>(
    'visitorWorkflows/describeVisitorWorkflowLocations',
    async ({ orgId, visitorWorkflowId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<Location>>(`${apiUrl}/orgs/${orgId}/visitorWorkflow/${visitorWorkflowId}/sites`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to describe visitor workflow locations');
        }
    }
);

export const describeVisitorWorkflowConfig = createAsyncThunk<ApiResponse<VisitorWorkflowConfig>, { orgId: number, visitorWorkflowId: number, global?: boolean }, { rejectValue: string }>(
    'visitorWorkflows/describeVisitorWorkflowConfig',
    async ({ orgId, visitorWorkflowId, global }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<VisitorWorkflowConfig>>(`${apiUrl}/orgs/${orgId}/visitorWorkflowConfig/${visitorWorkflowId}`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                }
            });
            return { ...response.data, global };
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to describe visitor workflow config');
        }
    }
);

export const updateVisitorWorkflowConfig = createAsyncThunk<void, { orgId: number, visitorWorkflowId: number, visitorWorkflowConfigParams: Partial<UpdateVisitorWorkflowConfig> }, { dispatch: any; rejectValue: string, state: { visitorWorkflows: VisitorWorkflowsState } }>(
    'visitorWorkflows/updateVisitorWorkflowConfig',
    async ({ orgId, visitorWorkflowId, visitorWorkflowConfigParams }, { dispatch, rejectWithValue, getState }) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/visitorWorkflowConfig/${visitorWorkflowId}`, visitorWorkflowConfigParams, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                }
            });
            dispatch(describeVisitorWorkflowConfig({ orgId, visitorWorkflowId }));

            const { globalSignInWorkflowConfig } = getState().visitorWorkflows;
            if (visitorWorkflowId === globalSignInWorkflowConfig?.id) {
                dispatch(describeVisitorWorkflowConfig({ orgId, visitorWorkflowId, global: true }));
            }
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update visitor workflow config');
        }
    }
);

export const describeVisitorWorkflowFields = createAsyncThunk<ApiResponse<VisitorWorkflowFields>, { orgId: number, visitorWorkflowId: number, global?: boolean }, { rejectValue: string }>(
    'visitorWorkflows/describeVisitorWorkflowFields',
    async ({ orgId, visitorWorkflowId, global }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<VisitorWorkflowFields>>(`${apiUrl}/orgs/${orgId}/visitorWorkflow/${visitorWorkflowId}/fieldConfig`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                }
            });
            return { ...response.data, global };
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to describe visitor workflow fields');
        }
    }
);

export const updateVisitorWorkflowFields = createAsyncThunk<void, { orgId: number, visitorWorkflowId: number, visitorWorkflowFields: Partial<UpdateVisitorWorkflowFields> }, { dispatch: any; rejectValue: string, state: { visitorWorkflows: VisitorWorkflowsState } }>(
    'visitorWorkflows/updateVisitorWorkflowFields',
    async ({ orgId, visitorWorkflowId, visitorWorkflowFields }, { dispatch, rejectWithValue, getState }) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/visitorWorkflow/${visitorWorkflowId}/fieldConfig`, visitorWorkflowFields, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                }
            });
            dispatch(describeVisitorWorkflowFields({ orgId, visitorWorkflowId }));

            const { globalSignInWorkflowConfig } = getState().visitorWorkflows;
            if (visitorWorkflowId === globalSignInWorkflowConfig?.id) {
                dispatch(describeVisitorWorkflowFields({ orgId, visitorWorkflowId, global: true }));
            }
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update visitor workflow fields');
        }
    }
);

export const fetchVisitorWorkflowAgreements = createAsyncThunk<ApiResponse<Agreement>, { orgId: number; visitorWorkflowId: number }, { rejectValue: string }>(
    'visitorWorkflows/fetchVisitorWorkflowAgreements',
    async ({ orgId, visitorWorkflowId }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ApiResponse<Agreement>>(`${apiUrl}/orgs/${orgId}/visitorWorkflow/${visitorWorkflowId}/agreements`, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to fetch visitor workflow agreements');
        }
    }
);

export const bulkUpdateVisitorWorkflowAgreements = createAsyncThunk<void, { orgId: number; visitorWorkflowId: number; add: number[]; remove: number[] }, { dispatch: any; rejectValue: string }>(
    'visitorWorkflows/bulkUpdateVisitorWorkflowAgreements',
    async ({ orgId, visitorWorkflowId, add, remove }, { dispatch, rejectWithValue }) => {
        try {
            await axios.patch(`${apiUrl}/orgs/${orgId}/visitorWorkflow/${visitorWorkflowId}/agreements`, { add, remove }, {
                headers: {
                    Authorization: `${localStorage.getItem('authToken')}`,
                    Accept: 'application/json',
                },
            });
            dispatch(describeVisitorWorkflow({ orgId, visitorWorkflowId }));
            dispatch(fetchVisitorWorkflows({ orgId }));
        } catch (error) {
            const axiosError = error as AxiosError<ApiErrorResponse>;
            return rejectWithValue(axiosError.response?.data.message || 'Failed to update visitor workflow agreements');
        }
    }
);

const visitorWorkflowsSlice = createSlice({
    name: 'visitorWorkflows',
    initialState,
    reducers: {
        clearVisitorWorkflows(state) {
            state.visitorWorkflows = initialState.visitorWorkflows;
        },
        clearSelectedVisitorWorkflow(state) {
            state.selectedVisitorWorkflow = initialState.selectedVisitorWorkflow;
        },
        clearVisitorWorkflowLocations(state) {
            state.selectedVisitorWorkflowLocations = initialState.selectedVisitorWorkflowLocations;
        },
        clearVisitorWorkflowConfig(state) {
            state.selectedSignInWorkflowConfig = initialState.selectedSignInWorkflowConfig;
        },
        clearVisitorWorkflowsState() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchVisitorWorkflows.pending, (state) => {
                state.fetchVisitorWorkflowsLoading = true;
                state.error = null;
            })
            .addCase(fetchVisitorWorkflows.fulfilled, (state, action) => {
                state.fetchVisitorWorkflowsLoading = false;
                state.visitorWorkflows = action.payload;
            })
            .addCase(fetchVisitorWorkflows.rejected, (state, action) => {
                state.fetchVisitorWorkflowsLoading = false;
                state.error = action.payload || 'Failed to fetch visitor workflows';
            })
            .addCase(describeVisitorWorkflow.pending, (state) => {
                state.describeVisitorWorkflowLoading = true;
                state.error = null;
            })
            .addCase(describeVisitorWorkflow.fulfilled, (state, action) => {
                state.describeVisitorWorkflowLoading = false;
                state.selectedVisitorWorkflow = action.payload.data[0];
            })
            .addCase(describeVisitorWorkflow.rejected, (state, action) => {
                state.describeVisitorWorkflowLoading = false;
                state.error = action.payload || 'Failed to describe visitor workflow';
            })
            .addCase(createVisitorWorkflow.pending, (state) => {
                state.createVisitorWorkflowLoading = true;
                state.error = null;
            })
            .addCase(createVisitorWorkflow.fulfilled, (state) => {
                state.createVisitorWorkflowLoading = false;
            })
            .addCase(createVisitorWorkflow.rejected, (state, action) => {
                state.createVisitorWorkflowLoading = false;
                state.error = action.payload || 'Failed to create visitor workflow';
            })
            .addCase(updateVisitorWorkflow.pending, (state) => {
                state.updateVisitorWorkflowLoading = true;
                state.error = null;
            })
            .addCase(updateVisitorWorkflow.fulfilled, (state) => {
                state.updateVisitorWorkflowLoading = false;
            })
            .addCase(updateVisitorWorkflow.rejected, (state, action) => {
                state.updateVisitorWorkflowLoading = false;
                state.error = action.payload || 'Failed to update visitor workflow';
            })
            .addCase(deleteVisitorWorkflow.pending, (state) => {
                state.deleteVisitorWorkflowLoading = true;
                state.error = null;
            })
            .addCase(deleteVisitorWorkflow.fulfilled, (state) => {
                state.deleteVisitorWorkflowLoading = false;
            })
            .addCase(deleteVisitorWorkflow.rejected, (state, action) => {
                state.deleteVisitorWorkflowLoading = false;
                state.error = action.payload || 'Failed to delete visitor workflow';
            })
            .addCase(describeVisitorWorkflowLocations.pending, (state) => {
                state.describeVisitorWorkflowLocationsLoading = true;
                state.error = null;
            })
            .addCase(describeVisitorWorkflowLocations.fulfilled, (state, action) => {
                state.describeVisitorWorkflowLocationsLoading = false;
                state.selectedVisitorWorkflowLocations = action.payload;
            })
            .addCase(describeVisitorWorkflowLocations.rejected, (state, action) => {
                state.describeVisitorWorkflowLocationsLoading = false;
                state.error = action.payload || 'Failed to describe visitor workflow locations';
            })
            .addCase(describeVisitorWorkflowConfig.pending, (state) => {
                state.describeVisitorWorkflowConfigLoading = true;
                state.error = null;
            })
            .addCase(describeVisitorWorkflowConfig.fulfilled, (state, action) => {
                if (action.payload.global) {
                    state.globalSignInWorkflowConfig = action.payload.data[0];
                } else {
                    state.selectedSignInWorkflowConfig = action.payload.data[0];
                }
                state.describeVisitorWorkflowConfigLoading = false;
            })
            .addCase(describeVisitorWorkflowConfig.rejected, (state, action) => {
                state.describeVisitorWorkflowConfigLoading = false;
                state.error = action.payload || 'Failed to describe visitor workflow config';
            })
            .addCase(updateVisitorWorkflowConfig.pending, (state) => {
                state.updateVisitorWorkflowConfigLoading = true;
                state.error = null;
            })
            .addCase(updateVisitorWorkflowConfig.fulfilled, (state) => {
                state.updateVisitorWorkflowConfigLoading = false;
            })
            .addCase(updateVisitorWorkflowConfig.rejected, (state, action) => {
                state.updateVisitorWorkflowConfigLoading = false;
                state.error = action.payload || 'Failed to update visitor workflow config';
            })
            .addCase(describeVisitorWorkflowFields.pending, (state) => {
                state.describeVisitorWorkflowFieldsLoading = true;
                state.error = null;
            })
            .addCase(describeVisitorWorkflowFields.fulfilled, (state, action) => {
                if (action.payload.global) {
                    state.globalSignInWorkflowFields = action.payload.data[0];
                } else {
                    state.selectedSignInWorkflowFields = action.payload.data[0];
                }
                state.describeVisitorWorkflowFieldsLoading = false;
            })
            .addCase(describeVisitorWorkflowFields.rejected, (state, action) => {
                state.describeVisitorWorkflowFieldsLoading = false;
                state.error = action.payload || 'Failed to describe visitor workflow fields';
            })
            .addCase(updateVisitorWorkflowFields.pending, (state) => {
                state.updateVisitorWorkflowFieldsLoading = true;
                state.error = null;
            })
            .addCase(updateVisitorWorkflowFields.fulfilled, (state) => {
                state.updateVisitorWorkflowFieldsLoading = false;
            })
            .addCase(updateVisitorWorkflowFields.rejected, (state, action) => {
                state.updateVisitorWorkflowFieldsLoading = false;
                state.error = action.payload || 'Failed to update visitor workflow fields';
            })
            .addCase(fetchVisitorWorkflowAgreements.pending, (state) => {
                state.fetchVisitorAgreementsLoading = true;
                state.error = null;
            })
            .addCase(fetchVisitorWorkflowAgreements.fulfilled, (state, action) => {
                state.fetchVisitorAgreementsLoading = false;
                state.visitorAgreements = action.payload;
            })
            .addCase(fetchVisitorWorkflowAgreements.rejected, (state, action) => {
                state.fetchVisitorAgreementsLoading = false;
                state.error = action.payload || 'Failed to fetch visitor workflow agreements';
            })
            .addCase(bulkUpdateVisitorWorkflowAgreements.pending, (state) => {
                state.bulkUpdateVisitorAgreementsLoading = true;
                state.error = null;
            })
            .addCase(bulkUpdateVisitorWorkflowAgreements.fulfilled, (state) => {
                state.bulkUpdateVisitorAgreementsLoading = false;
            })
            .addCase(bulkUpdateVisitorWorkflowAgreements.rejected, (state, action) => {
                state.bulkUpdateVisitorAgreementsLoading = false;
                state.error = action.payload || 'Failed to update visitor workflow agreements';
            });
    },
});

export const { clearVisitorWorkflows, clearSelectedVisitorWorkflow, clearVisitorWorkflowLocations, clearVisitorWorkflowConfig, clearVisitorWorkflowsState } = visitorWorkflowsSlice.actions;
export default visitorWorkflowsSlice.reducer;
