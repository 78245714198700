import React, { useCallback, useMemo, useState } from 'react';
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { Button, Modal, Input, Checkbox, message } from 'antd';
import { createRedFlagData, updateRedFlagData } from 'store/slices/redFlagSlice';
import { hasPermission } from 'utils/utils';

const { Group: CheckboxGroup } = Checkbox;

interface DataContentProps {
    form: any;
}

const DataContent: React.FC<DataContentProps> = ({ form }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { selectedRedFlag, redFlagData } = useSelector((state: RootState) => state.redFlags);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isCustomModalVisible, setIsCustomModalVisible] = useState(false);
    const [customLabel, setCustomLabel] = useState('');
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [customFields, setCustomFields] = useState<{ id: number, label: string, value: string }[]>([]);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasRedflagRead = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:r');
    const hasRedflagWrite = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:w');

    const initialValues = useMemo(() => {
        const values: any = {};
        if (redFlagData) {
            redFlagData.forEach((item) => {
                if (item.data) {
                    values[`${item.data}_${item.id}`] = item.value;
                }
            });
        }
        return values;
    }, [redFlagData]);

    const handleSubmit = useCallback(async ({ values, touchedValues }: IOnSubmitArgs<any>) => {
        if (selectedRedFlag) {
            for (const key of Object.keys(touchedValues)) {
                const originalKey = key.split('_')[0];
                const existingData = redFlagData.find(d => `${d.data}_${d.id}` === key);

                if (existingData) {
                    if (values[key] !== "") {
                        await dispatch(updateRedFlagData({
                            orgId: orgId,
                            redFlagId: selectedRedFlag.id,
                            dataId: existingData.id,
                            redFlagData: {
                                name: originalKey,
                                value: values[key]
                            }
                        }));
                    }
                } else {
                    if (values[key] !== "") {
                        await dispatch(createRedFlagData({
                            orgId: orgId,
                            redFlagId: selectedRedFlag?.id,
                            redFlagData: {
                                name: originalKey,
                                value: values[key]
                            }
                        }));
                    }
                }
            }
        }
        setCustomFields([]);
    }, [dispatch, redFlagData, orgId, selectedRedFlag]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        const nonCustomOptions = selectedOptions.filter(option => option !== 'custom');

        if (nonCustomOptions.length > 0) {
            const newFields = nonCustomOptions.map(option => ({
                id: Date.now() + Math.random(),
                label: option,
                value: ""
            }));

            setCustomFields(prevFields => [...prevFields, ...newFields]);
        }

        if (selectedOptions.includes('custom')) {
            setIsCustomModalVisible(true);
        }

        setSelectedOptions([]);
        setIsModalVisible(false);
    };

    const handleCustomModalOk = () => {
        if (!customLabel.trim()) {
            message.warning("Custom field name cannot be empty.");
            return;
        }

        setCustomFields(prevFields => [
            ...prevFields,
            { id: Date.now() + Math.random(), label: customLabel, value: "" }
        ]);

        setCustomLabel('');
        setIsCustomModalVisible(false);
    };

    const handleCancel = () => {
        setSelectedOptions([]);
        setIsModalVisible(false);
    };

    const handleCustomModalCancel = () => {
        setCustomLabel('');
        setIsCustomModalVisible(false);
    };

    const handleCheckboxChange = (checkedValues: any) => {
        setSelectedOptions(checkedValues);
    };

    const availableOptions = useMemo(() => {
        const predefinedOptions = [
            'Address',
            'City',
            'State',
            'Alias',
            'Phone Number',
            'ID Type',
            'ID Number'
        ];

        return predefinedOptions;
    }, []);

    return (
        <>
            <OpForm
                form={form}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                hasError={false}
                defaultButtons={false}
                isReadOnly={!hasRedflagWrite && hasRedflagRead}
            >
                {redFlagData && redFlagData.map((item) => (
                    <OpForm.Input
                        key={`${item.data}_${item.id}`} // Use the same key as in initialValues
                        label={item.data}
                        name={`${item.data}_${item.id}`} // Use the same name as in initialValues
                    />
                ))}
                {customFields.map((field) => (
                    <OpForm.Input
                        key={`${field.label}_${field.id}`} // Keep the key consistent
                        label={field.label}
                        name={`${field.label}_${field.id}`} // Keep the name consistent
                    />
                ))}
                {hasRedflagWrite && (
                    <Button type="dashed" onClick={showModal}>
                        Add Custom Field
                    </Button>
                )}
            </OpForm>
            <Modal title="Select Fields" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={300} centered>
                <CheckboxGroup
                    onChange={handleCheckboxChange}
                    value={selectedOptions}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                >
                    {availableOptions.map(option => (
                        <Checkbox key={option} value={option} style={{ marginBottom: '8px' }}>
                            {option}
                        </Checkbox>
                    ))}
                    <Checkbox value="custom" style={{ marginBottom: '8px' }}>Custom</Checkbox>
                </CheckboxGroup>
            </Modal>

            <Modal title="Enter Custom Field Name" open={isCustomModalVisible} onOk={handleCustomModalOk} onCancel={handleCustomModalCancel} centered>
                <Input
                    placeholder="Enter custom field name"
                    value={customLabel}
                    onChange={(e) => setCustomLabel(e.target.value)}
                />
            </Modal>
        </>
    );
};

export default DataContent;
