import React, { useCallback } from 'react';
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { notification } from 'antd';
import { changePassword } from 'store/slices/authSlice';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input } from 'antd';

interface InfoContentProps {
    form: any;
    onClose: () => void;
}

const CombinedFormContent: React.FC<InfoContentProps> = ({ form, onClose }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { globalUser } = useSelector((state: RootState) => state.users);

    const handleSubmit = useCallback(
        async ({ values }: IOnSubmitArgs<any>) => {
            if (globalUser?.id) {
                const { currentPassword, newPassword } = values;
                try {
                    await dispatch(changePassword({
                        orgId: orgId,
                        userId: globalUser.id,
                        payload: { currentPassword, newPassword }
                    })).unwrap();
                    notification.success({
                        message: 'Success',
                        description: 'Your password has been successfully changed.',
                        placement: 'bottomRight',
                    });
                    onClose();
                } catch (error: any) {
                    notification.error({
                        message: 'Error',
                        description: error.message || 'Failed to change password.',
                        placement: 'bottomRight',
                    });
                }
            }
        },
        [dispatch, orgId, globalUser, onClose]
    );

    return (
        <OpForm
            form={form}
            onSubmit={handleSubmit}
            hasError={false}
        >
            <OpForm.Item label="Current Password" name='currentPassword' rules={[{ required: true, message: 'Current password is required' }]}>
                <Input.Password
                    placeholder="Enter current password"
                    style={{ width: '100%' }}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
            </OpForm.Item>

            <OpForm.Item
                label="New Password"
                name='newPassword'
                rules={[
                    { required: true, message: 'New password is required' },
                    { min: 6, message: 'New password must be at least 6 characters' }
                ]}
            >
                <Input.Password
                    placeholder="Enter new password"
                    style={{ width: '100%' }}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
            </OpForm.Item>
        </OpForm>
    );
};

export default CombinedFormContent;
