import React, { useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import OrganizationTab from './tabs/OrganizationTab';
import LicenseTab from './tabs/LicenseTab';
import { ACCOUNT_TOOLTIP } from 'constants/tooltip';

const Account: React.FC = () => {
    const [activeKey, setActiveKey] = useState<string>('organization');

    const tabItems = [{
        key: 'organization',
        label: 'Organization',
        children: <OrganizationTab />,
    },
    {
        key: 'license',
        label: 'License',
        children: <LicenseTab />,
    },
    ];

    return (
        <OpPage title="Account" tooltip={ACCOUNT_TOOLTIP} >
            <OpTabs activeKey={activeKey} onChange={setActiveKey} items={tabItems} />
        </OpPage>
    );
}

export default Account;