import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { hasPermission } from 'utils/utils';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { notification } from 'antd';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import VisitorsEditModal from './VisitorsEditModal';
import { patchRequest } from 'api/apiClient';
import { fetchVisits } from 'store/slices/visitsSlice';
import STATUS from 'constants/status';

interface VisitorsDrawerHeaderProps {
    onClose: () => void;
    setLoading: (loading: boolean) => void;
}

const VisitorsDrawerHeader: React.FC<VisitorsDrawerHeaderProps> = ({ onClose, setLoading }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const { selectedVisit } = useSelector((state: RootState) => state.visits);

    const hasAllvisitorsWrite = hasPermission(tokenScopeList, orgId, 'o', 'allvisitors:w');
    const hasInviteWrite = hasPermission(tokenScopeList, orgId, 'o', 'invite:w');
    const hasSigninWrite = hasPermission(tokenScopeList, orgId, 'o', 'signin:w');
    const hasSignoutWrite = hasPermission(tokenScopeList, orgId, 'o', 'signout:w');
    const hasDenyWrite = hasPermission(tokenScopeList, orgId, 'o', 'deny:w');

    const [isVisitorsEditModalOpen, setIsVisitorsEditModalOpen] = useState(false);

    const confirmModal = useConfirmModal();

    const onDeleteClick = () => {
        confirmModal({
            title: 'Delete Visit',
            content: 'Are you sure you want to delete this visit? This can not be undone.',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                try {
                    await patchRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}`, {
                        status: 0
                    });
                    await dispatch(fetchVisits({ orgId }));
                    notification.success({
                        message: 'Success',
                        description: 'Visit deleted successfully.',
                        placement: 'bottomRight',
                    });
                    onClose();
                } catch (error) {
                    console.log("Failed to delete visit.");
                }
            },
        });
    };

    return (
        <>
            <OpSpace>
                {((selectedVisit?.visitStatus.id === STATUS.PENDING.id && hasInviteWrite) ||
                    (selectedVisit?.visitStatus.id === STATUS.SIGNED_IN.id && hasSigninWrite) ||
                    (selectedVisit?.visitStatus.id === STATUS.SIGNED_OUT.id && hasSignoutWrite) ||
                    (selectedVisit?.visitStatus.id === STATUS.DENIED_ENTRY.id && hasDenyWrite)) &&
                    (hasAllvisitorsWrite || (globalUserId === selectedVisit.host.userId)) && (
                        <>
                            {selectedVisit.visitStatus.id === 1 && selectedVisit.visitors.every(visitor => visitor.status === 1) && (
                                <OpButton type="primary" danger ghost onClick={onDeleteClick}>
                                    Delete
                                </OpButton>
                            )}
                            <OpButton type="primary" ghost onClick={() => setIsVisitorsEditModalOpen(true)}>
                                Edit
                            </OpButton>
                        </>
                    )}
            </OpSpace>
            {(isVisitorsEditModalOpen) && (
                <VisitorsEditModal
                    open={isVisitorsEditModalOpen}
                    onClose={() => setIsVisitorsEditModalOpen(false)}
                    setLoading={setLoading}
                />
            )}
        </>
    );
}

export default VisitorsDrawerHeader;