import React, { useCallback, useEffect, useState } from 'react';
import { DRAWER_WIDTH } from 'constants/ui';
import { OpFormDrawer } from 'components/customAntd/DLS/OpFormDrawer/OpFormDrawer';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { getRequest, patchRequest, postRequest } from 'api/apiClient';
import { Location } from 'types/locationTypes';
import { VisitorType } from 'types/VisitorTypeTypes';
import { RedFlag } from 'types/redFlagTypes';
import { User } from 'types/userTypes';
import { formatFullName, transformNullToEmptyString } from 'utils/utils';
import VisitorList from '../formComponents/VisitorList';
import { notification } from 'antd';
import { VisitorInvite } from 'types/visitorInvitationTypes';
import STATUS from 'constants/status';
import { DATE_TIME_FORMAT } from 'constants/dates';
import dayjs from 'dayjs';
import { SIGNED_IN_VISITOR_ID } from 'constants/userActivities';
import { fetchVisits, fetchVisitsToday } from 'store/slices/visitsSlice';
import { describeVisitorWorkflowFields } from 'store/slices/visitorWorkflowsSlice';
import { FormFieldWorkflow } from 'types/formFieldTypes';
import { OpDivider } from 'components/customAntd/DLS/OpDivider/OpDivider';

interface EnterManuallyComponentProps {
    open: boolean;
    onClose: () => void;
    newVisitorName?: {
        firstName: string;
        middleName: string;
        lastName: string;
    };
    setNewVisitorName: (filter: { firstName: string; middleName: string; lastName: string } | undefined) => void;
}

const SignInManuallyDrawer: React.FC<EnterManuallyComponentProps> = ({ open, onClose, newVisitorName, setNewVisitorName }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const globalLocationWorkflow = useSelector((state: RootState) => state.locations.globalLocationWorkflow);
    const { globalSignInWorkflowFields } = useSelector((state: RootState) => state.visitorWorkflows);

    const [locations, setLocations] = useState<Location[]>([]);
    const [hosts, setHosts] = useState<User[]>([]);
    const [redFlags, setRedFlags] = useState<RedFlag[]>([]);
    const [visitorTypes, setVisitorTypes] = useState<VisitorType[]>([]);
    const [customFieldsWorkflow, setCustomFieldsWorkflow] = useState<FormFieldWorkflow[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [form] = OpForm.useForm();

    const initialValues = {
        siteId: globalLocationId,
        visitorTypeId: visitorTypes[0]?.id,
        visitors: newVisitorName ? [
            {
                firstName: newVisitorName.firstName !== "NONE" ? newVisitorName.firstName : undefined,
                middleName: newVisitorName.middleName !== "NONE" ? newVisitorName.middleName : undefined,
                lastName: newVisitorName.lastName !== "NONE" ? newVisitorName.lastName : undefined,
            }
        ] : undefined
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (globalSignInWorkflowFields === null && globalLocationWorkflow) {
                    await dispatch(describeVisitorWorkflowFields({ orgId, visitorWorkflowId: globalLocationWorkflow.SignInWorkflowId, global: true }));
                }
                const locations = await getRequest(`/orgs/${orgId}/sites`, { status: 1 });
                setLocations(locations.data);

                const hosts = await getRequest(`/orgs/${orgId}/users`);
                const filteredHosts = hosts.data.filter((user: User) => user.status === 1 && (user.hostExclude === 0 || user.hostExclude === null));
                setHosts(filteredHosts);

                const redFlags = await getRequest(`/orgs/${orgId}/redflag`);
                setRedFlags(redFlags.data);

                const visitorTypes = await getRequest(`/orgs/${orgId}/visitorTypes`);
                setVisitorTypes(visitorTypes.data);

                const customFieldsWorkflow = await getRequest(`/orgs/${orgId}/visitorWorkflow/${globalLocationWorkflow?.SignInWorkflowId}/fieldCustom`);
                setCustomFieldsWorkflow(customFieldsWorkflow.data);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, [orgId, globalLocationId, globalLocationWorkflow?.SignInWorkflowId]);

    const renderFormFields = () => {
        return customFieldsWorkflow.map((formField, index) => {
            if (formField.values) {
                const options = formField.values.map((option) => ({
                    label: option.display || option.name,
                    value: option.value,
                }));

                return (
                    <OpForm.Select
                        label={formField.name}
                        name={formField.id}
                        options={options}
                        rules={[{ required: formField.required === 1 }]}
                        placeholder={formField.placeholder ? formField.placeholder : undefined}
                    />
                );
            } else {
                return (
                    <OpForm.Input
                        label={formField.name}
                        name={formField.id}
                        rules={[{ required: formField.required === 1 }]}
                        placeholder={formField.placeholder ? formField.placeholder : undefined}
                    />
                );
            }
        });
    };

    const handleSubmit = useCallback(async ({ values }: IOnSubmitArgs) => {
        setIsSubmitting(true);
        try {
            if (values.visitors.length === 0) {
                notification.error({
                    message: 'Error',
                    description: 'Please add at least one visitor.',
                    placement: 'bottomRight',
                });
                setIsSubmitting(false);
                return;
            }

            const transformedValues = transformNullToEmptyString(values);

            // Immediately sign in visitors upon sending an invitation.
            const inviteResponse = await postRequest(`/orgs/${orgId}/visitorInvitation`, {
                ...transformedValues,
                visitors: transformedValues.visitors.map((visitor: VisitorInvite) => ({
                    ...visitor,
                    status: STATUS.PENDING.id,
                })),
            });
            const visit = inviteResponse.data[0];
            await patchRequest(`/orgs/${orgId}/visitor/${visit.id}`, {
                signIn: dayjs().format(DATE_TIME_FORMAT),
                status: STATUS.SIGNED_IN.id
            });
            for (const visitor of visit.visitors) {
                if (visitor.id) {
                    await patchRequest(`/orgs/${orgId}/visitor/${visit.id}/visitors/${visitor.id}`, {
                        signIn: dayjs().format(DATE_TIME_FORMAT),
                        status: STATUS.SIGNED_IN.id
                    });
                }
            }

            for (const key in values) {
                const field = values[key];
                // Check if the field is a custom field (custom fields have numeric keys)
                const isCustomField = !isNaN(Number(key));
                if (isCustomField && field !== undefined) {
                    try {
                        await postRequest(`/orgs/${orgId}/visitor/${visit.id}/visitorData`, {
                            fieldId: key,
                            value: field,
                        });
                        console.log(`Added custom field ${key}.`);
                    } catch (error) {
                        console.log(`Failed to add custom field ${key}:`, error);
                    }
                }
            }

            // log activity
            const fullNameList = values.visitors.map((visitor: VisitorInvite) =>
                formatFullName(visitor.firstName, visitor.middleName, visitor.lastName,)).join(', ');
            await postRequest(`/orgs/${orgId}/userActivity`, {
                userId: globalUserId,
                activityId: SIGNED_IN_VISITOR_ID,
                details: fullNameList
            });

            notification.success({
                message: 'Success',
                description: 'Visitor(s) signed in successfully.',
                placement: 'bottomRight',
            });

            await dispatch(fetchVisits({ orgId }));
            await dispatch(fetchVisitsToday({ orgId }));
            form.resetFields();
            onClose();
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred during the submission.',
                placement: 'bottomRight',
            });
            console.error("Form submission failed:", error);
        } finally {
            setIsSubmitting(false);
        }
    }, [orgId, form, globalUserId, dispatch, onClose]);

    return (
        <OpFormDrawer
            form={form}
            title={'Enter Manually'}
            width={DRAWER_WIDTH}
            isFormLoading={loading || isSubmitting}
            open={open}
            onClose={() => {
                onClose();
                setNewVisitorName(undefined);
            }}
            formComponent={
                <OpForm
                    form={form}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    hasError={false}
                    defaultButtons={false}
                >
                    <VisitorList form={form} redFlags={redFlags} isInviting={false} newVisitorName={newVisitorName} />
                    <OpForm.Select
                        label="Visitor Type" name="visitorTypeId" rules={[{ required: true, message: 'Please select a visitor type.' }]}
                        placeholder="Select Visitor Type"
                        options={visitorTypes.map(type => ({
                            label: type.name,
                            value: type.id
                        }))}
                    />
                    <OpForm.Select
                        label="Locations" name="siteId" rules={[{ required: true, message: 'Please select a location.' }]}
                        placeholder="Select Locations"
                        options={locations.map(location => ({
                            label: location.name,
                            value: location.id
                        }))}
                    />
                    {(globalSignInWorkflowFields?.visitCompany.included === 1) ? (
                        <OpForm.Input
                            label="Company" name="company"
                            rules={[{ required: globalSignInWorkflowFields.visitCompany.required === 1, message: 'Please enter company.' }]}
                        />
                    ) : undefined}
                    {(globalSignInWorkflowFields?.host.included === 1) ? (
                        <OpForm.Select
                            label="Host" name="hostId"
                            rules={[{ required: globalSignInWorkflowFields.host.required === 1 && hosts.length > 0, message: 'Please select a host.' }]}
                            showSearch
                            placeholder={hosts.length === 0 ? "No hosts are available" : "Select Host"}
                            disabled={hosts.length === 0}
                            options={hosts.map(host => ({
                                label: host.identity?.firstName || host.identity?.lastName
                                    ? formatFullName(host.identity.firstName, host.identity.middleName, host.identity.lastName)
                                    : host.identity?.email,
                                value: host.id
                            }))}
                        />
                    ) : undefined}
                    {(globalSignInWorkflowFields?.visitPurpose.included === 1) ? (
                        <OpForm.TextAreaInput
                            label="Purpose" name="purpose" rows={4}
                            rules={[{ required: globalSignInWorkflowFields.visitPurpose.required === 1, message: 'Please enter purpose.' }]}
                        />
                    ) : undefined}

                    {(globalSignInWorkflowFields?.visitNotes?.included === 1) ? (
                        <OpForm.TextAreaInput
                            label="Note" name="note" rows={4}
                            rules={[{ required: globalSignInWorkflowFields.visitNotes.required === 1, message: 'Please enter note.' }]}
                        />
                    ) : undefined}

                    <OpDivider />
                    {renderFormFields()}
                </OpForm>
            }
        />
    );
};

export default SignInManuallyDrawer;
