import React, { useCallback, useEffect, useState } from 'react';
import { OpForm, IOnSubmitArgs } from 'components/customAntd/DLS/OpForm/OpForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { fetchScopeResources } from 'store/slices/scopeResourcesSlice';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import { OpTable } from 'components/customAntd/DLS/OpTable/OpTable';
import { FormInstance, Spin, notification } from 'antd';
import { bulkUpdateRoleScopes } from 'store/slices/rolesSlice';
import { Scope, ScopeResource } from 'types/scopeResourcesTypes';
import { LoadingOutlined } from '@ant-design/icons';
import { fetchTokenIdentity } from 'store/slices/authSlice';
import { hasPermission } from 'utils/utils';
import { OpSwitch } from 'components/customAntd/DLS/OpSwitch/OpSwitch';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';

interface UserContentProps {
    form: FormInstance;
}

interface InitialValues {
    [key: string]: boolean;
}

interface ProcessedScope {
    id: { READ: number | null, WRITE: number | null };
    name: React.ReactNode;
    parentScopeId: { READ: number | null, WRITE: number | null };
    order: number;
    readSwitch: JSX.Element;
    writeSwitch: JSX.Element;
    description: string;
}

interface ProcessedAccessControl {
    id: number;
    name: string | null;
    languageCode: string | null;
    scopes: ProcessedScope[];
}


const ScopeContent: React.FC<UserContentProps> = ({ form }) => {
    const dispatch: AppDispatch = useDispatch();
    const { selectedRole } = useSelector((state: RootState) => state.roles);
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const token = useSelector((state: RootState) => state.auth.auth.data[0].token);
    const { scopeResources, fetchScopeResourcesLoading } = useSelector((state: RootState) => state.scopeResources);
    const [processedAccessControls, setProcessedAccessControls] = useState<ProcessedAccessControl[]>([]);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasRoleRead = hasPermission(tokenScopeList, orgId, 'o', 'role:r');
    const hasRoleWrite = hasPermission(tokenScopeList, orgId, 'o', 'role:w');

    useEffect(() => {
        if (orgId) {
            dispatch(fetchScopeResources({ orgId }));
        }
    }, [dispatch, orgId]);

    const initialValues = scopeResources.data.reduce<InitialValues>((acc, accessControl) => {
        accessControl.scopes.forEach(scope => {
            acc[`read-${scope.id}`] = !!(selectedRole?.scopes.includes(scope.id) && scope.permission === 'READ');
            acc[`write-${scope.id}`] = !!(selectedRole?.scopes.includes(scope.id) && scope.permission === 'WRITE');
        });
        return acc;
    }, {});

    const handleScopeChange = useCallback((scopeId: string, type: 'read' | 'write', checked: boolean) => {
        form.setFieldsValue({ [`${type}-${scopeId}`]: checked });

        if (type === 'write' && checked) {
            const readScopeId = (parseInt(scopeId, 10) - 1).toString();
            form.setFieldsValue({ [`read-${readScopeId}`]: true });

            // turning parent scope on if child is on
            processedAccessControls.forEach(ac => {
                ac.scopes.forEach(scope => {
                    if (scope.parentScopeId.WRITE === parseInt(scopeId, 10)) {
                        console.log("scopeId", scopeId);
                        form.setFieldsValue({ [`write-${scope.id.WRITE}`]: true });
                        if (scope.id.READ) {
                            form.setFieldsValue({ [`read-${scope.id.READ}`]: true });
                        }
                    }
                });
            });
        }

        if (type === 'write' && !checked) {
            processedAccessControls.forEach(ac => {
                ac.scopes.forEach(scope => {
                    if (scope.parentScopeId.WRITE === parseInt(scopeId, 10)) {
                        form.setFieldsValue({ [`write-${scope.id.WRITE}`]: false });
                    }
                });
            });
        }

        if (type === 'read' && !checked) {
            processedAccessControls.forEach(ac => {
                ac.scopes.forEach(scope => {
                    if (scope.parentScopeId.READ === parseInt(scopeId, 10)) {
                        form.setFieldsValue({ [`read-${scope.id.READ}`]: false });
                        form.setFieldsValue({ [`write-${scope.id.WRITE}`]: false });
                    }
                });
            });
        }

        if (type === 'read' && checked) {
            processedAccessControls.forEach(ac => {
                ac.scopes.forEach(scope => {
                    if (scope.parentScopeId.READ === parseInt(scopeId, 10)) {
                        form.setFieldsValue({ [`read-${scope.id.READ}`]: true });
                    }
                });
            });
        }

        if (type === 'read' && !checked) {
            const writeScopeId = (parseInt(scopeId, 10) + 1).toString();
            form.setFieldsValue({ [`write-${writeScopeId}`]: false });
        }

        // Turn on parent scopes
        if (checked) {
            processedAccessControls.forEach(ac => {
                ac.scopes.forEach(scope => {
                    if ((type === 'read' && scope.id.READ === parseInt(scopeId, 10))) {
                        if (scope.parentScopeId.READ !== null) {
                            form.setFieldsValue({ [`read-${scope.parentScopeId.READ}`]: true });
                        }
                    }
                    if (type === 'write' && scope.id.WRITE === parseInt(scopeId, 10)) {
                        if (scope.parentScopeId.READ !== null) {
                            form.setFieldsValue({ [`read-${scope.parentScopeId.READ}`]: true });
                        }
                        if (scope.parentScopeId.WRITE !== null) {
                            form.setFieldsValue({ [`write-${scope.parentScopeId.WRITE}`]: true });
                        }
                    }
                });
            });
        }
    }, [form, processedAccessControls]);

    const preprocessScopes = useCallback((accessControls: ScopeResource[]): ProcessedAccessControl[] => {
        return accessControls.map(accessControl => {
            const groupedScopes: { [key: string]: { read?: Scope; write?: Scope } } = {};

            accessControl.scopes.forEach(scope => {
                if (!groupedScopes[scope.name]) {
                    groupedScopes[scope.name] = {};
                }
                if (scope.permission === 'READ') {
                    groupedScopes[scope.name].read = scope;
                } else if (scope.permission === 'WRITE') {
                    groupedScopes[scope.name].write = scope;
                }
            });

            const processedScopes: ProcessedScope[] = [];

            const addScope = (scopes: { read?: Scope; write?: Scope }, level: number = 0) => {
                const readScope = scopes.read;
                const writeScope = scopes.write;

                const namePrefix = level > 0 ? (
                    <>
                        <span style={{ marginLeft: `${level * 16}px`, fontSize: '12px' }}>
                            <CaretRightOutlined style={{ fontSize: '12px' }} />
                            {' '}
                        </span>
                    </>
                ) : (
                    <>
                        <CaretDownOutlined style={{ fontSize: '12px' }} />
                        {' '}
                    </>
                );
                if (readScope || writeScope) {
                    processedScopes.push({
                        id: { READ: readScope?.id || null, WRITE: writeScope?.id || null },
                        name: (
                            <span>
                                {namePrefix}{readScope?.name || writeScope?.name}
                            </span>
                        ),
                        parentScopeId: {
                            READ: readScope?.parentScopeId || null,
                            WRITE: writeScope?.parentScopeId || null
                        },
                        order: readScope ? readScope.order || 0 : writeScope ? writeScope.order || 0 : 0,
                        readSwitch: readScope ? (
                            <OpForm.Item name={`read-${readScope.id}`} valuePropName="checked" noStyle>
                                <OpSwitch
                                    checked={!!form.getFieldValue(`read-${readScope.id}`)}
                                    onChange={(checked) => handleScopeChange(readScope.id.toString(), 'read', checked)}
                                    disabled={!hasRoleWrite || selectedRole?.admin}
                                />
                            </OpForm.Item>
                        ) : <OpSwitch disabled />,
                        writeSwitch: writeScope ? (
                            <OpForm.Item name={`write-${writeScope.id}`} valuePropName="checked" noStyle>
                                <OpSwitch
                                    checked={!!form.getFieldValue(`write-${writeScope.id}`)}
                                    onChange={(checked) => handleScopeChange(writeScope.id.toString(), 'write', checked)}
                                    disabled={!hasRoleWrite || selectedRole?.admin}
                                />
                            </OpForm.Item>
                        ) : <OpSwitch disabled />,
                        description: `${readScope?.description || ''}${writeScope ? ' / ' + writeScope.description : ''}`
                    });
                }
            };

            const recurseScopes = (parentId: number | null, level: number = 0) => {
                Object.values(groupedScopes).forEach(scopes => {
                    if ((scopes.read?.parentScopeId === parentId) || (scopes.write?.parentScopeId === parentId)) {
                        addScope(scopes, level);
                        const scopeId = scopes.read?.id || scopes.write?.id;
                        if (scopeId) {
                            recurseScopes(scopeId, level + 1);
                        }
                    }
                });
            };

            recurseScopes(null);
            processedScopes.sort((a, b) => a.order - b.order);

            return {
                id: accessControl.id,
                name: accessControl.name,
                languageCode: accessControl.languageCode,
                scopes: processedScopes
            };
        });
    }, [form, handleScopeChange, hasRoleWrite, selectedRole?.admin]);

    useEffect(() => {
        if (scopeResources.data) {
            setProcessedAccessControls(preprocessScopes(scopeResources.data));
        }
    }, [preprocessScopes, scopeResources]);

    const renderTable = (accessControl: ProcessedAccessControl) => (
        <OpTable
            label={accessControl.name}
            dataSource={accessControl.scopes}
            pagination={false}
            allowGlobalSearch={false}
            allowExport={false}
            allowShowHideColumns={false}
            columns={[
                {
                    label: 'PERMISSION',
                    dataIndex: 'name',
                    key: 'name',
                    width: 200,
                },
                {
                    label: 'READ',
                    dataIndex: 'readSwitch',
                    key: 'readSwitch',
                    align: 'center',
                    width: 100,
                },
                {
                    label: 'WRITE',
                    dataIndex: 'writeSwitch',
                    key: 'writeSwitch',
                    align: 'center',
                    width: 100,
                },
                {
                    label: 'DESCRIPTION',
                    dataIndex: 'description',
                    key: 'description',
                    width: 300,
                },
            ]}
            rowKey={record => `${record.id.READ}-${record.id.WRITE}`}
        />
    );

    const handleSubmit = async (args: IOnSubmitArgs) => {
        if (!orgId || !selectedRole) {
            return;
        }
        if (selectedRole.admin) {
            notification.error({
                message: 'Error',
                description: `The ${selectedRole.name} role cannot be modified.`,
                placement: 'bottomRight',
            });
            return;
        }

        const { values } = args;
        const addScopes: number[] = [];
        const removeScopes: number[] = [];

        Object.entries(values).forEach(([key, value]) => {
            const [type, id] = key.split('-');
            if (type === 'read' || type === 'write') {
                const isChecked = Boolean(value);
                const scopeId = parseInt(id, 10);
                const currentState = initialValues[key];
                if (isChecked && !currentState) {
                    addScopes.push(scopeId);
                } else if (!isChecked && currentState) {
                    removeScopes.push(scopeId);
                }
            }
        });

        try {
            await dispatch(bulkUpdateRoleScopes({ orgId, roleId: selectedRole.id, add: addScopes, remove: removeScopes }));
            await dispatch(fetchTokenIdentity({ token }));
            notification.success({
                message: 'Success',
                description: 'Scopes updated successfully.',
                placement: 'bottomRight',
            });
        } catch (error) {
            notification.error({ message: 'Failed to update scopes.' });
        }
    };

    return (
        <OpForm
            form={form}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            hasError={false}
            defaultButtons={false}
            isReadOnly={!hasRoleWrite && hasRoleRead}
        >
            {fetchScopeResourcesLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </div>
            ) : (
                <OpSpace direction="vertical" size="large" style={{ display: 'flex' }}>
                    {processedAccessControls.map(ac => (
                        <div key={ac.id}>
                            {renderTable(ac)}
                        </div>
                    ))}
                </OpSpace>
            )}
        </OpForm>
    );
};

export default ScopeContent;
